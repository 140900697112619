import {store} from "../../redux/store";
import {setActiveProblem,reset30seconds} from "../../redux/slices/activeProblemSlice";
import {getMeeting} from "../../redux/slices/workspaceSlice";
import {getProblem} from "../../redux/slices/singleProblemSlice";
import {getProblemsPendingReview} from "../../redux/slices/bannerSlice";
import {clearInWindowInterval} from "../../utils";
import {TimerTypes} from "../timers";


const defaultCase = async (data) => {
    await store.dispatch(getProblem({problem_uuid: data?.data.problem_uuid}));
    await store.dispatch(setActiveProblem(data?.data));
    store.dispatch(getMeeting({problem_uuid: data?.data.problem_uuid}));
};

export default async (data) => {
    switch (data?.event) {
        case "matched":
            await clearInWindowInterval(TimerTypes.new_problem);
            await clearInWindowInterval(TimerTypes.timer_30_sec);
            await defaultCase(data);
            return await store.dispatch(reset30seconds());
        case "close":
            const {auth:{user:{user_uuid}}} = store.getState();

            await store.dispatch(getMeeting({problem_uuid: data?.data.problem_uuid}));
            store.dispatch(getProblemsPendingReview(user_uuid));
            return await store.dispatch(setActiveProblem({}));
        default:
            defaultCase(data)
    }
}
