import React from 'react';
import {CircularProgressbarWithChildren, buildStyles} from "react-circular-progressbar";

const CircularProgressbar = ({src, value, maxValue = 100, pathColor}) => {
    return (
        <div className='circular-progressbar'>
            <CircularProgressbarWithChildren
                value={value}
                strokeWidth={5}
                maxValue={maxValue}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    trailColor: "transparent",
                    pathColor: pathColor,
                    pathTransitionDuration: 0.5,
                })}
            >
                <img
                    style={{ width: 80+'%' }}
                    src={src}
                    alt="woman"
                />
            </CircularProgressbarWithChildren>
        </div>
    );
};

export default CircularProgressbar;
