import {createSlice} from '@reduxjs/toolkit'
import Api from '../../services/api/user'

export const initialEducation = {
    school: '',
    field_study: '',
    from: {
        label: '',
        value: ''
    },
    to: {
        label: '',
        value: ''
    },
    education_id:  null
};

const initialState = {
    profile: {},
    averageRating: null,
    educations: [],
    practiceAreas: [],
    userLanguages: [],
    currentEducation: initialEducation,
};

const publicAccountSlice = createSlice({
    name: 'publicProfile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state= {...state, ...action.payload};
            return state;
        },
        setCurrentEducation: (state, action) => {
            state.currentEducation = action.payload;
            return state;
        },
        setPublicUserLanguages: (state, action) => {
            state.userLanguages = action.payload;
            return state;
        },
        resetCurrentEducation: (state, action) => {
            state.currentEducation = initialEducation;
            return state;
        },
        resetPublicProfile: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setProfile,
    resetPublicProfile,
    actionError,
    setCurrentEducation,
    setPublicUserLanguages,
    resetCurrentEducation
}
    = publicAccountSlice.actions;


export const getPublicProfile= (user_uuid) => async dispatch => {
    try {
        const payload = await Api.getPublicProfile(user_uuid);
        dispatch(setProfile(payload.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const addEducation = (data, user_uuid) => async dispatch => {
    try {
        await Api.addEducation(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const updateEducation = (data, user_uuid) => async dispatch => {
    try {
        await Api.updateEducation(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const deleteEducation = (data, user_uuid) => async dispatch => {
    try {
        await Api.deleteEducation(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};


export default publicAccountSlice.reducer;
