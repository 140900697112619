import React, {useState, useRef, useEffect} from 'react';
import {Switch, Button, ButtonLink, Icomoon} from 'elements';
import {useDispatch, useSelector} from "react-redux";
import ManageExpertise from "./modals/areaOfExpertise/ManageExpertise";
import ConfirmationModal from "elements/modals/confirmation/ConfirmationModal";
import {closeConfirmationModal} from 'vendors/redux/slices/layoutSlice'
import InfoBanner from "elements/InfoBanner";
import {setCurrentPracticeArea, changePracticeAreaStatus, changeUserRole} from "vendors/redux/slices/editPracticeAreaSlice";
import {deletePracticeArea} from "vendors/redux/slices/editPracticeAreaSlice";
import {getSubjects} from "vendors/redux/slices/staticSlice";
import {hashToId, scrollToId} from "../../vendors/utils";

const alertContent = () => {
    return [
        {
            alertType: 'warning',
            alertText: 'We will stop sending you help requests for this subject. When you are ready, simply switch it back on to resume helping others.',
        },
        {
            alertType: 'success',
            alertText: 'Welcome back! You are now visible on search results and will start receiving job notifications for this subject.',
        },
        {
            alertType: 'success',
            alertText: 'Welcome back! You are now visible on search results and will start receiving help requests for your chosen subjects.',
        },
        {
            alertType: 'warning',
            alertText: 'You will no longer receive help requests for any of your chosen subjects. When you\'re ready, simply switch it back on to resume helping others. ',
        },
    ];
};

const AreaOfExpertiseEdit= ({hash}) => {
    const dispatch = useDispatch();
    const {practiceAreas = [], profile: {user_uuid, role, was_tutor}} = useSelector(state => state.profile);
    const {roles, subjects} = useSelector(state => state.staticData);
    const [openManage, setOpenManage] = useState(false);
    const [subjectAlert, setSubjectAlert] = useState(null);

    const paramsRef = useRef(null);

    useEffect(() => {
        dispatch(getSubjects());

    }, []);

    useEffect(() => {
        if(hash) {
            scrollToId(hashToId(hash))
        }

    }, [hash]);


    const onOpenManageModal = (item) => {
        item && dispatch(setCurrentPracticeArea(item));
        setOpenManage(true)
    };

    const onCloseManageModal = (data) => {
        paramsRef.current = data;
        setOpenManage(false);
    };

    const onDelete = () => {
        const {params, user_uuid} = paramsRef.current;
        dispatch(deletePracticeArea(params, user_uuid));
        dispatch(closeConfirmationModal());
        paramsRef.current = null;

        if(practiceAreas.length === 1) {
            dispatch(changeUserRole({new_role: roles.STUDENT}, user_uuid));
            setSubjectAlert(3)
        }
    };

    const onChangeSubject = (e, item) => {
        const checked = e.target.checked;
        if(checked) {
            dispatch(changePracticeAreaStatus({subject: {...item, active: true}}, user_uuid));
            setSubjectAlert(1)
        } else {
            dispatch(changePracticeAreaStatus({subject: {...item, active: false}}, user_uuid));
            setSubjectAlert(0)
        }
    };

    const onChangeRole = (e) => {
        const checked = e.target.checked;
        if(checked) {
            dispatch(changeUserRole({new_role: roles.STUDENT}, user_uuid));
            setSubjectAlert(3)
        } else {
            dispatch(changeUserRole({new_role: roles.TUTOR}, user_uuid));
            setSubjectAlert(2)
        }

    };
    const current = alertContent()[subjectAlert] || '';


    return (
        <>

            {
                role !== roles?.TUTOR && !was_tutor ?
                    <div id={hashToId(hash)} className='section-for-student'>
                        <div>
                            <h3 className='pb-3 color-secondary-2'>Subjects I can help with</h3>
                            <p className='max-width-600 pb-4 color-secondary-2 text-sm'>When you’re ready to earn some extra income by coaching other members of the community, the subjects you want to teach show up here.</p>
                            <ButtonLink
                                styleType='secondary'
                                to={'/become-a-coach'}
                            >
                                Find out more
                            </ButtonLink>
                        </div>
                    </div> :
                    <div id={hashToId(hash)} className="account-info expertise wrap-mobile">
                        <div className={role === roles?.STUDENT ? 'disabled-content' : ''}>
                            <div className="content-top pb-6">
                                <h3 className='pb-3'>Subjects I can help with</h3>
                                <p className='text-sm color-text-primary-light'>Leave these options enabled to show up in search results and earn extra income by helping others solve their problems.</p>

                            </div>

                            <div className="pb-7 mt-0 border-bottom-light">
                                <ul className="profile-editable-list">
                                    {[...practiceAreas]?.map(((item) =>
                                            <li key={item.id} className={`with-switch ${!item.active && role === roles.TUTOR ? 'disabled-content' : ''}`}>
                                                <div className="list-item-main">
                                                    <label className='text-label w-100 pb-2'>{item?.name}</label>
                                                    <p className='text-xs color-text-primary-light'>
                                                        {item?.levels?.map(level => level.name).join(', ')}
                                                    </p>
                                                </div>
                                                <div className="list-item-actions">
                                                    <button className='no-style pointer btn-icon' onClick={() => onOpenManageModal(item)}>
                                                        <Icomoon size={24} name='edit'/>
                                                    </button>
                                                    <Switch checked={item.active} onChange={(e)=>onChangeSubject(e, item)}/>
                                                </div>

                                            </li>
                                    ))}

                                </ul>
                                { practiceAreas.length < subjects.length &&
                                <Button
                                    size='w-90 medium-text'
                                    className="item-right-space mt-2"
                                    styleType='secondary'
                                    onClick={() => onOpenManageModal()}
                                    // disabled={!(role === roles.TUTOR)}
                                >
                                    Add
                                </Button>
                                }

                            </div>
                        </div>

                        <div className='py-7 border-bottom-light'>
                            <div className='flex justify-between pb-4'>
                                <h5>I no longer want to help others</h5>
                                <Switch checked={role === roles?.STUDENT} onChange={(e)=>onChangeRole(e)}/>
                            </div>
                            <p className='text-normal color-text-primary-light mr-4'>Switching this on removes you from the search results. Simply switch it back off to continue earning an extra income. Please note, for auditing purposes your tuition history is stored securely on our servers.</p>
                        </div>
                    </div>

            }


            {openManage && <ManageExpertise
                onCloseModal={onCloseManageModal}
                open={openManage}
            />}

            <ConfirmationModal onDelete={onDelete} subject={paramsRef?.current?.subject} />

            <InfoBanner
                inProp={subjectAlert !== null}
                type={current}
                onClose={() => setSubjectAlert(null)}
            />


        </>
    )
};

export default AreaOfExpertiseEdit;
