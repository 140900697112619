import React, {useEffect} from 'react';
import PageEnhancer from "./PageEnhancer";
import {useDispatch, useSelector} from "react-redux";
import PostProblemFirst from "component/postProblem/PostProblemFirst";
import PostProblemSecond from "component/postProblem/PostProblemSecond";
import {changeCurrentStep, resetProblem} from "../../vendors/redux/slices/postProblemSlice";
import {setPaymentBanner} from "vendors/redux/slices/layoutSlice";
import {useHistory} from "react-router";

const stepCount = 2;

const stepNotes = [
    'Tell us about the problem',
    'Review your request'
];
const PostProblem = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();

    const {currentStep} = useSelector(state => state.postProblem);
    const {user_uuid, paymentInfo} = useSelector(state => state.auth.user);


    /**
     change from client
     */
    /*useEffect(() => {
        if (user_uuid && !paymentInfo?.payment_method_id){
            dispatch(setPaymentBanner(true));
            push({pathname: '/account-profile', hash: '#payment-section'})
        }
    }, [user_uuid]);*/


    useEffect(() => {
        return () => {
            dispatch(changeCurrentStep(1));
            dispatch(resetProblem());
        }
    }, []);


    const stepViewsStatic = [
        <PostProblemFirst/>,
        <PostProblemSecond/>
    ];

    const stepContents = {
        formTitle: stepNotes[currentStep-1],
        filled: currentStep * 100 / stepCount
    };

    return (
        <PageEnhancer>
            <div className='slider-bar-wrapper'>
                <p className='slider-bar-text'>{`Step ${currentStep} of ${stepCount}`}</p>
                <div className="slider-bar"><span className={'filled'} style={{width: stepContents.filled + '%'}}/>
                </div>
            </div>
            <h3 className="text-center form-title mt-10">{stepContents.formTitle}</h3>
            {
                stepViewsStatic[currentStep - 1]
            }
        </PageEnhancer>
    );
};

export default PostProblem;
