import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-responsive-modal";
import {resetCurrentEducation} from "vendors/redux/slices/publicProfileSlice";
import ManageEducation from "./modals/manageEducation/manageEducation";
import Education from "./Education";
import {Button} from 'elements';



const EducationsEdit = (props) => {
    const dispatch = useDispatch();

    const {educations} = useSelector(state => state.profile);

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);

    const onCloseModal = () => {
        setOpen(false);
        dispatch(resetCurrentEducation());
    };

    return (
        <>
            <div className="account-info wrap-mobile">
                <div className="content-top">
                    <h3>Education</h3>
                </div>
                <div className="content mb-0 no-border-bottom">
                    {educations.length === 0 &&
                    <p className="text-xs color-text-primary-light w-45 expertise-info mb-3">Record your educational experience here.</p>
                    }

                    {educations.map((item =>
                            <Education key={item.id} openModal={onOpenModal} item = {item} />
                    ))}
                    <Button onClick={onOpenModal}
                                         size='w-90 medium-text'
                                         className="item-right-space"
                                         styleType='secondary'>Add
                    </Button>
                    <Modal
                        open={open}
                        onClose={onCloseModal}
                        item
                        center>
                        <ManageEducation
                            onCloseModal={onCloseModal}
                        />
                    </Modal>
                </div>
            </div>
        </>
    )
};

export default EducationsEdit;
