import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {studentView, coachView} from "./configs";

const HowItWorksCarousel = ({isCoach}) => {

    const slideContent = () => {
        return isCoach ? coachView : studentView
    };

    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        appendDots: dots => (
            <ul className='tab-dot-list'>{dots}</ul>
        ),
        customPaging: i => {
            return (
                <p className='tab-dot-list-item'>
                    {slideContent()[i].tabTitle}
                </p>
            )
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                }
            }
        ]
    };
    return (
        <Slider className='tab-carousel' {...settings}>
            {
                slideContent().map((slide, index) => {
                    return(
                        <div key={`index-${index}`}>
                            <h2 className='title'>{slide.title}</h2>
                            <div className='content-images'>
                                <img src={slide.img} className='hide-mobile' alt=""/>
                                <img src={slide.imgResp} className='show-mobile' alt=""/>
                            </div>
                        </div>
                    )
                })
            }
        </Slider>
    );
};

export default HowItWorksCarousel;
