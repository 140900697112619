import React from 'react';

const Switch = ({checked, onChange}) => {

    return (
        <label className="switch">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <span className="slider"/>
        </label>
    );
};

export default Switch
