import React, {useEffect} from 'react';
import Select from "react-select";
import {useFormik} from "formik";
import {Button} from "elements";
import {useDispatch, useSelector} from "react-redux";
import {changeCurrentStep, setProblemData, setFilteredLevels} from "../../vendors/redux/slices/postProblemSlice";
import {getLevels, getSubjects} from "../../vendors/redux/slices/staticSlice";
import {constructSelectOption, assignDisableToLevels} from "vendors/utils";
import { problemFirstSchema as validationSchema } from 'vendors/services/yup-schemas/problemSchemas'
import FileUpload from "./FileUpload";
import {filterLangs} from "../../vendors/utils";
import {resetLevel, setLevel} from "../../vendors/redux/slices/postProblemSlice";

const PostProblemFirst = () => {
    const dispatch = useDispatch();
    let {subjects, levels, languages} = useSelector(state => state.staticData);
    const {data, filteredLevels, level} = useSelector(state => state.postProblem);
    languages = filterLangs(languages);

    const initialValues = {
        subject: data.subject || {},
        level: data.level || {},
        comment: data.comment || '',
        language: data.language || constructSelectOption(languages,{check_coming_soon: true})[0],
    };


    useEffect(() => {
        dispatch(getSubjects());
        dispatch(getLevels());
        window.scrollTo(0, 0)
    }, []);


    const filterLevels = (subject) => {
        const filtered = assignDisableToLevels(subject, levels);
        dispatch(setFilteredLevels(filtered));
    };

    const subjectChange = (field, value) => {
        setFieldValue(field, value);
        dispatch(resetLevel());
        setFieldValue('level', {});
        filterLevels(value);
    };

    const levelChange = (field, value) => {
        setFieldValue(field, value);
        dispatch(setLevel(value));
    };

    const onSubmit = (values) => {
        dispatch(setProblemData(values));
        dispatch(changeCurrentStep(2));
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
        enableReinitialize: true
    });
    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleFocus,
        handleBlur,
        isValid,
        dirty,
        setFieldTouched,
        setFieldValue
    } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <ul>
                <li className={`select-box select-wrapper pb-7 ${errors.subject?.name && touched.subject ? 'has-error' : ''}`}>
                    <label>Subject*</label>
                    <Select
                        name={'subject'}
                        classNamePrefix='select'
                        placeholder={false}
                        value={values.subject}
                        options={constructSelectOption(subjects,{check_coming_soon: true})}
                        onChange={v => subjectChange('subject', v)}
                        onBlur={() => setFieldTouched('subject', true)}
                    />
                    {errors.subject?.name && touched.subject && <p className='error-message-general bottom'>{errors.subject?.name}</p>}
                </li>
                <li className={`select-box select-wrapper pb-7 ${errors.level?.name && touched.level ? 'has-error' : ''}`}>
                    <label>Level of problem*</label>
                    <Select
                        name={'level'}
                        classNamePrefix='select'
                        placeholder={false}
                        value={level}
                        isDisabled={!values?.subject.id}
                        options={constructSelectOption(filteredLevels || [],{check_coming_soon: true})}
                        onChange={v =>levelChange('level', v)}
                        onBlur={() => setFieldTouched('level', true)}
                    />
                    {errors.level?.name && touched.level && <p className='error-message-general bottom'>{errors.level?.name}</p>}
                </li>
                <li className={`pb-7 `}>
                    <label>Write a few sentences about the problem*</label>
                    <div className={`form-input mb-0 ${errors.comment && touched.comment ? 'has-error' : ''}`}>
                        <textarea
                            name={'comment'}
                            value={values.comment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            placeholder={'E.g. Long Division…'}
                            maxLength="1000"
                            cols="30"
                            rows="6"
                        />
                        {errors.comment && touched.comment && <p className='error-message'>{errors.comment}</p>}
                    </div>
                </li>
                <li className='pb-7'>
                    <FileUpload />
                </li>
                <li className={'select-box select-wrapper pb-7 '}>
                    <label>What language would you like your helper to speak?*</label>
                    <Select
                        name={'language'}
                        classNamePrefix='select'
                        placeholder={false}
                        value={values.language}
                        options={constructSelectOption(languages)}
                        onChange={v => setFieldValue('language', v)}
                        onBlur={() => setFieldTouched('language', true)}
                    />
                </li>
            </ul>
            <Button
                type="submit"
                className='w-100 mt-3'
                disabled={data.subject ? !(isValid) : !(isValid && dirty)}
            >
                Next
            </Button>
        </form>

    );
};

export default PostProblemFirst;
