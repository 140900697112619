import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { hotjar } from 'react-hotjar';
import Routes from "./Routes";
import {getUserInfo} from "../vendors/redux/slices/authSlice";
import {
    getRoles,
    getLanguages,
    getPrices,
    getProblemStatuses,
    getNotificationTypes,
    getProblemEvents
} from "../vendors/redux/slices/staticSlice";
import Socket from "../vendors/services/sockets/socket";
import GeneralErrorModal from "../layout/GeneralErrorModal"
import {Spinner} from "elements";
import 'assets/scss/style.scss';
import {
    _start30seconds,
    getActiveProblem,
    startProblemTimer
} from "../vendors/redux/slices/activeProblemSlice";
import {getMeeting} from "../vendors/redux/slices/workspaceSlice";
import LocalStorage from "../vendors/services/localStorage";
import {TimerTypes} from "../vendors/services/timers";
const {REACT_APP_HOTJAR_SID} = process.env;


const App = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);

    const {problem: activeProblem} = useSelector(state => state.activeProblem);
    const {loggedIn, user: {user_uuid}} = useSelector(state => state.auth);
    const {meeting} = useSelector(state => state.workspace);
    const {problemStatuses} = useSelector(state => state.staticData);

    const token = localStorage.getItem('token');

    const loadStaticData = async () => {
        if (!loggedIn) {
            dispatch(getUserInfo());
        }

        await dispatch(getRoles());
        await dispatch(getLanguages());
        await dispatch(getPrices());
        await dispatch(getProblemStatuses())
        await dispatch(getNotificationTypes());
        await dispatch(getProblemEvents());
    }

    const loadHotjar = () => {
        if (REACT_APP_HOTJAR_SID) {
            hotjar.initialize(REACT_APP_HOTJAR_SID, 6);
            hotjar.identify(REACT_APP_HOTJAR_SID, { userProperty: 'value' });
        }
    }

    const checkTimers = () => {
        const timer_30_sec = LocalStorage.getLocalStorageAsync(TimerTypes.timer_30_sec);
        if (timer_30_sec) {
            dispatch(_start30seconds({count: timer_30_sec}))
        }
    }

    // authenticate the user
    useEffect(() => {
        loadHotjar();
        loadStaticData();
        checkTimers()
    }, []);


    const activeProblemListen = async () => {
        if (loggedIn && !Object.keys(activeProblem).length) {
            await dispatch(getActiveProblem({user_uuid}));
        }
        checkStudentProblemTimer()
    }

    const checkStudentProblemTimer = () => {
        !window?.timers[TimerTypes.new_problem] && setTimeout(()=> {
            const new_problem = LocalStorage.getLocalStorageAsync(TimerTypes.new_problem);
            if (new_problem && activeProblem?.student === user_uuid) {
                dispatch(startProblemTimer())
            }
        });
    }

    useEffect(activeProblemListen, [loggedIn, activeProblem]);

    useEffect(() => {
        if (Object.keys(activeProblem).length && activeProblem.status > problemStatuses?.expired && !Object.keys(meeting).length) {
            dispatch(getMeeting({problem_uuid: activeProblem.problem_uuid}))
        }
    }, [activeProblem, meeting, problemStatuses]);

    // show spinner if there is pending request
    useEffect(() => {
        if (loggedIn && token || loggedIn !== null) {
            setLoaded(true);
            Socket.init({token});
        }
    }, [loggedIn]);

    return (
        <>{!loaded ? <Spinner/> :
            <>
                <Routes/>
                <GeneralErrorModal/>
            </>
        }</>
    );
};

export default App;
