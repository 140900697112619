import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {getActiveProblem, reset} from "../../vendors/redux/slices/activeProblemSlice";
import {userReady} from "../../vendors/redux/slices/workspaceSlice";
import "react-circular-progressbar/dist/styles.css";
import {CircularProgressbar, Button} from "elements";
import HappyWoman from 'assets/img/postProblem/happy.png'
import {calcCirclePercent, clearInWindowInterval} from "../../vendors/utils";
import CountDownTimer from "../../component/postProblem/CountDownTimer";
import {TimerTypes} from  "../../vendors/services/timers"
import ProblemExpire from "./elements/ProblemExpire";
import PageEnhancer from "../postProblem/PageEnhancer";
import {Spinner} from "elements";


const CountdownCoach = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const { problem_uuid } = useParams();

    const {problem: activeProblem, getError: problemError} = useSelector(state => state.activeProblem);
    const {meeting} = useSelector(state => state.workspace);
    const {user: {user_uuid}} = useSelector(state => state.auth);
    const {roles, problemStatuses} = useSelector(state => state.staticData);

    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if (user_uuid && !Object.keys(activeProblem).length && Object.keys(roles).length) {
            dispatch(getActiveProblem({problem_uuid, role: roles.TUTOR}))
        }
    }, [user_uuid, roles]);

    useEffect(() => {
        if (meeting?.tutor_ready && meeting?.student_ready) {
            redirectWorkspace()
        }
        return ()=>{resetProblem()}
    }, []);

    useEffect(() => {
        if (!(Object.keys(activeProblem).length) && problemError) {
            push('/');
        }

    }, [problemError]);

    const getTimerTick = (tick) => {
        if (!tick) {
            return;
        }
        setCountDown(calcCirclePercent(tick.in_seconds))
    }

    const resetProblem = () => {
        dispatch(reset());
    };

    const sendUserReady = async () => {
        await dispatch(userReady({
            zoom_meeting_id: meeting.zoom_meeting_id,
            problem_uuid: activeProblem.problem_uuid
        }))
    }

    const redirectWorkspace = async () => {
        await clearInWindowInterval(TimerTypes.matched_problem);
        push(`/workspace/${activeProblem.problem_uuid}/${meeting.zoom_meeting_id}`);
    }


    return (
        !Object.keys(activeProblem).length ? <Spinner/> : <PageEnhancer className='countdown-page'>
            {/*coach accepts*/}
            {activeProblem?.status === problemStatuses.expired && <ProblemExpire/>}

            {activeProblem?.status === problemStatuses.matched && <div className="text-center">
                <div className='text-center pb-8'>
                    <CircularProgressbar
                        src={HappyWoman}
                        value={countDown}
                        pathColor="#F9E47E"
                    />
                </div>
                <h3>Well done, the job is yours!</h3>
                <h4 className='fw-500 py-8'>Please connect to the private workspace* within the next <strong>
                    {activeProblem.matched_date_end && <CountDownTimer
                        type={TimerTypes.matched_problem}
                        end={activeProblem.matched_date_end}
                        getRemaining={getTimerTick}
                        after={sendUserReady}
                    />}
                </strong></h4>
                {meeting?.tutor_ready && <p>Waiting for the other participant to join.</p>}
                {!meeting?.tutor_ready && <Button onClick={sendUserReady} size='small medium-text'>Launch my workspace</Button>}
                {!meeting?.tutor_ready && <p className='text-normal pt-10'>*A private workspace is where you and the student will connect
                    over a video call to solve the problem together.</p>}
            </div>}
        </PageEnhancer>
    );
};
export default CountdownCoach;

