import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useHistory} from "react-router";
import {Button, ButtonLink, InfoBanner, Icomoon} from 'elements';
import Logo from 'assets/img/logo.svg'
import Avatar from "assets/img/avatar-default.svg";
import {useDispatch, useSelector} from "react-redux";
import {logOut, resetAuth} from "vendors/redux/slices/authSlice";
import {resetAccount} from "vendors/redux/slices/accountSlice";
import {resetPublicProfile} from "vendors/redux/slices/publicProfileSlice";
import {closeDrawer, openDrawer, setPaymentBanner, setGeneralBanner} from "vendors/redux/slices/layoutSlice";
import {getBells, resetBells, bellsTypes} from "vendors/redux/slices/bellsSlice";
import {useLocation} from "react-router";
import Drawer from "../../drawer/Drawer";
import {mobileMenu, problemBannerData, reviewBannerData, paymentBannerData} from "./configs";
import {getProblemsPendingReview} from "../../../vendors/redux/slices/bannerSlice";



const initialStateBanner = {
    index: null,
    path: ''
}

const Header = () => {

    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const {push} = useHistory();

    const [menuOpened, setMenuOpened] = useState(false);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [scrolled,setScrolled] = useState(false);
    const [problemBanner,setProblemBanner] = useState(initialStateBanner);
    const [reviewBanner,setReviewBanner] = useState(false);

    const {avatar, role, user_uuid, paymentInfo} = useSelector(state => state.auth.user);
    const {roles, meetingStatuses, problemStatuses} = useSelector(state => state.staticData);
    const {openedDrawer, paymentBanner, generalBanner} = useSelector(state => state.layout);
    const {newProblems} = useSelector(state => state.bells);
    const {problem: activeProblem, pending: problemPending} = useSelector(state => state.activeProblem);
    const {meeting} = useSelector(state => state.workspace);
    const {problemsReview} = useSelector(state => state.banners);

    useEffect(() => {
        window.addEventListener('scroll',handleScroll);

        dispatch(getProblemsPendingReview(user_uuid))
        dispatch(getBells(user_uuid));
        return () => {
            setMenuOpened(false);
            window.removeEventListener('scroll',handleScroll);
        }
    }, []);

    useEffect(() => {
        if (menuOpened) {
            document.body.classList.add('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden')
        }
    },[menuOpened]);

    useEffect(() => {
        if (openedDrawer) {
            document.body.classList.add('with-layer');
            document.body.classList.add('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('with-layer');
            document.body.classList.remove('overflow-hidden')
        }
    },[openedDrawer]);

    useEffect(() => {
        return () => {
            dispatch(closeDrawer())
        }
    },[]);

    useEffect(() => {
        setProblemBanner(null);

        if(!!Object.keys(activeProblem).length) {
            if(activeProblem.status !== problemStatuses.matched && !Object.keys(meeting).length) {
                setProblemBanner({
                    index: 0,
                    path: '/countdown-student'
                })
            }
            if(meeting?.status === meetingStatuses.waiting) {
                setProblemBanner({
                    index: 1,
                    path: user_uuid === activeProblem.student ? '/countdown-student' : `/countdown-coach/${activeProblem.problem_uuid}`
                })
            }
            if(meeting?.status === meetingStatuses.active ) {
                setProblemBanner({
                    index: 2,
                    path: `/workspace/${activeProblem.problem_uuid}/${meeting.zoom_meeting_id}`
                })
            }
        }

    }, [activeProblem, meeting])

    useEffect(() => {
        if (problemsReview.length && !Object.keys(activeProblem).length) {
            setReviewBanner(true)
        } else {
            setReviewBanner(false)
        }
    }, [problemsReview?.length])

    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 0 ){
            setScrolled(true);
        }
        else{
            setScrolled(false);
        }
    };

    const handleDrawerToggle = () => {
        setMenuOpened(!menuOpened);
    };

    const handleDrawerClose = () => {
        setMenuOpened(false);
    };

    const handleDropdownToggle = () => {
        setDropdownOpened(!dropdownOpened);
    };

    const handleNotificationDrawerToggle = () => {
        if (!openedDrawer) {
            dispatch(resetBells(bellsTypes.newProblem))
            dispatch(openDrawer())
        } else {
            dispatch(closeDrawer())
        }
        if(menuOpened) {
            handleDrawerClose()
        }
    };

    const handleLogout = () => {
        dispatch(logOut());
        dispatch(resetAuth());
        dispatch(resetAccount());
        dispatch(resetPublicProfile())
    };

    const showProblemBanner = () => {
        if (problemPending === false && Object.keys(problemStatuses).length) {
            return activeProblem.status === problemStatuses.matched ? !!Object.keys(meeting).length : problemPending === false;
        }
    }

    const goPostProblem = () => {
        push('/post-problem')
    }


    return (
        <>
            <header className={`header signedIn ${menuOpened ? 'open' : ''} ${scrolled ? 'sticky' : ''}`}>
                <div className="container">
                    <div className="header-content">
                        <Link  className='logo' to={'/'}>
                            <img src={Logo} alt="veepow"/>
                        </Link>
                        <div className='flex align-center'>
                            <Button onClick={handleNotificationDrawerToggle} className='no-style show-mobile mr-6 notification-btn'>
                                <div className={`${newProblems.count > 0 ? 'with-notification' : ''}`}>
                                    <Icomoon  name='bell'/>
                                </div>
                            </Button>
                            <button
                                className={`hamburger hamburger--slider ${menuOpened ? 'is-active' : ''}`}
                                type="button"
                                onClick={handleDrawerToggle}
                            >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                            </button>
                        </div>

                        <nav className='header-nav'>
                            <div className="header-nav-inner">
                                <ul className="secondary align-center header-nav-list">
                                    {
                                        mobileMenu().map(({to, text, roleType}) => {
                                            return (
                                                role !== roleType &&
                                                <li key={to} className='show-mobile'>
                                                    <Link to={to} onClick={handleDrawerClose}>{text}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                    <li className={`hide-mobile dropdown ${dropdownOpened ? 'opened' : ''}`}>
                                        <button onClick={handleDropdownToggle}>
                                            {
                                                avatar !== false ? <img className="avatar-image sm" src={avatar || Avatar}  alt="avatar"/> : <div className='shimmer-image sm' />
                                            }
                                        </button>
                                        <ul className='dropdown-list'>
                                            <li>
                                                <Link to={'/account-profile'}>My account</Link>
                                            </li>
                                            <li onClick={handleLogout}>Sign out</li>
                                        </ul>
                                    </li>
                                </ul>
                                <Button onClick={handleNotificationDrawerToggle} className='no-style vertical-middle notification-btn'>
                                    <div className={`${newProblems.count > 0 ? 'with-notification' : ''}`}>
                                        <Icomoon  name='bell'/>
                                    </div>

                                </Button>
                                <div className='button-group flex-mobile-column'>
                                    {
                                        role === roles?.STUDENT && pathname !== '/become-a-coach' &&
                                        <ButtonLink
                                            size='medium w-190'
                                            styleType='secondary'
                                            className='order2 earn-extra mr-10'
                                            to={'/become-a-coach'}
                                        >
                                            Earn extra income as a coach
                                        </ButtonLink>
                                    }
                                    <Button
                                        size='medium w-190'
                                        styleType='primary'
                                        className='order1'
                                        onClick={goPostProblem}
                                        disabled={!!(Object.keys(activeProblem).length)}
                                    >
                                        Post a problem
                                    </Button>
                                    <Button onClick={handleLogout} className='mt-10 show-mobile-block order3' styleType='secondary'>
                                        <Icomoon
                                            name='sign-out'
                                            size={22}
                                            className='mr-2'
                                        />
                                        Sign out
                                    </Button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>

            <div className='post-problem-section show-mobile'>
                <Button
                    size='medium-text w-100'
                    styleType='primary'
                    className='order1'
                    onClick={goPostProblem}
                    disabled={!!(Object.keys(activeProblem).length)}
                >
                    Post a problem
                </Button>
            </div>
            <Drawer />

            {paymentBanner && <InfoBanner
                inProp={paymentBanner === true}
                type={paymentBannerData}
                path={paymentBannerData.path}
                hash={paymentBannerData.hash}
                onClose={() => dispatch(setPaymentBanner(false))}
            />}

            {!paymentBanner && showProblemBanner() && <InfoBanner
                inProp={problemBanner && problemBanner.index !== null}
                type={problemBannerData()[problemBanner?.index]}
                path={problemBanner?.path}
                countdown={problemBanner?.index === 2}
            />}
            {!paymentBanner && problemPending === false && !(problemBanner && problemBanner.index !== null) &&
                !!problemsReview?.length &&
                <InfoBanner
                    inProp={reviewBanner === true}
                    type={reviewBannerData}
                    path={`/review/${problemsReview[0]?.problem_uuid}/${user_uuid === problemsReview[0]?.student ? problemsReview[0]?.tutor : problemsReview[0]?.student}`}
                    onClose={() => setReviewBanner(false)}
                />}

            {generalBanner && <InfoBanner
                inProp={!!generalBanner}
                type={generalBanner}
                path={generalBanner.path}
                hash={generalBanner.hash}
                onClose={() => dispatch(setGeneralBanner(false))}
            />}
        </>
    );
}

export default Header;
