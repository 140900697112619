import {createSlice} from '@reduxjs/toolkit'
import Api from '../../services/api/user'
import {getPublicProfile} from "./publicProfileSlice";
import {getUserInfo} from "./authSlice";

const initialState = {
    id: null,
    name: '',
    levels: []
};

const editPracticeAreaSlice = createSlice({
    name: 'editPracticeArea',
    initialState,
    reducers: {
        setCurrentPracticeArea: (state, action) => {
            state = action.payload;
            return state;
        },
        resetCurrentPracticeArea: () => {
            return initialState;
        },
        updateLevels: (state, action) => {
            state.levels = action.payload;
            return state;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setCurrentPracticeArea,
    resetCurrentPracticeArea,
    updateLevels,
    actionError,
}
    = editPracticeAreaSlice.actions;


export const addPracticeArea = (data, user_uuid) => async dispatch => {
    try {
        await Api.addPracticeArea(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const updatePracticeArea = (data, user_uuid) => async dispatch => {
    try {
        await Api.updatePracticeArea(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const deletePracticeArea = (data, user_uuid) => async dispatch => {
    try {
        await Api.deletePracticeArea(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const changePracticeAreaStatus = (data, user_uuid) => async dispatch => {
    try {
        await Api.changePracticeAreaStatus(data);
        dispatch(getPublicProfile(user_uuid));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const changeUserRole = (data, user_uuid) => async dispatch => {
    try {
        await Api.changeUserRole(data);
        dispatch(getPublicProfile(user_uuid));
        dispatch(getUserInfo());
    } catch (e) {
        dispatch(actionError(e))
    }
};




export default editPracticeAreaSlice.reducer;
