import React from "react";
import {InputMasked} from "../../../elements";
import errorMessages from "../../../vendors/services/yup-schemas/errorMessages";

const {minTopUp} = errorMessages('info');

const Amount = (props) => {

    const {errors, values, touched, handleBlur, _handleChange, handleFocus} = props;

    return (
        <>
            <ul className="li-space">
                <li className="label-inline">
                    <label>Amount*</label>
                    <InputMasked
                        name='amount'
                        info={minTopUp}
                        value={values.amount}
                        errorMessage={errors.amount}
                        touched={touched.amount}
                        className='mb-0 w-100'
                        handleBlur={handleBlur}
                        handleChange={_handleChange}
                        handleFocus={handleFocus}
                        placeholder={''}
                        alwaysShowMask={true}
                        mask="S$999999"
                        maskChar=""/>
                </li>
            </ul>
        </>
    )
};

export default Amount;