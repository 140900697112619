import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {Button} from 'elements'
import {useDispatch, useSelector} from "react-redux";
import close from "assets/img/icons/close-circle.svg";
import {getInbox} from "../../vendors/redux/slices/inboxSlice";
import {formatDate} from "../../vendors/utils";
import {closeDrawer} from "../../vendors/redux/slices/layoutSlice";

const Drawer = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();

    const {user_uuid} = useSelector(state => state.auth.user);
    const {openedDrawer} = useSelector(state => state.layout);
    const {inboxList} = useSelector(state => state.inbox);
    const {problemStatuses} = useSelector(state => state.staticData);
    const {problem: {problem_uuid: active_problem}} = useSelector(state => state.activeProblem);

    useEffect(() => {
        dispatch(getInbox({user_uuid: user_uuid}))
    }, []);

    const _closeDrawer = (link) => {
        dispatch(closeDrawer());

        link && push(link);
    };

    return (
        <div className={`drawer ${!openedDrawer ? '' : 'opened'}`}>
            <button className='no-style pointer btn-icon' onClick={_closeDrawer}>
                <img src={close} alt=""/>
            </button>
            <ul className='drawer-inner-list'>
                {!inboxList.length && <div>No results to show</div>}
                {
                    inboxList.map(({subject, problem_uuid, status, created_at}) => {
                        return (
                            <li key={problem_uuid}>
                                <div className='flex align-center justify-between pb-5'>
                                    <h3>Job request</h3>
                                    {
                                        !status ? <p>{formatDate(created_at, 'DD/MM/YYYY HH:mm')}</p> : <p>Expired</p>
                                    }

                                </div>
                                <div className='flex align-end justify-between'>
                                    <div>
                                        <label>Subject</label>
                                        <p className='color-dark mt-3'>{subject}</p>
                                    </div>
                                    <Button
                                        onClick={()=>_closeDrawer(`/problem-details/${problem_uuid}`)}
                                        className='medium-text small'
                                        disabled={(status !== problemStatuses.new  || active_problem) && active_problem !== problem_uuid}
                                    >
                                        View job
                                    </Button>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
};

export default Drawer;
