import {store} from "../../redux/store";
import {setMeeting} from "../../redux/slices/workspaceSlice";
import {clearInWindowInterval} from "../../utils";
import {TimerTypes} from "../timers";

export default async (data) => {
    await store.dispatch(setMeeting(data?.data));
    await clearInWindowInterval(TimerTypes.matched_problem);
    window.location.href = `/workspace/${data?.data.problem_uuid}/${data?.data.zoom_meeting_id}`
}
