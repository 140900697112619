import React from 'react';


const NotFound = ()=> {
    return (
        <div className="not-found">
            <h1>0_0 Page not Found</h1>
        </div>
    );
}

export default NotFound;
