import * as _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import {Button} from "elements";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {Modal} from "react-responsive-modal";

import {addExpertise, editExpertise} from 'vendors/services/yup-schemas/publicAccountSchemas'
import {
    setCurrentPracticeArea,
    resetCurrentPracticeArea,
    updateLevels,
    updatePracticeArea,
    addPracticeArea
} from "vendors/redux/slices/editPracticeAreaSlice";
import {getSubjects, getLevels} from "vendors/redux/slices/staticSlice";
import {Confirmation} from "elements/modals/config";
import {openConfirmationModal} from "vendors/redux/slices/layoutSlice";
import {
    constructSelectOption,
    assignDisableToLevels
} from "vendors/utils";

const ManageExpertise = ({open, onCloseModal}) => {
    const dispatch = useDispatch();
    const {editPracticeArea} = useSelector(state => state);
    const {subjects, levels} = useSelector(state => state.staticData);
    const {practiceAreas, profile: {user_uuid}} = useSelector(state => state.profile);
    const [editView, setEditView] = useState(!!editPracticeArea.levels?.[0]?.user_subject_id);

    const initialValues = {
        subject: editPracticeArea.name,
        levels: editPracticeArea.levels
    };

    let nonSelectedSubjects = [];
    let filteredLevels = [];

    filterLevels();
    filterSubjects();

    function filterLevels(subject) {
        const staticSubject = !editView ? editPracticeArea :
            subjects.find(s => s.id === (editPracticeArea.id));

        filteredLevels = assignDisableToLevels(subject || staticSubject, levels);
    }

    function filterSubjects() {
        const oldSubjectIds = practiceAreas.map(p=>p.id);

        nonSelectedSubjects = subjects.filter(s=>!oldSubjectIds.includes(s.id));
    }

    useEffect(() => {
        dispatch(getSubjects());
        dispatch(getLevels());

        return ()=> dispatch(resetCurrentPracticeArea())

    }, []);

    const onSubmit = () => {
        const practiceAreasReducer = editView ? updatePracticeArea : addPracticeArea;

        const params = {
            practiceAreas: [
                {
                    subject_id: editPracticeArea.id,
                    subject_name: editPracticeArea.name,
                    levels: editPracticeArea.levels.map(({name, id, user_subject_id, level_new, level_removed}) => {
                        return {
                            user_subject_id: user_subject_id,
                            level_id: id,
                            level_name: name,
                            level_new: !!level_new,
                            level_removed: !!level_removed
                        }

                    })
                }
            ]
        };
        dispatch(practiceAreasReducer(params, user_uuid));
        onCloseModal();
    };

    const levelsChange = (field, values) => {
        values = values || [];
        const oldSubjectLevels = practiceAreas.find(p=> p.id === editPracticeArea.id);

        //mark removed
        const removed = _.cloneDeep(editPracticeArea.levels).flatMap(l => {

            if (!_.find(values, {id: l.id}) && _.find(oldSubjectLevels?.levels || [], {id: l.id})) {
                Object.assign(l, {level_removed: true});
                return l;
            }
            return []
        });

        //mark added
        const added = _.cloneDeep(values).map(l => {
            if (!_.find(oldSubjectLevels?.levels || [], {id: l.id})) {
                Object.assign(l, {level_new: true})
            }
            return l
        });
        setFieldValue(field, values);
        dispatch(updateLevels([...removed, ...added]))
    };

    const subjectChange = (field, value) => {
        setFieldValue(field, value);
        dispatch(setCurrentPracticeArea({...value,levels: []}));

        setFieldValue('levels', []);
        filterLevels(value);
    };

    const onDelete = () => {

        const params = {
            subject: {
                levels: editPracticeArea.levels.map(({name, id, user_subject_id}) => {
                    return {
                        user_subject_id: user_subject_id,
                        level_id: id,
                        level_name: name,
                    }
                })
            }
        };
        const subject = editPracticeArea.name;
        if(practiceAreas.length > 1) {
            dispatch(openConfirmationModal(Confirmation.deleteSubject));
        } else {
            dispatch(openConfirmationModal(Confirmation.deleteLastSubject));
        }

        onCloseModal({params, user_uuid, subject});
    };




    const {values, errors, touched, handleSubmit, setFieldValue, setFieldTouched, isValid, dirty} = useFormik({
        initialValues,
        validationSchema: editView ? editExpertise : addExpertise,
        onSubmit,
    });


    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            center
        >
            <div className="modal-body">
                <h3 className="modal-title">{!editView ? 'Add subject' : 'Edit my subject'}</h3>
                <form onSubmit={handleSubmit}>
                    <ul className="li-space pb-7">
                        <li className='label-inline'>
                            <label>Subject</label>

                            {
                                !editView ?
                                    <div className={`select-box w-100 pb-7 ${errors.subject?.name && touched.subject?.name ? 'has-error' : ''}`}>
                                        <Select
                                            name='subject'
                                            className='subject'
                                            classNamePrefix='select'
                                            placeholder={false}
                                            onChange={v => subjectChange('subject', v)}
                                            options={constructSelectOption(nonSelectedSubjects || [])}
                                            isSearchable={false}
                                            onBlur={() => setFieldTouched(`subject.name`, true)}
                                        />
                                        {errors.subject?.name && touched.subject?.name && <p className='error-message-general bottom'>{errors.subject?.name}</p>}
                                    </div> :
                                    <p className='mt-3'>{editPracticeArea.name}</p>
                            }

                        </li>
                        <li className='label-inline'>
                            <label>Subject level</label>
                            <div className={`select-box w-100 pb-7 ${errors.levels && touched.levels ? 'has-error' : ''}`}>
                                <Select
                                    name='levels'
                                    className='subject'
                                    classNamePrefix='select'
                                    placeholder={false}
                                    options={constructSelectOption(filteredLevels || [])}
                                    isMulti
                                    onChange={v => levelsChange('levels', v)}
                                    value={constructSelectOption(values.levels) || ''}
                                    onBlur={() => setFieldTouched(`levels`, true)}
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={!(editPracticeArea.name)}
                                />
                                {errors.levels && touched.levels && <p className='error-message-general bottom'>{errors.levels}</p>}
                            </div>
                        </li>
                    </ul>
                    <div className="modal-footer side-aligned">
                        {
                            editView &&
                            <div className="content-left">
                                <Button
                                    styleType='secondary'
                                    className={'small'}
                                    onClick={onDelete}
                                >
                                    Delete
                                </Button>
                            </div>

                        }

                        <div className='content-right push-right'>
                            <Button
                                onClick={onCloseModal}

                                className='text-underline'
                                styleType='tertiary'
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                className='mobile-primary w-126 small'
                                disabled={!(isValid && dirty)}
                            >
                                Save
                            </Button>
                        </div>

                    </div>
                </form>
            </div>
        </Modal>
    )
};

export default ManageExpertise;
