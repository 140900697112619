import React from 'react';
import {Button} from "elements";
import {Modal} from "react-responsive-modal";
import {useDispatch, useSelector} from "react-redux";
import {closeConfirmationModal} from "vendors/redux/slices/layoutSlice";

const ConfirmationModal = ({onDelete, subject}) => {
    const {open, body} = useSelector(state => state.layout.confirmationModal);
    const dispatch = useDispatch();

    const onCloseModal = () => {
        dispatch(closeConfirmationModal())
    };

    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            center
        >
            <div className='modal-body'>
                <h3 className='modal-title'>{body.title}</h3>
                {
                    body.subject &&
                    <div className='label-inline pb-10'>
                        <label>Subject</label>
                        <p className='color-dark mt-3'>{subject}</p>
                    </div>
                }

                <p className='color-danger fw-700 pb-7'>{body.warningTitle}</p>
                {body.warningText && <p className='color-danger pb-7'>{body.warningText}</p>}
                <p className='modal-text'>{body.text}</p>
                {body.note && <p className='text-xs pb-7'>{body.note}</p>}

                <div className="modal-footer">
                    <Button
                        onClick={onCloseModal}
                        className='text-underline'
                        styleType='tertiary'
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        styleType='secondary'
                        className='w-126 small'
                        onClick={onDelete}
                    >
                        {body.submit}
                    </Button>
                </div>
            </div>
        </Modal>

    );
};

export default ConfirmationModal;
