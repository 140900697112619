import Base from './base';

class Auth extends Base {

    me() {
        return this.get('user/me');
    }

    login(data) {
        return this.post('user/login', data)
    }

    create(data) {
        return this.post('user/signup', data)
    }

    emailExists(data) {
        return this.post('user/email-exists', data);
    }

    usernameExists(data) {
        return this.post('user/username-exists', data);
    }

    twilioSendCode(data) {
        return this.post(`user/twilio-send-code`, data)
    }

    twilioVerifyCode(data) {
        return this.post(`user/twilio-verify-code`, data)
    }

    forgotPassword(data) {
        return this.post(`user/forget-password`, data)
    }

    checkResetPassToken(token) {
        return this.get(`user/forget-password/${token}`)
    }

    resetPassword(data) {
        return this.post(`user/reset-password`, data)
    }

    becomeCoach(data) {
        return this.put(`user/become-tutor`, data)
    }
}

export default new Auth();
