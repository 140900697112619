import { createSlice } from "@reduxjs/toolkit";
import userApi from "../../services/api/user";
import problemApi from "../../services/api/problem";
import Api from "../../services/api/reviews";

const initialState = {
    reviewee: {
        profile: {},
        averageRating: null,
    },
    problem: {},
    reviewData: {},
    currentStep: 1,
    error: null
};

const addReviewSlice = createSlice({
    name: "addReview",
    initialState,
    reducers: {
        setReviewee: (state, action) => {
            state.reviewee = action.payload;
            return state;
        },
        resetReviewee: (state) => {
            state.reviewee = {};
            return state;
        },
        setProblem: (state, action) => {
            state.problem = action.payload;
            return state;
        },
        resetProblem: (state) => {
            state.problem = {};
            return state;
        },
        setReviewData: (state, action) => {
            Object.assign(state.reviewData, {...action.payload});
            return state;
        },
        changeCurrentStep: (state, action) => {
            state.currentStep = action.payload;
            return state;
        },
        reset: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
    },
});

export const {
    setReviewee,
    setProblem,
    setReviewData,
    changeCurrentStep,
    resetReviewee,
    resetProblem,
    reset,
    actionError,
} = addReviewSlice.actions;


export const getRevieweeProfile= (user_uuid) => async dispatch => {

    try {
        const payload = await userApi.getPublicProfile(user_uuid);
        dispatch(setReviewee(payload.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const getProblem = (problem_uuid) => async dispatch => {
    try {
        const payload = await problemApi.singleProblem(problem_uuid);
        dispatch(setProblem(payload.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const addReview = (data) => async dispatch => {
    try {
        await Api.addReview(data);
        // dispatch(getProblemsPendingReview())
    } catch (e) {
        dispatch(actionError(e))
    }
};

export default addReviewSlice.reducer;
