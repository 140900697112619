import React from 'react';
import ReactDOM from 'react-dom';
import {Provider, ReactReduxContext} from "react-redux";
import {store} from "./vendors/redux/store";
import App from './base/App';
import reportWebVitals from './reportWebVitals';
window.timers = {};

ReactDOM.render(
    <Provider store={store} context={ReactReduxContext}>
        <App/>
    </Provider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
