import React, {useEffect, useState} from 'react';
import {Button} from "../../../elements";
import {useDispatch, useSelector} from "react-redux";
import {setGeneralBanner} from "vendors/redux/slices/layoutSlice";
import {deleteCard} from "../../../vendors/redux/slices/paymentSlice";
import {bannerTypes} from "../../../layout/header/signedIn/configs";

const CardDeletionModal = ({onClose}) => {
    const dispatch = useDispatch()
    const {deletionSuccess} = useSelector(state => state.payment);

    const [submitting, setSubmitting] = useState();
    const resetState = () => dispatch(setGeneralBanner(false));

    const handleDelete = async () => {
        setSubmitting(true);
        await dispatch(deleteCard());
        setSubmitting(false);
    }

    const listenDeleteSuccess = () => {
        if (deletionSuccess) {
            dispatch(setGeneralBanner(bannerTypes.cardDelete));
            onClose();
        }
    };


    useEffect(resetState,[]);
    useEffect(listenDeleteSuccess, [deletionSuccess]);

    return (
        <>
            <div className='modal-body'>
                <h3 className='modal-title'>Delete my credit card</h3>
                <p className='color-danger fw-700 pb-7'>Proceed with caution.</p>
                <p className='color-danger pb-7'>You are about to remove the credit card from your account.</p>
                <p className='modal-text'>In future, if you decide to use our service but do not have any cash on your balance, you will need to add a new credit card to top up.</p>

                <div className="modal-footer delete-modal-footer">
                    <Button
                        onClick={onClose}
                        className='text-underline'
                        styleType='tertiary'
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={submitting}
                        type='submit'
                        styleType='secondary'
                        className='w-126 small'
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CardDeletionModal;
