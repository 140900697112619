import React, { useRef } from 'react';
import { Button } from 'elements'

const ActionInput = ({
                         type = 'text',
                         placeholder,
                         className='',
                         name,
                         value,
                         errorMessage,
                         onClick,
                         onChange,
                         disabled,
                         text,
                         btnType
                     }) => {
    const inputRef = useRef();

    return (
        <div className={`action-input form-input ${className} ${errorMessage ? 'has-error' : ''}`}>
            <div className='grow-1'>
                <input
                    name={name}
                    type={type}
                    onChange={onChange}
                    value={value}
                    className='input'
                    placeholder={placeholder}
                />
                { errorMessage && <p className='error-message'>{errorMessage}</p> }
            </div>

            <Button
                className='btn-action'
                type='button'
                onClick={onClick}
                disabled={disabled}
                size='small'
                styleType={btnType}
            >
                {text}
            </Button>
        </div>
    );
};

export default ActionInput;
