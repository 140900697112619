import { createSlice } from "@reduxjs/toolkit";
import Api from "../../services/api/static";

export const meetingStatuses = {
    "waiting": 0,
    "active": 1,
    "end": 2
}

const initialState = {
    subjects: [],
    levels: [],
    languages: [],
    prices: [],
    topics: [],
    roles: {},
    problemStatuses: {},
    notificationTypes: {},
    problemEvents: {},
    meetingStatuses,

    pending: null,
    success: null,
    error: null,
};

const staticSlice = createSlice({
    name: "staticData",
    initialState,
    reducers: {
        setSubject: (state, action) => {
            state.subjects = action.payload;
            return state;
        },
        setLevels: (state, action) => {
            state.levels = action.payload;
            return state;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
            return state;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
            return state;
        },
        setPrices: (state, action) => {
            state.prices = action.payload;
            return state;
        },
        reset: () => {
            return initialState;
        },
        resetSubjects: (state) => {
            state.subjects = [];
            return state;
        },
        resetLevels: (state, action) => {
            state.levels = action.payload;
            return state;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        setTopics: (state, action) => {
            state.topics = action.payload;
            return state;
        },
        setProblemStatuses: (state, action) => {
            state.problemStatuses = action.payload;
            return state;
        },
        setNotificationTypes: (state, action) => {
            state.notificationTypes = action.payload;
            return state;
        },
        setProblemEvents: (state, action) => {
            state.problemEvents = action.payload;
            return state;
        },
    },
});

export const {
    setSubject,
    reset,
    resetSubjects,
    resetLevels,
    setLevels,
    setLanguages,
    setRoles,
    setPrices,
    setTopics,
    setProblemStatuses,
    setNotificationTypes,
    setProblemEvents,
    actionError,
} = staticSlice.actions;

export const getSubjects = () => async (dispatch) => {
    try {
        const res = await Api.subjects();
        dispatch(setSubject(res?.data.subjects || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getLevels = () => async (dispatch) => {
    try {
        const res = await Api.levels();
        dispatch(setLevels(res?.data.levels || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getLanguages = () => async (dispatch) => {
    try {
        const res = await Api.languages();
        dispatch(setLanguages(res?.data.languages || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getRoles = () => async (dispatch) => {
    try {
        const res = await Api.roles();
        dispatch(setRoles(res?.data.roles || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getPrices = () => async (dispatch) => {
    try {
        const res = await Api.prices();
        dispatch(setPrices(res?.data.prices || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getTopics = () => async (dispatch) => {
    try {
        const res = await Api.topics();
        dispatch(setTopics(res?.data.topics || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getProblemStatuses = () => async (dispatch) => {
    try {
        const res = await Api.problemStatuses();
        dispatch(setProblemStatuses(res?.data.statuses || {}));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getNotificationTypes = () => async (dispatch) => {
    try {
        const res = await Api.notificationTypes();
        dispatch(setNotificationTypes(res?.data.notificationTypes || {}));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getProblemEvents = () => async (dispatch) => {
    try {
        const res = await Api.problemEvents();
        dispatch(setProblemEvents(res?.data.problemEvents || {}));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export default staticSlice.reducer;
