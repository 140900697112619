import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {SignUpFirst, SignUpSecond, SignUpThird, SignUpLastStep} from "component/auth/signUpForm";
import {resetSignUpData, setRole, twilioSendCode} from "vendors/redux/slices/authSlice"
import {changeCurrentStep} from "../../../vendors/redux/slices/authSlice";
import {resetLanguages} from "../../../vendors/redux/slices/userLanguages";
import AuthEnhancer from '../AuthEnhancer'
import {Button} from "elements";


const stepCount = {
    1: '3',
    2: '4'
};
const stepNotes = [
    'Get started with veepow',
    'Verify your mobile number',
    'What subjects would you like to teach?',
    'Almost done'
];

const SignUp = (props) => {
    const dispatch = useDispatch();

    const {data} = useSelector(state => state.auth.signUp);
    const {roles} = useSelector(state => state.staticData);
    const {currentStep} = useSelector(state => state.auth);
    const [stepViews, setStepViews] = useState([]);
    let role = props.location?.state?.role || data.role;

    const removeThirdStep = () => {
        if (role === roles?.STUDENT) {
            stepViewsStatic.splice(2, 1);
            stepNotes.splice(2, 1)
        }
        setStepViews(stepViewsStatic);
    };

    useEffect(() => {
        role && dispatch(setRole(role));
        removeThirdStep();

        return () => {
            dispatch(resetLanguages());
            dispatch(resetSignUpData());
            dispatch(changeCurrentStep(1))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resendCode = () => {
        dispatch(twilioSendCode({phone: data.phone}));
    };
    const stepContents = {
        formTitle: stepNotes[currentStep-1],
        filled: currentStep * 100 / stepCount[role]
    };

    const stepViewsStatic = [
        <>
            <p className="text-center text-normal form-subtitle">Already have an account? <Link to={'/sign-in'}
                                                                                                className="link-primary">Sign
                in</Link></p>
            <SignUpFirst/>
        </>,
        <>
            <p className="text-center text-normal form-subtitle">Didn’t receive a code? <Button
                styleType="no-style underline" onClick={resendCode}>Resend</Button></p>
            <SignUpSecond/>
        </>,
        <SignUpThird/>,
        <SignUpLastStep/>
    ];

    return (
        <AuthEnhancer>
            <div className='slider-bar-wrapper'>
                <p className='slider-bar-text'>{`Step ${currentStep} of ${stepCount[role]}`}</p>
                <div className="slider-bar"><span className={'filled'} style={{width: stepContents.filled + '%'}}/>
                </div>
            </div>
            <h3 className="text-center form-title mt-10">{stepContents.formTitle}</h3>
            {
                stepViews[currentStep - 1]
            }
        </AuthEnhancer>
    );
};

export default SignUp;
