import * as Yup from 'yup';
import errorMessages from './errorMessages.js';

export const validationMessages = errorMessages('validation');

export const manageEducation = () => {

    return Yup.object().shape({

        school: Yup.string()
            .required(validationMessages.required),
        from: Yup
            .object().shape({
                label: Yup.number(),
                value: Yup.number()
            }),
        to: Yup.object().shape(
            {
                label: Yup.number(),
                value: Yup.number()
            }
        ).test('more than to', validationMessages.fromTo, function () {
            const {to, from} = this.parent;
            if (to?.value && from?.value) return to?.value >= from?.value;
            return true
        })

    });
};

export const addExpertise = () => {

    return Yup.object().shape({

        subject: Yup.object().shape({
            name: Yup.string()
                .required(validationMessages.requiredSubject),
        }),

        levels: Yup.array()
            .min(1, validationMessages.oneOrMore)
            .required(validationMessages.required)
            .nullable(),

    });
};

export const editExpertise = () => {

    return Yup.object().shape({
        levels: Yup.array()
            .min(1, validationMessages.oneOrMore)
            .required(validationMessages.required)
            .nullable(),

    });
};
