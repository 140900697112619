import React, {useEffect} from "react";
import {useQuery} from 'vendors/hooks';
import {useDispatch, useSelector} from "react-redux";
import {authorizeStripeAccount} from "vendors/redux/slices/paymentSlice";
import {Spinner} from "../elements";


const StripeVerifyAccount = () => {
    const dispatch = useDispatch();
    const query = useQuery()

    const code = query.get('code');

    const {generalErrorModal} = useSelector(state=>state.layout)

    useEffect(() => {
        if (code) {
            dispatch(authorizeStripeAccount(code))
        }
    },[]);

    return (<>{!generalErrorModal.open && <Spinner/>}</>);
};

export default StripeVerifyAccount;
