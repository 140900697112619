import Base from './base';

class Workspace extends Base {

    createMeeting(data) {
        return this.post('workspace/zoom/create-meeting',data);
    }

    getMeeting(data) {
        return this.get('workspace/get-meeting', data);
    }

    userReady(data) {
        return this.post('workspace/user-ready', data);
    }

    userJoin(data) {
        return this.post('workspace/user-join', data);
    }

    userLeave(data) {
        return this.post('workspace/user-leave', data);
    }

    endMeeting(data) {
        return this.post('workspace/end-meeting', data);
    }
}

export default new Workspace();
