import React, {useState} from 'react';
import DeletionModal from "./modals/DeletionModal";

const AccountDeletion = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <div className="account-info">
                <div className="content flex">
                    <button
                        onClick={handleOpen}
                        className='text-underline btn-link link-medium'
                    >
                        Permanently delete my account
                    </button>
                </div>
            </div>
            <DeletionModal
                open={open}
                onClose={handleClose}
            />
        </>

    );
};

export default AccountDeletion;
