import React from 'react';
import { ForgotPasswordForm } from "component/auth";
import AuthEnhancer from '../AuthEnhancer'
import {Link} from "react-router-dom";

const ForgotPassword = () => {
    return (
        <AuthEnhancer>
            <h3 className="text-center form-title">Forgot your password?</h3>
            <p className="text-center text-normal form-subtitle">Fill in your email below and we’ll send you a link to reset your password.</p>
            <ForgotPasswordForm />
            <div className='text-center pt-7'>
                <Link to={'/sign-in'} className='underline text-xs'>Back</Link>
            </div>
        </AuthEnhancer>
    );
};

export default ForgotPassword;
