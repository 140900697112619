import React, {useState} from 'react';
import {Modal} from "react-responsive-modal";
import { Icomoon } from 'elements';
import EditPassword from "./modals/EditPassword";
import {useDispatch} from "react-redux";
import {resetChangePassword} from "vendors/redux/slices/accountSlice";

const Password = () => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        dispatch(resetChangePassword());
        setOpen(false)
    };

    return (
        <div className="account-info">
            <div className="content-top flex align-center justify-between">
                <h3>Password</h3>
                <button onClick={onOpenModal} className='no-style pointer btn-icon'>
                    <Icomoon name='edit'/>
                </button>
                <Modal
                    open={open}
                    onClose={onCloseModal}
                    center>
                    <EditPassword
                        onCloseModal={onCloseModal}
                    />
                </Modal>
            </div>
            <div className="content flex">
                <ul className="content-left data-list">
                    <li>
                        <label className="text-label">Password</label>
                        <p className="password-text text-value">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Password;
