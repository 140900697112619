import Base from './base';

class Problem extends Base {

    createProblem(data) {
        return this.post(`problem/create`, data)
    }

    closeProblem(data) {
        return this.post(`problem/close`, data)
    }

    singleProblem(data) {
        return this.get(`problem/single-problem`, data)
    }

    acceptProblem(data) {
        return this.post(`/problem/accept`, data)
    }

   updateProblem(data) {
        return this.post(`/problem/start-timer`, data)
    }

    extendTimer(data) {
        return this.post(`/problem/extend-timer`, data)
    }

    expireProblem(data) {
        return this.post(`/problem/expire`, data)
    }

    inbox(data) {
        return this.get(`/problem/inbox-list`, data)
    }

    addFile(data) {
        return this.post(`problem/add-file`, data, {'Content-Type': 'multipart/form-data'})
    }

    deleteFile(data) {
        return this.delete(`problem/delete-file`, data)
    }

    checkVoucher(data) {
        return this.post(`/voucher/check`, data)
    }

    getActiveProblem(data) {
        return this.get(`/problem/active-problem`, data)
    }

}

export default new Problem();
