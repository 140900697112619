import React from "react";
import {Button, CircularProgressbar} from "../../../elements";
import SadWoman from "../../../assets/img/postProblem/sad.png";
import {useHistory} from "react-router";

const ProblemExpire = () => {
    const {push} = useHistory();

    const handlePush = () => {
        push({
            pathname: '/account-profile',
            hash: "#area-of-expertise",
            state: {
                tab: 2
            }
        })
    }

    return (
        <div className="text-center">
            <div className='text-center pb-8'>
                <CircularProgressbar
                    src={SadWoman}
                    value={100}
                    pathColor="#1B4569"
                />
            </div>

            <h3>Oops, this job is no longer available.</h3>
            <h4 className='fw-500 pt-8'>Not to worry, more jobs like this will come along as our community
                of students is growing daily.</h4>
            <h4 className='fw-500 pt-8 pb-10'>In the meantime, why not increase your chances of getting work
                by expanding the subjects you can help with?</h4>
            <Button onClick={handlePush} styleType='secondary' size='small medium-text'>Show me my subject list</Button>
        </div>
    )
}

export default ProblemExpire;
