import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from 'elements';
import Logo from 'assets/img/logo.svg'


const Header = () => {
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [scrolled,setScrolled] = useState(false);

    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 0 ){
            setScrolled(true);
        }
        else{
            setScrolled(false);
        }
    };

    const handleDrawerToggle = () => {
        setDrawerOpened(!drawerOpened);
    };

    useEffect(() => {
        window.addEventListener('scroll',handleScroll);
        return () => {
            window.removeEventListener('scroll',handleScroll);
        }
    }, []);

    useEffect(() => {
        if (drawerOpened) {
            document.body.classList.add('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden')
        }
    },[drawerOpened]);

    return (
        <header className={`header ${drawerOpened ? 'open' : ''} ${scrolled ? 'sticky' : ''}`}>
            <div className="container">
                <div className="header-content">
                    <Link  className='logo' to={'/'}>
                        <img src={Logo} alt="veepow"/>
                    </Link>
                    <button
                        className={`hamburger hamburger--slider ${drawerOpened ? 'is-active' : ''}`}
                        type="button"
                        onClick={handleDrawerToggle}
                    >
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                    </button>
                    <nav className='header-nav'>
                        <div className="header-nav-inner">
                            <ul className='header-nav-list'>
                                <li>
                                    <Link to={'/how-it-works'}>How it works</Link>
                                </li>
                                <li>
                                    <Link to={'/pricing'}>Pricing</Link>
                                </li>
                                <li className='with-border'>
                                    <Link to={'/become-a-coach'}>Become a coach</Link>
                                </li>
                                <li className='hide-mobile'>
                                    <Link to={'sign-in'}>Sign in</Link>
                                </li>
                                <li className='show-mobile'>
                                    <Link to={'/about-us'}>About us</Link>
                                </li>
                                <li className='show-mobile'>
                                    <Link to={'/trust-and-safety'}>Trust & Safety</Link>
                                </li>
                                <li className='show-mobile'>
                                    <Link to={'/contact-us'}>Contact us</Link>
                                </li>
                            </ul>
                            <div className='button-group'>
                                <ButtonLink to={'/sign-up'} className='mobile-primary' styleType='secondary'>Get started</ButtonLink>
                                <ButtonLink to={'/sign-in'} className='show-mobile mt-10' styleType='secondary'>Sign in</ButtonLink>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
