import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/reviews";


const initialState = {
    problemsReview: [],
    error: null
};

const BannerSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        setProblemReviewBanner: (state, action) => {
            state.problemsReview = action.payload

            return state;
        },
        resetProblemReviewBanner: (state, action) => {
            state.problemsReview = []
            return state;
        },
        reset: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setProblemReviewBanner,
    resetProblemReviewBanner,
    actionError,
    reset
}
    = BannerSlice.actions;


export const getProblemsPendingReview = (user_uuid) => async dispatch => {
    try {
        const data = await Api.problemsPendingReview({user_uuid});
        dispatch(setProblemReviewBanner(data.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};


export default BannerSlice.reducer
