import React from 'react';

const FounderModal = ({data}) => {
    return (
        <div>
            <div className="img">
                <img src={data.src} alt={data.fullName}/>
            </div>
            <div className='founder-details'>
                <h3 className='pb-2'>{data.fullName}</h3>
                <p>{data.position}</p>
            </div>
            <p>{data.text}</p>
        </div>
    );
};

export default FounderModal;
