import { createSlice } from "@reduxjs/toolkit";
import Api from "../../services/api/support";

const initialState = {};

const supportSlice = createSlice({
  name: "supportData",
  initialState,
  reducers: {
    setResponse: (state, action) => {
      state.response = action.payload;
      return state;
    },
    resetState: (state) => {
      state = {};
      return state;
    },
    actionError: (state, action) => {
      state.error = action.payload;
      return state;
    },
  },
});

export const { setResponse, actionError, resetState } = supportSlice.actions;

export const sendMessage = (data) => async (dispatch) => {
  try {
    const payload = await Api.sendMessage(data);
    dispatch(setResponse(payload.data));
  } catch (e) {
    dispatch(actionError(e));
  }
};

export const clearResponse = () => async (dispatch) => {
  dispatch(resetState());
};

export default supportSlice.reducer;
