import * as Yup from "yup";

import errorMessages from "./errorMessages.js";
const validationMessages = errorMessages("validation");

export const topUpField = {
    amount: Yup.string()
        .required(validationMessages.required)
        .test('min val',
            validationMessages.required,
            value => {
                return value !== 'S$';
            })
        .test('min val',
            validationMessages.topUpMin,
            value => {
                let clean = 0;
                if (value) {
                    clean = value?.replace('S$', '')
                }
                return clean >= 15
            })
};

export const topUpSchema = () => Yup.object().shape(topUpField);
