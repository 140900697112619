import React from 'react';
import {Link} from "react-router-dom";
import Logo from 'assets/img/logo.svg'
import FooterEnhancer from "../../layout/footer/FooterEnhancer";

const AuthEnhancer = ({
    children
}) => {
    return (
        <div className="enhancer-page">
            <div className="container">
                <div className="logo text-center">
                    <Link to={'/'}><img src={Logo} alt=""/></Link>
                </div>
                <div className="enhancer-inner">{children}</div>
                <FooterEnhancer />
            </div>
        </div>
    );
};

export default AuthEnhancer;
