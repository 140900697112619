import {createSlice} from '@reduxjs/toolkit'
import {banners} from "../../../layout/header/signedIn/configs";

const generalMessage = 'Sorry, something has gone wrong. Please try again later!'

const initialState = {
    generalErrorModal: {
        open: null,
        message: generalMessage,
        exception: null
    },
    confirmationModal: {
        open: null,
        body: {}
    },

    generalBanner: false,
    paymentBanner: false,
    openedDrawer: false
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        openGeneralErrorModal: (state, action) => {
            const {data = {}} = action.payload || {};
            const {exception = {}} = data;

            const message = exception?.message || data?.message ||  generalMessage

            Object.assign(state.generalErrorModal, {
                message,
                exception: action.payload || null,
                open: true
            });
            return state;
        },
        closeGeneralErrorModal: (state) => {
            Object.assign(state.generalErrorModal, {
                message: generalMessage,
                exception: null,
                open: false
            });
            return state;
        },
        openConfirmationModal: (state, action) => {
            Object.assign(state.confirmationModal, {
                open: true,
                body: action.payload
            })
        },
        closeConfirmationModal: (state) => {
            Object.assign(state.confirmationModal, {
                open: false,
                body: {}
            })
        },
        openDrawer: (state, action) => {
            state.openedDrawer = true;
            return state
        },
        closeDrawer: (state, action) => {
            state.openedDrawer = false;
            return state
        },

        setGeneralBanner: (state, action) => {
            const banner = banners[action.payload];

            if (banner) {
                state.generalBanner = banner;
            }
            else {
                state.generalBanner = false;
            }
            return state
        },
        setPaymentBanner: (state, action) => {
            state.paymentBanner = action.payload;
            return state
        },
        reset: () => {
            return initialState;
        }
    }
});

export const {
    openGeneralErrorModal,
    closeGeneralErrorModal,
    openConfirmationModal,
    closeConfirmationModal,
    openDrawer,
    closeDrawer,
    setPaymentBanner,
    setGeneralBanner,
    reset
}
    = layoutSlice.actions;


export default layoutSlice.reducer
