import React from 'react';
import {Button} from "../../elements";
import {useHistory} from "react-router";

const ReviewThirdStep = () => {
    const {replace} = useHistory()

    const handleReplace = () => {
        replace('/account-profile')
    }
    return (
        <div className='text-center'>
            <h2 className='section-title pb-10'>Thank you.</h2>
            <Button onClick={handleReplace} styleType='secondary small medium-text'>Return to my account</Button>
        </div>
    );
};

export default ReviewThirdStep;
