import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import {useFormik} from "formik";
import {personalInfoSchema as validationSchema} from 'vendors/services/yup-schemas/accountSchemas'
import {Button, Input} from "elements"
import {updateAccount, emailExists, resetEmailApiError} from "vendors/redux/slices/accountSlice";
import SelectLanguage from "../../selectUserLanguage/selectLanguageForm";
import {constructSelectOption} from "../../../vendors/utils";
import {dateOfBirthRegex} from "../../../vendors/services/yup-schemas/helpers";

const EditProfile = ({onCloseModal}) => {
    const dispatch = useDispatch();

    const {account, emailExists: emailExistsStore} = useSelector(state => state.account);
    const {userLanguages} = useSelector(state => state.account);
    const {formLanguages} = useSelector(state => state.userLangForm);

    const defaultLanguage = [
        {
            id: 1,
            value: 'English',
            label: 'English',
            name: 'English'
        }
    ];

    const initialValues = {
        first_name: account.first_name,
        family_name: account.family_name,
        date_of_birth: account.date_of_birth || '',
        email: account.email,
        about: account.about || '',
        language: userLanguages?.length ? constructSelectOption(userLanguages) : defaultLanguage,
    };

    useEffect(() => {
        if (emailExistsStore.exists === false) {
            updateAccountData()
        }
        if (emailExistsStore.exists === true) {
            setFieldTouched('email')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailExistsStore.exists]);

    const updateAccountData = () => {
        dispatch(resetEmailApiError());
        dispatch(updateAccount(values));
        onCloseModal()
    };

    const onSubmit = (values) => {
        if (account.email !== values.email) {
            dispatch(emailExists({email: values.email}));
        } else {
            updateAccountData()
        }
    };

    const onEmailChange = (e) => {
        if (emailExistsStore.exists) {
            dispatch(resetEmailApiError());
        }
        handleChange(e)
    };
    const onDateOfBirthChange = (e) => {
        const input = e.target.value;
        const match = dateOfBirthRegex.test(input);
        match && handleChange(e);
    };

    const onDateOfBirthBlur = (e) => {
        const input = e.target.value;
        const match = dateOfBirthRegex.test(input);
        match && handleBlur(e);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: () => validationSchema(emailExistsStore),
        onSubmit
    });

    let {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        handleFocus,
        setFieldTouched,
        isValid,
        dirty
    } = formik;


    return (
        <>
            <div className="modal-body">
                <h3 className="modal-title">Edit my personal information</h3>
                <form onSubmit={handleSubmit}>
                    <ul className="li-space mobile-sm">
                        <li className='label-inline'>
                            <label>First name*</label>
                            <Input
                                name='first_name'
                                className='mb-0'
                                value={values.first_name}
                                touched={touched.first_name}
                                errorMessage={errors.first_name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                handleFocus={handleFocus}
                            />
                        </li>
                        <li className='label-inline'>
                            <label>Family name*</label>
                            <Input
                                name='family_name'
                                className='mb-0'
                                value={values.family_name}
                                touched={touched.family_name}
                                errorMessage={errors.family_name}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                handleFocus={handleFocus}
                            />
                        </li>
                        <li className="label-inline">
                            <label>Date of birth*</label>
                            <Input
                                name='date_of_birth'
                                className='mb-0'
                                errorMessage={errors.date_of_birth}
                                value={values.date_of_birth}
                                touched={touched.date_of_birth}
                                placeholder={'DD/MM/YYYY'}
                                handleBlur={onDateOfBirthBlur}
                                handleChange={onDateOfBirthChange}
                            />
                        </li>
                        <li className='label-inline'>
                            <label>Email*</label>
                            <Input
                                name='email'
                                className='mb-0'
                                type='email'
                                value={values.email}
                                touched={touched.email}
                                errorMessage={errors.email}
                                handleBlur={handleBlur}
                                handleChange={onEmailChange}
                                handleFocus={handleFocus}
                            />
                        </li>
                        {
                            account.was_tutor &&
                            <li className='label-inline mn-0'>
                                <label>Languages* <br/>spoken</label>
                                <div
                                    className="w-100">
                                    <SelectLanguage formik={formik}/>
                                </div>
                            </li>
                        }
                        <li className="label-inline">
                            <label className="mt-0">Location</label>
                            <div className='flex justify-between w-100'>
                                <p className="color-secondary-2">Singapore</p>
                                <i data-tip="The veepow service is currently available to residents of Singapore only."
                                   className="icon-question-circle pointer"/>
                                <ReactTooltip
                                    effect="solid"
                                    place="bottom"
                                />
                            </div>

                        </li>
                        <li className='label-inline'>
                            <label>About</label>
                            <div className="form-input mb-0">
                                <textarea
                                    name='about'
                                    placeholder="Write a short intro for your public profile"
                                    cols="30"
                                    rows="4"
                                    value={values.about}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    maxLength="1000"
                                />
                            </div>
                        </li>
                    </ul>
                    <div className="modal-footer">
                        <Button
                            onClick={onCloseModal}
                            className="text-underline"
                            styleType='tertiary'
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='w-126 small'
                            disabled={!(isValid && dirty)}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </div>

        </>
    )
};

export default EditProfile;

