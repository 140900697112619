import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-responsive-modal";
import {closeGeneralErrorModal} from "vendors/redux/slices/layoutSlice";
import {Button} from "elements";


const GeneralErrorModal = () => {
    const dispatch = useDispatch();
    const {open, message} = useSelector(state => state.layout.generalErrorModal);

    const onCloseAvatarModal = () => {
     dispatch(closeGeneralErrorModal())
    }

    return (
        <>
            {open && <Modal open={open}
                   onClose={onCloseAvatarModal}
                   center>
                <div className="modal-body">
                    <h3 className="modal-title">Oops, we have difficulties! </h3>
                    <div className="flex align-center">
                        <div className="content-right">
                            <p className="text-xs color-secondary-2 mt-4">{message}</p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        onClick={onCloseAvatarModal}
                        className="text-underline"
                        styleType='tertiary'
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
            }
        </>
    )
};

export default GeneralErrorModal;
