import Base from './base';

class User extends Base {

    changePassword(data) {
        return this.post(`user/change-password`, data)
    }

    changeAvatar(data) {
        return this.post(`user/change-avatar`, data, {'Content-Type': 'multipart/form-data'})
    }

    addEducation(data) {
        return this.post(`user/add-education`, data)
    }

    updateEducation(data) {
        return this.post(`user/update-education`, data)
    }

    deleteEducation(data) {
        return this.post(`user/delete-education`, data)
    }

    getAccount(data) {
        return this.get(`user/account`, data)
    }

    updateAccount(data) {
        return this.put(`user`, data)
    }
    changeUserRole(data) {
        return this.put(`user/change-role`, data)
    }

    getPublicProfile(user_uuid) {
        return this.get(`user/public-profile`, {user_uuid})
    }
    addPracticeArea(data) {
        return this.post(`user/add-subject`, data)
    }
    updatePracticeArea(data) {
        return this.post(`user/update-subject`, data)
    }
    deletePracticeArea(data) {
        return this.post(`user/delete-subject`, data)
    }
    changePracticeAreaStatus(data) {
        return this.post(`user/change-subject-status`, data)
    }
    updateLanguages(data) {
        return this.post(`user/update-languages`, data)
    }

    accountDeleteRequest(data) {
        return this.post(`user/account-delete-request`, data)
    }

}

export default new User();
