import React from 'react';
import { Link } from 'react-router-dom';
import {companyList, supportList} from './configs'
import { ActionInput, Icomoon } from 'elements';
import LogoWhite from 'assets/img/logo-white.svg'
import {getThisYear} from "../../vendors/utils";

const Footer = ({zIndex}) => {

    return (
        <footer className={`footer ${zIndex ? 'z-index pos-rel' : ''}`}>

            {/*<div className="support-section">
                <div className="container">
                    <h3>Need help? Call our support team 24/7 at +65 1234-5678</h3>
                </div>
            </div>*/}

            <div className="container">
                <div className="footer-top">
                    <div className='footer-top-content'>
                        <div className='footer-left'>
                            <div className='footer-list'>
                                <p className='title'>Company</p>
                                <ul>
                                    {
                                        companyList().map(({linkText, path}, index) => {
                                            return (
                                                <li key={`item-${index}`}>
                                                    <Link to={path}>{linkText}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className='footer-list'>
                                <p className='title'>Support</p>
                                <ul>
                                    {
                                        supportList().map(({linkText, path}, index) => {
                                            return (
                                                <li key={`item-${index}`}>
                                                    <Link to={path}>{linkText}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div>
                            {/*<p>Get our newsletter, join our community:</p>
                            <div className='pt-4'>
                                <ActionInput
                                    text='Sign up'
                                    placeholder='Email address'
                                    className='input-group'
                                />
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <Link className='logo' to={'/'}>
                        <img src={LogoWhite} alt="veepow"/>
                    </Link>
                    <p className='text-xs'>© { getThisYear() } Veepow Asia Pte Ltd </p>
                    <ul className='footer-social-list'>
                       {/* <li>
                            <Link to={'/'}>
                                <Icomoon
                                    color='#f7f7f7'
                                    size={25}
                                    name='facebook'
                                />
                            </Link>
                        </li>*/}
                        <li>
                            <Link to={{ pathname: 'https://www.linkedin.com/company/veepow'}} target="_blank">
                                <Icomoon
                                    color='#f7f7f7'
                                    size={25}
                                    name='linkedin'
                                />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
