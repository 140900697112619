import React, {useRef, useState} from 'react';
import {deleteFile, uploadFile} from "../../vendors/redux/slices/postProblemSlice";
import {useDispatch, useSelector} from "react-redux";
import {Icomoon} from 'elements'

const FileUpload = () => {
    const dispatch = useDispatch();
    const uploadedFile = useRef(null);
    const fileUploader = useRef(null);
    const {data} = useSelector(state => state.postProblem);
    const [error, setError] = useState('');
    const [fileList, setFileList] = useState([]);


    const errorType = {
        sizeError: 'Please upload files up to 10MB.',
        formatError: 'Please upload JPEG, PNG or PDF files.',
    };

    const validateFile = (file) => {

        if (file?.size > 10485760) {
            setError(errorType.sizeError);
            return;
        }

        if (!file?.type.match('image/jpeg|image/png|application/pdf')) {
            setError(errorType.formatError);
            return;
        }
        setError('');
        return true
    };

    const handleFileUpload = e => {
        const files = e.target.files;
        let valid = true;

        for (const file of files) {
            if (!validateFile(file)) {
              valid = false
            }
        }

        if (valid && files.length + fileList.length <= 3) {

            setFileList([...fileList, ...files]);
            const fd = new FormData();
            for(let file of files) {
                fd.append("file", file);
            }
            dispatch(uploadFile(fd))
        }

    };
    const onFileDelete = (e, index) => {
        const filteredList = fileList.filter((val, i) => i !== index );
        setFileList(filteredList);
        dispatch(deleteFile({file_url: e.file_url}, index))
    }

    return (
        <>
            <p className='pb-2'  ref={uploadedFile}>Add attachments (up to 3)</p>
            <ul className='uploaded-list'>
                {
                    data.files.map((file, index) => {
                        return (
                            <li key={`file-${index}`}>
                                <Icomoon
                                    name='file-image'
                                    size={32}
                                />
                                <span className='file-name truncate'>{file.file_name}</span>
                                <Icomoon
                                    name='close'
                                    onClick={() => onFileDelete(file, index)}
                                    size={12}
                                    color='#FF0000'
                                />
                            </li>
                        )
                    })
                }
            </ul>

            <label className={`image-upload mt-4 ${data.files.length > 2 ? 'disabled' : ''}`}>
                Choose file
                <input
                    type="file"
                    accept=".jpeg, .jpg, .png, .pdf"
                    multiple
                    name='file'
                    id='file'
                    onChange={handleFileUpload}
                    ref={fileUploader}
                />
            </label>
            {
                error ? <p className="text-xs color-danger mt-4">{error}</p> : <p className="text-xs color-secondary-2 mt-4">JPG, PNG or PDF. Max size of 10MB</p>

            }
        </>
    );
};

export default FileUpload;
