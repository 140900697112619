import React, {useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, StarRatings } from "elements";
import ListReview from "./ListReview";
import {resetReviews} from "../../vendors/redux/slices/reviewSlice"
import {reviewsReducers, roles} from "./config"


const ReviewRow = ({userType, user_uuid, subState}) => {
    const dispatch = useDispatch();
    const {
        index,
        offset,
        limit,
        rows,
        count,
        averageRating
    } = useSelector(state => state.reviews[subState][userType]);
    const reviewsReducer = reviewsReducers[userType];
    const role = roles[userType];

    useEffect(() => {
            dispatch(reviewsReducer({user_uuid, offset, limit, role}, {index, afterReplay: null, subState}));

            return ()=> dispatch(resetReviews({subState}));
    }, []);

    const loadMore = useCallback(() => {
        const idx = index + 1;
        dispatch(reviewsReducer({user_uuid, offset:  (idx - 1) * limit, limit, role}, {index:idx, afterReplay: null, subState}));

    }, [index, limit, userType, user_uuid]);

    return (
        <div className="content reviews  flex justify-between">
            <div className="review-name w-25">
                <p className="">As {userType}</p>
            </div>
            <div className="review-content w-75">
                <div className="flex justify-between review-total">
                    <p className="fw-700 color-secondary-2">{count} reviews</p>
                    <StarRatings
                        initialRating={averageRating}
                    />
                </div>
                {
                    <ListReview
                        reviews={rows}
                        user_uuid={user_uuid}
                        subState={subState}
                        userType={userType}
                        // withBorder={rows.length < count}
                    />
                }
                {
                    rows.length < count &&
                    <div className="load-button flex justify-center">
                        <Button
                            size='w-190 small medium-text'
                            styleType='secondary'
                            onClick={loadMore}
                        >
                            Load more reviews
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
};

export default ReviewRow;
