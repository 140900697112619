import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useFormik} from "formik";
import { signUpFirstSchema as validationSchema } from 'vendors/services/yup-schemas/authSchemas'
import {
    emailExists,
    resetSignUpEmailApiError,
    resetPhoneSendCode,
    setSignUpData,
    twilioSendCode,
    changeCurrentStep
} from "../../../vendors/redux/slices/authSlice"
import {phonePrefix, onlyDigitsRegEx, dateOfBirthRegex} from "../../../vendors/services/yup-schemas/helpers";
import { Input, Button } from 'elements'
import SG from 'assets/img/flags/singapore-flag.png';

const SignUpFirst = () => {
    const dispatch = useDispatch();
    const {emailExists: emailExistsStore} = useSelector(state => state.auth.signUp);
    const {phoneCodeSend} = useSelector(state => state.auth.signUp);
    const {data} = useSelector(state => state.auth.signUp);

    const initialValues = {
        first_name: data.first_name,
        family_name: data.family_name,
        email: data.email,
        date_of_birth: data.date_of_birth || '',
        phone: data.phone || phonePrefix
    };

    useEffect(()=>{
        if (emailExistsStore.exists === false) {
            dispatch(resetSignUpEmailApiError());
            dispatch(setSignUpData(values));
            dispatch(twilioSendCode({phone: values.phone}));
        }
        if (emailExistsStore.exists === true) {
            setFieldTouched('email')
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[emailExistsStore.exists]);

    useEffect(() => {
        if (phoneCodeSend.success === true) {
            dispatch(resetPhoneSendCode());
            dispatch(changeCurrentStep(2))
        }
        if (!phoneCodeSend.success && phoneCodeSend.ui_message) {
            setFieldTouched('phone')
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[phoneCodeSend.ui_message, phoneCodeSend.success]);

    const onSubmit = (values) => {
        dispatch(emailExists({email:values.email}));
    };

    const onEmailChange = (e) => {
        if (emailExistsStore.exists) {
            dispatch(resetSignUpEmailApiError());
        }
        handleChange(e)
    };

    const onPhoneChange = (e) => {
        if(phoneCodeSend.success !== true) {
            dispatch(resetPhoneSendCode());
        }
        const input = e.target.value;
        let numbers = onlyDigitsRegEx.test(input);
        numbers && handleChange(e);
    };

    const onDateOfBirthChange = (e) => {
        const input = e.target.value;
        const match = dateOfBirthRegex.test(input);
        match && handleChange(e);
    };

    const onDateOfBirthBlur = (e) => {
        const input = e.target.value;
        const match = dateOfBirthRegex.test(input);
        match && handleBlur(e);
    };

    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, handleFocus, setFieldTouched, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: emailExistsStore.exists === true ? ()=>validationSchema(emailExistsStore) : ()=>validationSchema(phoneCodeSend)
    });

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Input
                    withCheck
                    name='first_name'
                    label='First name'
                    errorMessage={errors.first_name}
                    value={values.first_name}
                    touched={touched.first_name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                />
                <Input
                    withCheck
                    name='family_name'
                    label='Family name'
                    errorMessage={errors.family_name}
                    value={values.family_name}
                    touched={touched.family_name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                />
                <Input
                    name='date_of_birth'
                    label='Date of birth'
                    errorMessage={errors.date_of_birth}
                    value={values.date_of_birth}
                    touched={touched.date_of_birth}
                    placeholder={'DD/MM/YYYY'}
                    handleBlur={onDateOfBirthBlur}
                    handleChange={onDateOfBirthChange}
                />
                <Input
                    withCheck
                    type='email'
                    name='email'
                    label='Email address'
                    errorMessage={errors.email}
                    value={values.email}
                    touched={touched.email}
                    handleBlur={handleBlur}
                    handleChange={onEmailChange}
                    handleFocus={handleFocus}
                />
                <Input
                    flag={SG}
                    name='phone'
                    label='Mobile number'
                    errorMessage={errors.phone}
                    value={values.phone}
                    touched={touched.phone}
                    handleBlur={handleBlur}
                    handleChange={onPhoneChange}
                    maxLength={phonePrefix === '+65' ? 11 : 12}
                />

                <Button
                    type="submit"
                    className='w-100'
                    disabled={data.first_name ? !(isValid) : !(isValid && dirty)}
                >
                    Verify via sms
                </Button>
            </form>
        </div>
    );
};

export default SignUpFirst;

