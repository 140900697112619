import React from 'react';
import {useSelector} from "react-redux";


const EducationsView = () => {
    const {educations} = useSelector(state => state.guestProfile);

    return (
        <>
            <div className="account-info wrap-mobile">
                <div className="content-top">
                    <h3>Education</h3>
                </div>
                <div className="content">
                    {educations.length === 0 &&
                    <p className="text-xs color-text-primary-light w-45 expertise-info mb-3">No educational experience.</p>
                    }
                    {educations.map((item =>
                            <div className="content-top flex align-center justify-between mb-6">
                                <ul className="li-space-xxs break-word">
                                    <li className="text-value mr-3 text-bold">{item?.school}</li>
                                    <li className="text-value mr-3 text-normal">{item?.field_study}</li>
                                    <li className="text-value mr-3 text-normal">
                                        <span>{item?.from}</span> - <span>{item?.to}</span></li>
                                </ul>
                            </div>
                    ))}
                </div>
            </div>
        </>
    )
};

export default EducationsView;
