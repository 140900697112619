import * as Yup from "yup";
import errorMessages from "./errorMessages.js";
import { emailRegEx } from "./helpers";

const validationMessages = errorMessages("validation");

export const contactSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required(validationMessages.required),

    email: Yup.string()
      .required(validationMessages.required)
      .matches(emailRegEx, validationMessages.email),

    topic: Yup.object().shape({
      id: Yup.number(),
      name: Yup.string().required(validationMessages.required),
    }),

    // topic: Yup.string().required(validationMessages.required),
    message: Yup.string()
        .required(validationMessages.required)
        .max(3500),
  });
};
