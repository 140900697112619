import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useFormik } from "formik";
import { SignUpLastStepSchema as validationSchema } from 'vendors/services/yup-schemas/authSchemas'
import { Input, Button } from 'elements'
import {Link, useHistory} from 'react-router-dom';
import {
    usernameExists,
    resetSignUpUserNameApiError,
    setSignUpData,
    signUp
} from "vendors/redux/slices/authSlice";

import errorMessages from "../../../vendors/services/yup-schemas/errorMessages";
const messages = errorMessages("info");


const SignUpLastStep = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const {roles} = useSelector(state => state.staticData);
    const {usernameExists: userNameExistsStore} = useSelector(state => state.auth.signUp);
    const {data} = useSelector(state => state.auth.signUp);
    const {loggedIn} = useSelector(state => state.auth);

    const initialValues = {
        username: '',
        password: '',
    };

    useEffect(() => {
        if (userNameExistsStore.exists === false) {
            dispatch(setSignUpData(values));
        }
        if (userNameExistsStore.exists === true) {
            setFieldTouched('username')
        }
        if (userNameExistsStore.exists === false && data.username) {
            dispatch(signUp({user: data}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userNameExistsStore.exists, data.username]);

    useEffect(() => {
        if (loggedIn) {
            push('/account-profile')
        }

    },[loggedIn]);



    const onUsernameChange = (e) => {
        if (userNameExistsStore.exists !== null) {
            dispatch(resetSignUpUserNameApiError());
        }

        handleChange(e)
    };

    const onSubmit = (values) => {
        dispatch(usernameExists({username: values.username}));
    };

    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, isValid, dirty, setFieldTouched } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: ()=>validationSchema(userNameExistsStore),
    });

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Input
                    withCheck
                    type='text'
                    name='username'
                    label='Choose a username'
                    errorMessage={errors.username}
                    value={values.username}
                    touched={touched.username}
                    handleBlur={handleBlur}
                    handleChange={onUsernameChange}
                />
                <Input
                    withCheck
                    withIcon
                    type='password'
                    name='password'
                    label='Create a password'
                    className='pb-0'
                    errorMessage={errors.password}
                    value={values.password}
                    touched={touched.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    existedErrorMessage
                />
                {data.role === roles.TUTOR && <>
                    <div className="middle-line-light"/>
                    <p className='pt-10 pb-5 text-xs color-secondary-2'><span style={{fontWeight: 'bold'}}>Note:</span> {messages.tutorNote}</p>
                </>}
                <Button
                    type="submit"
                    className="w-100"
                    disabled={!(isValid && dirty)}
                >
                    Join veepow
                </Button>
                <p className='pt-10 text-xs color-secondary-2'>By joining, you agree to veepow’s <Link to={'/privacy'} className='underline'>Privacy Policy</Link>, as well as to receive occasional emails from us.</p>
            </form>
        </div>
    );
};

export default SignUpLastStep;

