import Base from './base';

class NotificationSettings extends Base {

    turnOn(data) {
        return this.post('notification/turn-on', data)
    }

    turnOff(data) {
        return this.post('notification/turn-off', data)
    }

    getBells(data) {
        return this.get('notification/bells', data)
    }

    updateBells(data) {
        return this.post('notification/update-bells', data)
    }
}

export default new NotificationSettings();
