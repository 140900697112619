import React from 'react';


const Button = ({
    className='',
    styleType='primary',
    size='',
    disabled= false,
    onClick,
    children,
    type='button'
}) => {
    return (
        <button
            onClick={onClick}
            className={`btn ${styleType} ${size} ${className}`}
            type={type}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;

