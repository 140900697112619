import React, {useState} from 'react';
import {useSelector} from "react-redux";
import HeaderGuest from 'layout/header/guest/Header';
import HeaderSignedIn from 'layout/header/signedIn/Header';
import Footer from "../../../layout/footer/Footer";
import Banner from "assets/img/about/banner.png"
import Girl from "assets/img/about/girl.png"
import Boy from "assets/img/about/boy.png"
import Children from "assets/img/about/children.png"
import MotherAndSon from "assets/img/about/mother-and-son.png"
import {Modal} from "react-responsive-modal";
import FounderModal from "./FounderModal";
import {Founder} from "elements/modals/config";


const AboutUs = () => {
    const {loggedIn} = useSelector(state => state.auth);
    const [open, setOpen] = useState(false);
    const [founder, setFounder] = useState(null);
    const onOpenModal = (item) => {
        setFounder(item);
        setOpen(true)
    };
    const onCloseModal = () => setOpen(false);

    let FounderModalData = {};
    if(founder === 1) {
        FounderModalData = Founder.founder1
    } else if (founder === 2) {
        FounderModalData = Founder.founder2
    }

    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }
            <main className='main-wrapper with-padding about-page'>
                <section className="about-banner">
                    <div className="container">
                        <img src={Banner} alt="about us"/>
                    </div>
                </section>

                <section className='text-center'>
                    <div className="section-content">
                        <h1 className='section-title'>About us</h1>
                        <p className='pb-7 text-lg'>As full time employees working from home, the last 18 months has been challenging.
                            Navigating the seemingly never-ending back-to-back online meetings whilst being cooks, cleaners,
                            and teachers to our children as their schools closed during lockdown had us wishing for on demand tuition.
                            Support that could provide an hour or two of coaching instead of our children waiting for a gap between meetings!</p>
                        <p className='text-lg'>Eventually the idea of Veepow was born. Our vision is to bridge the gap between students ad hoc and/or last minute homework needs, with a community of coaches available on demand.
                            No pre-booking hassles, no long term commitments, just immediate online requests to coaches incentivised to help a learner in need.</p>
                    </div>
                </section>
                <section className='founders-section'>
                    <div className="container">
                        <h3 className='text-center'>Founders</h3>
                        <div className='founders-inner'>
                            <div
                                className="founder founder-left"
                                onClick={() => onOpenModal(1)}
                            >
                                <div className='founder-img'>
                                    <img src={Girl} alt="Carmen Leo"/>
                                </div>
                                <div className='founder-name'>
                                    <h4 className='pb-2'>Carmen Leo</h4>
                                    <p className='text-normal'>Co-founder and CEO</p>
                                </div>
                            </div>
                            <div
                                className="founder founder-right"
                                onClick={() => onOpenModal(2)}
                            >
                                <div className='founder-img'>
                                    <img src={Boy} alt="Nic Leo"/>
                                </div>
                                <div className='founder-name'>
                                    <h4 className='pb-2'>Nic Leo</h4>
                                    <p className='text-normal'>Co-founder</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='section-content lg'>
                        <h3 className='text-center'>Our Core Values</h3>
                        <div className='image-with-text'>
                            <img src={Children} alt="Be human"/>
                            <div className='text'>
                                <h4>Be human</h4>
                                <p>In a time of increasing automation, we see community knowledge sharing as a critical component for successful learners at scale. This helps us to build a credible and tight ecosystem that is both aspirational and practical. </p>
                            </div>
                        </div>
                        <div className='image-with-text mobile-reverse'>
                            <div className='text'>
                                <h4>Be committed</h4>
                                <p>Be committed to the community. Be committed to each other. Be committed to the work. Be committed to getting better. Be committed.</p>
                            </div>
                            <img src={MotherAndSon} alt="Be committed"/>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />

            <Modal
                open={open}
                onClose={onCloseModal}
                center
                classNames={{
                    modal: 'founder-modal',
                }}
            >
                <FounderModal data={FounderModalData} />
            </Modal>
        </>
    );
};

export default AboutUs;
