import * as _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select, {components} from 'react-select';
import {setLanguage} from "vendors/redux/slices/userLanguages";
import {constructSelectOption} from '../../vendors/utils'
import {Icomoon} from 'elements'


const defaultLanguage = [
    {
        id: 1,
        value: 'English',
        label: 'English',
        name: 'English'
    }
];

const SelectLanguage = (props) => {
    const {
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue
    } = props.formik;

    const dispatch = useDispatch();
    const {languages} = useSelector(state => state.staticData);
    const {userLanguages} = useSelector(state => state.account);
    const {formLanguages} = useSelector(state => state.userLangForm);
    const defaultValue = userLanguages?.length ? constructSelectOption(userLanguages) : defaultLanguage;

    useEffect(() => {
        changeLanguage(defaultValue);
    }, []);

    const {MultiValueRemove} = components;


    const IconOption = (props) => (
        <MultiValueRemove {...props}>
            <Icomoon name='close' size={16}/>
        </MultiValueRemove>
    );

    const changeLanguage = (langs) => {
        langs = langs || [];

        //mark removed
        const removed = _.cloneDeep(formLanguages).flatMap(l => {
            if (!_.find(langs, {id: l.id}) && _.find(userLanguages, {id: l.id})) {
                Object.assign(l, {language_removed: true});
                return l;
            }
            return []
        });

        //mark added
        const added = _.cloneDeep(langs).map(l => {
            if (!_.find(userLanguages, {id: l.id})) {
                Object.assign(l, {language_new: true})
            }
            return l
        });

        setFieldValue('language', langs);
        dispatch(setLanguage([...removed, ...added]));
    };

    return (
        <>
            <div
                className={`select-box select-wrapper pb-7 ${(errors.language && touched.language) ? 'has-error' : ''}`}>
                {props.label && <label>{props.label}</label>}
                <Select
                    name='language'
                    onChange={changeLanguage}
                    options={constructSelectOption(languages)}
                    defaultValue={defaultValue}
                    className="multi"
                    classNamePrefix='select'
                    placeholder={false}
                    value={values.language}
                    isMulti
                    isClearable={false}
                    components={{MultiValueRemove: IconOption}}
                    errorMessage={errors.language}
                    onBlur={() => setFieldTouched('language', true)}
                />
                {
                    props.withInfoText ?
                        <p className={`text-xs pt-2 color-text-primary-light ${(errors.language && touched.language) ? 'color-danger' : ''}`}>You
                            must indicate at least one language.</p> :
                        <p className='error-message-general bottom'>{errors.language}</p>
                }

            </div>

        </>
    );
};

export default SelectLanguage;
