import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Button, Icomoon} from 'elements';
import {getStripeExpressUrl, getUserLoginLink} from "vendors/redux/slices/paymentSlice";
import CreditCardForm from "./modals/CreditCardForm";
import TopUp from "../account/modals/TopUp"
import {hashToId, scrollToId} from "../../vendors/utils";


const Payment = ({hash}) => {
    const dispatch = useDispatch();

    const {paymentInfo, account: {is_tutor}} = useSelector(state => state.account);

    const [creditCardOpen, toggleCard] = useState(false);
    const onOpenCardModal = () => toggleCard(true);
    const onCloseCardModal = () => toggleCard(false);

    const [topUpOpen, toggleTopUp] = useState(false);
    const onOpenTopUpModal = () => toggleTopUp(true);
    const onCloseTopUpModal = () => toggleTopUp(false);

    useEffect(() => {
        if(hash) {
            scrollToId(hashToId(hash))
        }
    }, [hash]);


    const setUpStripe = () => {
        dispatch(getStripeExpressUrl());
    }
    const goToStripe = () => {
        dispatch(getUserLoginLink());
    }


    return (
        <div id={hashToId(hash)}  className="account-info payment wrap-mobile">
            <div className="content-top flex align-center justify-between">
                <h3>Veepow wallet</h3>
                <Icomoon
                    name='warning'
                    color='#EEC200'
                    size={21}
                />
            </div>
            <div  className="content flex">
                <ul className="content-left data-list pos-rel">
                    <li>
                        <label className="text-label">Balance</label>
                        <p className="text-value fw-600">S${paymentInfo?.custom_balance || 0}</p>
                    </li>
                    <li className="top-up">
                        <Button disabled={!paymentInfo?.payment_method_id} onClick={onOpenTopUpModal} tagetr size='w-166 medium' className="item-right-space" styleType='secondary'>Top up</Button>
                    </li>
                    <li>
                        <label className="text-label">Linked card</label>
                        <Button
                            onClick={onOpenCardModal}
                            className="with-icon"
                            styleType='tertiary'>
                            <Icomoon
                                className='mr-3'
                                name='credit-card'
                            />
                            <span className="text-underline">{paymentInfo?.payment_method_id ? 'Edit' : 'Link'} a card</span>
                        </Button>
                    </li>
                    {is_tutor && <h3>Coach Earning Balance</h3>}
                    {is_tutor && <li>
                        <p className="text-info mt-2">At Veepow we have partnered with Stripe to handle all payments.
                            Please note, to receive payment from coaching you must have a Stripe account set up.</p>
                    </li>}
                    {/*{is_tutor && <li className="middle-line-light"/>}*/}
                    {is_tutor && <li className="balance-title">
                        <label className="text-label">Coach Balance</label>
                        <p className="text-value fw-600">S${paymentInfo.pendingBalance || 0}</p>
                    </li>}
                    {is_tutor && <li>
                        <p className="text-info mt-2">The coach balance reflects money you have earned and is in process of being cleared by Stripe.
                            This process, by law, takes seven working days. Once cleared, it will automatically transfer into your Stripe balance below.</p>
                    </li>}
                    {is_tutor && paymentInfo?.connect_account_id && <li className="balance-title">
                        <label className="text-label">Stripe Balance</label>
                        <p className="text-value fw-600">S${paymentInfo.accountBalance || 0}</p>
                    </li>}
                    {is_tutor && paymentInfo?.connect_account_id && <li>
                        <p className="text-info mt-2">Money earned through coaching that has been cleared by Stripe,
                            and is available to you in your personal Stripe account.</p>
                    </li>}
                    {is_tutor && <li className="mb-0">
                        {/*<label className="text-label">Stripe Account</label>*/}
                        <Button
                            onClick={paymentInfo?.connect_account_id ? goToStripe : setUpStripe}
                            className="pl-0 with-icon"
                            styleType='tertiary'>
                            <span
                                className="text-underline">{paymentInfo?.connect_account_id ? 'Go to' : 'Set up'} my Stripe Account</span>
                        </Button>
                    </li>}
                </ul>
            </div>
            {creditCardOpen && <CreditCardForm
                open={creditCardOpen}
                onCloseModal={onCloseCardModal}>
            </CreditCardForm>}

            {topUpOpen && <TopUp
                open={topUpOpen}
                onCloseModal={onCloseTopUpModal}
                >
                </TopUp>
            }
        </div>
    );
};

export default Payment;
