import React, {useRef, useState} from 'react';
import {ButtonLink, Button} from "elements";
import Avatar from "assets/img/avatar-default.svg";
import {changeAvatar} from "vendors/redux/slices/accountSlice";
import {useDispatch, useSelector} from "react-redux";


const UploadImage = ({onCloseAvatarModal}) => {
    const dispatch = useDispatch();
    const {avatar} = useSelector(state => state.account.account);
    const uploadedImage = useRef(null);
    const imageUploader = useRef(null);
    const [error, setError] = useState('');
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);

    const errorType = {
        sizeError: 'Please upload images up to 10MB.',
        formatError: 'Please upload JPEG or PNG files.',
    };

    const validateFile = (file) => {

        if (file?.size > 10485760) {
            setError(errorType.sizeError);
            return;
        }

        if (!file?.type.match('image/jpeg|image/png')) {
            setError(errorType.formatError);
            return;
        }
        setError('');
        return true
    };

    const handleImageUpload = e => {
        const [file] = e.target.files;

        if (file && validateFile(file)) {
            const reader = new FileReader();
            const {current} = uploadedImage;
            current.file = file;
            setFileName(file.name);

            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);

            setFile(file);
        }
    };


    const onSubmit = () => {

        if (file) {
            const fd = new FormData();
            fd.append("image", file);
            dispatch(changeAvatar(fd));
            onCloseAvatarModal()
        }
    };


    return (
        <>
            <div className="modal-body">
                <h3 className="modal-title">Edit my profile picture</h3>
                <div className='image-upload-wrapper'>
                    <div className=" flex align-center">
                        <img className='avatar-image lg' ref={uploadedImage} src={uploadedImage?.current?.currentSrc || avatar || Avatar}
                             alt="avatar"/>
                        <div className="content-right">
                            <label className="image-upload">
                                Choose file
                                <input
                                    type="file"
                                    accept=".jpeg, .jpg, .png"
                                    name='image'
                                    id='image'
                                    onChange={handleImageUpload}
                                    ref={imageUploader}
                                />
                            </label>
                            {
                                !fileName &&
                                <p className="text-xs color-secondary-2 mt-4">JPG or PNG. Max size of 10MB</p>
                            }

                        </div>
                    </div>
                    {
                        fileName && <p className="file-name truncate">{fileName}</p>
                    }
                    {
                        error && <p className='error-message-general bottom'>{error}</p>
                    }

                </div>
            </div>
            <div className="modal-footer">
                <Button
                    onClick={onCloseAvatarModal}
                    className="text-underline"
                    styleType='tertiary'
                >
                    Cancel
                </Button>
                <Button
                    onClick={onSubmit}
                    className='w-126 small'
                    disabled={!!error || !file}
                >
                    Upload
                </Button>
            </div>
        </>
    )
};

export default UploadImage;
