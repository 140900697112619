import React from 'react';
import SingleReview from "./SingleReview";

const ListReview = ({reviews, user_uuid, subState, userType}) => {

    return (
        <ul className={'list-bordered'}>
            {
                reviews.map((review) => {
                    return (
                        <li key={review.review_uuid}>
                            <SingleReview
                                review={review}
                                subState={subState}
                                user_uuid={user_uuid}
                                userType={userType} />
                        </li>
                    )
                })
            }
        </ul>
    );
};


export default ListReview;
