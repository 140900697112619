import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/notificationSettings";
import {groupBy} from 'lodash';

export const bellsTypes = {
    newProblem: '0'
}

const initialState = {
    newProblems: {
        count: 0
    },
    error: null
};

const BellsSlice = createSlice({
    name: 'bells',
    initialState,
    reducers: {
        updateBells: (state, action) => {
            const {id, count} = action.payload || {};

            state.newProblems.count = count

            return state;
        },
        resetByType: (state, action) => {
            const {type} = action.payload || {};
            state[type].count = 0;
            return state;
        },
        reset: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    updateBells,
    resetByType,
    actionError,
    reset
}
    = BellsSlice.actions;


export const getBells = (user_uuid) => async dispatch => {
    try {
        const data = await Api.getBells({user_uuid});
        dispatch(updateBells(serializeBells(data.data, bellsTypes.newProblem)));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const resetBells = (type) => async dispatch => {
    try {
        await Api.updateBells({type});
        dispatch(resetByType({type: 'newProblems'}));
    } catch (e) {
        dispatch(actionError(e))
    }
};


const serializeBells = (data, type) => {
    const grouped = groupBy(data,'type')
    if (type) {
        return grouped[type][0];
    }
    return grouped;
}


export default BellsSlice.reducer
