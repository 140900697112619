import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useFormik, FormikProvider, FieldArray} from "formik";
import Select, {components} from 'react-select';
import {getSubjects, getLevels, getLanguages} from "vendors/redux/slices/staticSlice";
import {becomeCoach, changeCurrentStep} from "vendors/redux/slices/authSlice";
import {setPracticeAreas} from "vendors/redux/slices/authSlice";
import {subjectSchema as validationSchema} from 'vendors/services/yup-schemas/authSchemas'
import {
    assignDisableToLevels,
    constructSelectOption
} from "../../../vendors/utils";
import SelectLanguage from '../../selectUserLanguage/selectLanguageForm';
import {Button, Icon, Icomoon} from 'elements'
import {useHistory} from "react-router";
import errorMessages from "../../../vendors/services/yup-schemas/errorMessages";
const messages = errorMessages("info");

const {MultiValueRemove} = components;
const IconOption = (props) => (
    <MultiValueRemove {...props}>
        <Icomoon name='close' size={16}/>
    </MultiValueRemove>
);

const SignUpThird = ({registerCoach}) => {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const {user_uuid} = useSelector(state => state.auth.user);
    const {subjects: allSSubjects, levels} = useSelector(state => state.staticData);
    const [nonSelectedSubjects, setNonSelectedSubjects] = useState(null);
    const initialValues = {
        subjectList: [
            {
                value: '',
                levels: [],
                filteredLevels: [],
            }
        ],
        language: [],
    };

    useEffect(() => {
        dispatch(getSubjects());
        dispatch(getLevels());
    }, []);

    const serializeData = (value) => {
        return value.map(({id, name, levels}) => {
            return {
                subject_id: id,
                subject_name: name,
                levels: levels.map(({id, name}) => {
                    return {
                        level_id: id,
                        level_name: name
                    }
                })
            }
        })
    };

    const serializeLang = (value) => {
        return value.map(({id, name}) => {
            return {
                language_id: id,
                language_name: name
            }
        })
    };

    const onSubmit = async (values) => {
            dispatch(setPracticeAreas(serializeData(values.subjectList)));
            dispatch(changeCurrentStep(4));
    };

    const submitCoach = async (values) => {
        const params = {
            user: {
                user_uuid,
                practiceAreas: serializeData(values.subjectList),
                languages: serializeLang(values.language)
            }

        }
        await dispatch(becomeCoach(params));

        push({
            pathname: '/account-profile',
            hash: "#area-of-expertise",
            state: {
                tab: 2
            }
        })
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: registerCoach ? submitCoach : onSubmit
    });

    const {
        values,
        errors,
        touched,
        handleSubmit,
        handleFocus,
        isValid,
        dirty,
        setFieldTouched,
        setFieldValue
    } = formik;

    const changeSubject = (field, subject) => {
        const modifiedLevels = assignDisableToLevels(subject, levels);

        //levels that available to selected subject: `subjectList.0.filteredLevels`
        const filteredLevels = field + '.filteredLevels';

        //selected levels: `subjectList.0.levels`
        const subjectListLevels = field + '.levels';

        setFieldValue(field, subject);

        //reset levels
        setFieldValue(subjectListLevels, []);

        //set disabled  levels
        setFieldValue(filteredLevels, modifiedLevels);

        const filteredSubjects = allSSubjects.filter(s => !values.subjectList.find(l => l.id === s.id || s.id === subject.id));
        setNonSelectedSubjects(filteredSubjects);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <FormikProvider value={formik}>
                    <FieldArray
                        name="subjectList"
                        render={({remove, push}) => (
                            <>
                                <ul className='subject-select-list'>
                                    {values.subjectList.length > 0 &&
                                    values.subjectList.map((subject, index) => (
                                        <li key={index}>
                                            <div
                                                className={`select-box select-wrapper pb-7 ${errors.subjectList?.[index]?.label && touched.subjectList?.[index]?.label ? 'has-error' : ''}`}>
                                                <label htmlFor={`subjectList.${index}.label`}>Subject</label>
                                                <Select
                                                    name={`subjectList.${index}.label`}
                                                    classNamePrefix='select'
                                                    options={constructSelectOption(nonSelectedSubjects || allSSubjects)}
                                                    value={values.subjectList?.[index] || ''}
                                                    onChange={v => changeSubject(`subjectList.${index}`, v)}
                                                    onFocus={handleFocus}
                                                    onBlur={() => setFieldTouched(`subjectList.${index}.label`, true)}
                                                />
                                                {errors.subjectList?.[index]?.label && touched.subjectList?.[index]?.label &&
                                                <p className='error-message-general bottom'>{errors.subjectList?.[index]?.label}</p>
                                                }
                                            </div>
                                            <div
                                                className={`select-box select-wrapper pb-7 ${errors.subjectList?.[index]?.levels && touched.subjectList?.[index]?.levels ? 'has-error' : ''}`}>
                                                <label>Subject level</label>
                                                <Select
                                                    name={`subjectList.${index}.levels`}
                                                    className="multi"
                                                    classNamePrefix='select'
                                                    placeholder={false}
                                                    options={constructSelectOption(values.subjectList?.[index].filteredLevels || [])}
                                                    isMulti
                                                    value={values.subjectList?.[index].levels}
                                                    isClearable={false}
                                                    components={{MultiValueRemove: IconOption}}
                                                    onChange={v => setFieldValue(`subjectList.${index}.levels`, v)}
                                                    onFocus={handleFocus}
                                                    onBlur={() => setFieldTouched(`subjectList.${index}.levels`, true)}
                                                    isDisabled={!values.subjectList?.[index].value}
                                                />
                                                {
                                                    errors.subjectList?.[index]?.levels && touched.subjectList?.[index]?.levels && (
                                                        <p className='error-message-general bottom'>{errors.subjectList?.[index]?.levels}</p>
                                                    )}
                                            </div>
                                            {
                                                index > 0 &&
                                                <button
                                                    className='no-style pointer btn-icon abs-remove'
                                                    onClick={() => remove(index)}
                                                >
                                                    <Icomoon name='close' size={18}/>
                                                </button>
                                            }
                                        </li>
                                    ))}
                                </ul>
                                {
                                    values.subjectList.length < allSSubjects.length && values.subjectList[0].value && !errors.subjectList &&
                                    <div className='text-center'>
                                        <Button
                                            styleType='secondary w-210 text-sm'
                                            disabled={errors.subjectList}
                                            onClick={() => push({label: "", levels: [], filteredLevels: []})}
                                        >
                                            Add another subject
                                        </Button>
                                    </div>}
                            </>
                        )}
                    />
                </FormikProvider>

                <h3 className='text-center form-title mt-10'>Can you teach in other languages?</h3>
                <div className='select-box select-wrapper'>
                    <SelectLanguage
                        formik={formik}
                        label={'I can teach in these languages'}
                        withInfoText
                    />
                </div>

                {registerCoach && <>
                    <div className="middle-line-light"/>
                    <p className='pt-10 pb-5 text-xs color-secondary-2'><span style={{fontWeight: 'bold'}}>Note:</span> {messages.tutorNote}</p>
                </>}

                <Button
                    type="submit"
                    className='w-100 mt-3'
                    disabled={!(isValid && dirty)}
                >
                    {registerCoach ? 'Become a Coach' : 'Next'}
                </Button>
            </form>
        </div>
    );
};

export default SignUpThird;
