import React from 'react';
import { ButtonLink } from "elements";

const Expired = () => {
    return (
        <div className='text-center'>
            <p className='pb-10'>This link has expired. Please fill in your email again.</p>
            <ButtonLink to={'/forgot-password'}>Try again</ButtonLink>
        </div>
    );
};

export default Expired;
