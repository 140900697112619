import errorMessages from "./errorMessages";
const {REACT_APP_ENV} = process.env;

export function _onlyLetters (that, value){
    const { required, onlyLetters } = errorMessages ('validation');

    if (!value){
        return that.createError({
            message: required,
            path: that.path,
        });
    } else if (!value.match(/^[a-zA-Z]+$/)){
        return that.createError({
            message: onlyLetters,
            path: that.path,
        });
    }else {
        return true;
    }
}

export const passRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const dateOfBirthRegexPattern = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;

export const dateOfBirthRegex = /^[0-9\/]*$/;

export const phoneRegEx = (REACT_APP_ENV !== "production" && REACT_APP_ENV !== "staging") ? /^\+374[1-9]\d{7}$/g : /^\+65[1-9]\d{7}$/g;

export const emailRegEx = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;

export const phonePrefix = (REACT_APP_ENV !== "production" && REACT_APP_ENV !== "staging") ? "+374" : "+65";

export const onlyDigitsRegEx = (REACT_APP_ENV !== "production" && REACT_APP_ENV !== "staging") ? /^\+374[0-9]*$/ : /^\+65[0-9]*$/;
