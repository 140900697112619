import React, {useEffect} from 'react';
import HeaderSignedIn from "../../../layout/header/signedIn/Header";
import HeaderGuest from "../../../layout/header/guest/Header";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLink} from "elements";
import SubjectCards from "../home/subjectCards/SubjectCards";
import {getLevels, getSubjects} from "../../../vendors/redux/slices/staticSlice";
import PricingImg from "assets/img/pricing.png";
import Step1 from 'assets/img/homepage/how-it-works/step1-resp.png'
import Step2 from 'assets/img/homepage/how-it-works/step2-resp.png'
import Step3 from 'assets/img/homepage/how-it-works/step3-resp.png'
import Footer from "../../../layout/footer/Footer";

const HowItWorks = () => {
    const dispatch = useDispatch();

    const {loggedIn} = useSelector(state => state.auth);
    const {roles} = useSelector(state => state.staticData);
    const {role} = useSelector(state => state.auth.user);

    useEffect(() => {
        dispatch(getSubjects());
        dispatch(getLevels());
    }, []);
    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }
            <main className='main-wrapper with-padding'>
                <section>
                    <div className='section-content lg'>
                        <h1 className='section-title pb-5 text-center'>1:1 student coaching</h1>
                        <h2 className='text-center pb-10'>How it works</h2>
                        <div className='image-with-text pb-7'>
                            <img src={Step1} alt="Post your problem"/>
                            <div className='text'>
                                <h3 className='secondary-title'>Step 1.</h3>
                                <h4>Post your problem to our community</h4>
                                <p>It used to be that a problem shared is a problem halved. At Veepow we’re aiming for a problem posted is a problem solved. Don’t get stuck on school work, let our community of experts show you the solution.</p>
                            </div>
                        </div>
                        <div className='image-with-text pb-7 mobile-reverse'>
                            <div className='text'>
                                <h3 className='secondary-title'>Step 2.</h3>
                                <h4>We match you to a coach</h4>
                                <p>Once you’ve posted your problem, our system will contact the best coach for the job. Within 10 mins you will be matched, and away you go!</p>
                            </div>
                            <img src={Step2} alt="We match you to a coach"/>
                        </div>
                        <div className='image-with-text pb-7'>
                            <img src={Step3} alt="Solve the problem together"/>
                            <div className='text'>
                                <h3 className='secondary-title'>Step 3.</h3>
                                <h4>Solve the problem together using our collaborative workspace</h4>
                                <p>Veepow connects you, the student, to the coach via a video call in the browser. This is just like a video call students have with their teachers, except on Veepow it’s an efficient one-to-one!</p>
                            </div>
                        </div>
                        {
                            !loggedIn &&
                            <div className='text-center py-10'>
                                <ButtonLink className='small' to={'sign-up'}>Sign up as a student</ButtonLink>
                            </div>
                        }

                    </div>
                </section>

                {/*subject-section*/}
                <section className='subject-section bg-color-secondary mt-10'>
                    <div className="container">
                        <div className='text-center'>
                            <h2>Subjects we support</h2>
                        </div>
                        <SubjectCards bgWhite={true} />
                        {
                            !loggedIn &&
                            <div className='text-center pt-10'>
                                <ButtonLink className='small' to={'sign-up'}>Sign up as a student</ButtonLink>
                            </div>
                        }
                    </div>
                </section>
                <section className='pt-10 pb-6'>
                    <div className='section-content lg'>
                        <div className='image-with-text'>
                            <img src={PricingImg} alt="Veepow coach"/>
                            <div className='text'>
                                <h4>Could you be a Veepow coach?</h4>
                                <h2 className='font-secondary fw-500 pb-4'>Earn extra income by coaching others</h2>
                                {
                                    role !== roles?.TUTOR &&
                                    <ButtonLink
                                        to={'/become-a-coach'}
                                        styleType='secondary medium-text'
                                    >
                                        Find out more
                                    </ButtonLink>
                                }

                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default HowItWorks;
