import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-responsive-modal";
import {useFormik} from "formik";
import {topUp, setTopPending, resetTopError} from "vendors/redux/slices/paymentSlice";
import {getAccount} from "vendors/redux/slices/accountSlice";
import {topUpSchema} from "vendors/services/yup-schemas/topUpSchemas";
import {Button, InputMasked} from "elements";
import Amount from '../elements/Amount'
import errorMessages from "../../../vendors/services/yup-schemas/errorMessages";


const { wentWrong, minTopUp } = errorMessages ('info');


const TopUp = ({open, onCloseModal}) => {
    const dispatch = useDispatch();

    const {paymentInfo = {}} = useSelector(state => state.account);
    const {topUp: {pending, error}} = useSelector(state => state.payment);

    const initialValues = { };

    const closeOnSuccess = () => {
        if(pending === false && !error) {
            dispatch(getAccount());
            onCloseModal()
        }

        if (pending === false){
            setSubmitting(false);
        }
    }

    const resetState = () => {
        resetForm({});
        dispatch(setTopPending(null));

        if (error) {
            dispatch(resetTopError())
        }
    }

    const init = () => {
        return resetState
    }

    useEffect(init,[]);

    useEffect(closeOnSuccess,[pending]);

    const onSubmit = (values) => {
        values.amount = values.amount.replace('S$', '')
        dispatch(topUp(values));
    };

    const _handleChange = (e) => {
        handleChange(e);

        if (error) {
            dispatch(resetTopError())
        }
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        handleFocus,
        resetForm,
        isValid,
        dirty,
        isSubmitting,
        setSubmitting
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: topUpSchema
    });


    return (
        <>
            {open && <Modal open={open}
                            onClose={onCloseModal}
                            center>
                <div className="modal-body">
                    <h3 className="modal-title">Top Up</h3>
                    <form onSubmit={handleSubmit}>
                            <Amount
                                errors={errors}
                                values={values}
                                touched={touched}
                                handleBlur={handleBlur}
                                _handleChange={_handleChange}
                                handleFocus={handleFocus}
                            />
                        <div className="modal-footer">
                            <Button
                                className="text-underline"
                                styleType='tertiary'
                                onClick={onCloseModal}>Cancel</Button>
                            <Button className='w-126 small'
                                    type='submit'
                                    disabled={error || isSubmitting || !(isValid && dirty)}>Confirm</Button>
                            {
                                error && <p className='error-message-general bottom text-right w-100 pr-10'>{error?.ui_message || error?.exception?.message || wentWrong}</p>
                            }
                        </div>
                    </form>
                </div>
            </Modal>}
        </>
    )
};

export default TopUp;
