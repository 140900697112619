import React, { useEffect } from 'react';
import { useFormik } from "formik";
import {Input, Button} from "elements";
import { editPasswordSchema as validationSchema } from 'vendors/services/yup-schemas/accountSchemas'
import {changePassword, resetChangePassword} from "vendors/redux/slices/accountSlice";
import {useDispatch, useSelector} from "react-redux";

const EditPassword = ({onCloseModal}) => {
    const dispatch = useDispatch();

    const {changePassword: changePasswordStore} = useSelector(state => state.account);

    const initialValues = {
        oldPassword : '',
        newPassword  : '',
        confirmPassword: ''
    };

    useEffect(() => {
        if (changePasswordStore.success === true) {
            dispatch(resetChangePassword());
            onCloseModal()
        }
        if (changePasswordStore.success === false) {
            setFieldTouched('oldPassword')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePasswordStore.success]);

    const onOldPasswordChange = (e) => {
        if (changePasswordStore.success === false) {
            dispatch(resetChangePassword());
        }
        handleChange(e)
    };

    const onSubmit = () => {
        dispatch(changePassword({
            oldPassword : values.oldPassword,
            newPassword  : values.newPassword,
        }))
    };

    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, handleFocus, setFieldTouched, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: () => validationSchema(changePasswordStore)
    });

    return (
        <>
            <div className="modal-body">
                <h3 className="modal-title">Password</h3>
                <form onSubmit={handleSubmit}>
                    <ul className="li-space">
                        <li className='label-inline'>
                            <label>Current password*</label>
                            <Input
                                withIcon
                                name='oldPassword'
                                className='mb-0'
                                type='password'
                                value={values.oldPassword}
                                touched={touched.oldPassword}
                                errorMessage={errors.oldPassword}
                                handleBlur={handleBlur}
                                handleChange={onOldPasswordChange}
                                handleFocus={handleFocus}
                            />
                        </li>
                        <li className='label-inline'>
                            <label>New Password*</label>
                            <Input
                                withIcon
                                name='newPassword'
                                existedErrorMessage
                                className='mb-0'
                                type='password'
                                value={values.newPassword}
                                touched={touched.newPassword}
                                errorMessage={errors.newPassword}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                handleFocus={handleFocus}
                            />
                        </li>
                        <li className='label-inline'>
                            <label>Retype password*</label>
                            <Input
                                withIcon
                                name='confirmPassword'
                                className='mb-0'
                                type='password'
                                value={values.confirmPassword}
                                touched={touched.confirmPassword}
                                errorMessage={errors.confirmPassword}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                handleFocus={handleFocus}
                            />
                        </li>
                    </ul>
                    <div className="modal-footer">
                        <Button
                            onClick={onCloseModal}
                            className='text-underline'
                            styleType='tertiary'
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            className='w-126 small'
                            disabled={!(isValid && dirty)}

                        >
                            Save
                        </Button>
                    </div>
                </form>
            </div>

        </>
    )
};

export default EditPassword;
