import React from 'react';
import { ResetPasswordForm } from "component/auth";
import AuthEnhancer from '../AuthEnhancer'

const ForgotPassword = () => {
    return (
        <AuthEnhancer>
            <h3 className="text-center form-title">Reset your password</h3>
            <ResetPasswordForm />
        </AuthEnhancer>
    );
};

export default ForgotPassword;
