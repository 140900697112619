import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageEnhancer from "../postProblem/PageEnhancer";
import {getRevieweeProfile, getProblem, reset} from "../../vendors/redux/slices/addReviewSlice";
import {useParams} from "react-router";
import ReviewFirstStep from "./ReviewFirstStep";
import ReviewSecondStep from "./ReviewSecondStep";
import ReviewThirdStep from "./ReviewThirdStep";

const Review = () => {
    const dispatch = useDispatch()
    const {problem_uuid, user_uuid} = useParams();
    const {currentStep} = useSelector(state => state.addReview);

    useEffect(() => {
        dispatch(getRevieweeProfile(user_uuid))
        dispatch(getProblem({problem_uuid}))

        return () => {
            dispatch(reset())
        }
    }, []);


    const stepViews = [
        <ReviewFirstStep
            user_uuid={user_uuid}
        />,
        <ReviewSecondStep />,
        <ReviewThirdStep />
    ]


    return (
        <PageEnhancer
            className='enhancer-large'
            withContactSupport={currentStep !== 3}
        >
            {stepViews[currentStep - 1]}

        </PageEnhancer>
    );
};

export default Review;
