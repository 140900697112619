import React from 'react';
import Collapsible from 'react-collapsible';
import {Button, ButtonLink} from "elements";
import {Link} from "react-router-dom";

const TUTOR_ROLE = 2;

const texts = {
    howItWorks: [
        {
            question: 'How do I sign up as a Coach?',
            answer: <> You can sign up as a Coach {" "}
                <Link className="link-underline" to={{pathname:'/sign-up',state: { role: TUTOR_ROLE}}}>here</Link>.
                Kindly note that all accounts come with the student features enabled for free.
               </>
        },
        {
            question: 'What are the minimum requirements to become a Coach?',
            answer: 'You must be 13 or above, and a resident in Singapore.'
        },
        {
            question: 'How do I get job notifications?',
            answer: `Upon successful registration, any suitable jobs will be sent to your phone, via SMS, the email you register with us, as well as a notification on the website.
                 You will have 10 minutes to accept the job should you be available.`
        },
        {
            question: 'What if a dispute happens?',
            answer: <>Any dispute will be managed via a support ticket with our team {" "} <a className="link-underline" href="/contact-us">here</a>.</>
        },
        {
            question: 'When will I receive the payment from my coaching?',
            answer:
                <>
                    Once the session between you and your learner ends, the session fee will be automatically scheduled for payout according to Stripe’s payment policy -
                    which in Singapore requires a clearing period of seven working days. Once this has completed, the money will be transferred to your personal
                    Stripe account. Read more about our payment provider <a className="link-underline" href="/trust-and-safety">here</a>.
               </>
        },
        {
            question: 'What is the star rating and why do you have it?',
            answer: `The star rating helps us and the community keep track of errant coaches. A consistently bad rating will affect your ability to get future jobs.`,
            subs: ['As a coach, we also encourage you to rate the students behaviour.']
        },
        {
            question: 'What if I want to dispute a rating?',
            answer: <>Drop us a note via the {" "} <a className="link-underline" href="/contact-us">Contact us</a> page and we will be in touch within 48 hrs.</>
        },
        {
            question: 'How much do I earn per session?',
            answer: 'To provide the service we deduct a fee of 25% per session. For a coach to sign up and use Veepow, it is completely FREE.'
        },
    ],
    pricing: [
        {
            question: 'How much am I charged per session?',
            answer: 'Each coaching session is fixed at S$15 for a 30 minute video call.',
            subs: ['The call can be extended if both you and the coach agree. An extension is another 30 minute video call for the same price.']
        },
        {
            question: 'When am I charged?',
            answer: 'Your account will be charged once the coaching session has ended.',
        },
        {
            question: 'What types of payment do you accept?',
            answer: 'We use Stripe to handle all payments securely. Stripe accepts all major debit and credit cards.',
        },
        {
            question: 'What if I am not satisfied?',
            answer: <>
                If you are not satisfied with the quality of the coaching session you are encouraged to contact us directly via our
                {" "} <a className="link-underline" href="/contact-us">contact form</a>.
                Remember to select the right topic, and provide as many details as you can.
                You will be assigned a case number and our support team will reach out to you within 48 hrs.
            </>
        },
    ]
};

// <Collapsible trigger="Start here">

const FAQ = ({withMore, type = 'howItWorks' }) => {
    return (
        <section className='FAQ-section'>
            <div className='section-content'>
                <h3 className='text-center'>FAQs</h3>
                {
                    texts[type].map((q, index) => {
                        return (
                            <Collapsible
                                key={`index-${index}`}
                                trigger={q.question}
                                transitionTime={300}
                            >
                                <p>{q.answer}</p>
                                {q.subs && q.subs.map((n, i) => {
                                    return (
                                        <p className="mt-2" key={'sub'+i}> {n}</p>
                                    )
                                })}
                            </Collapsible>
                        )
                    })
                }

                {
                    withMore &&
                        <div className='text-center'>
                            <p className='text-center pt-10 pb-7'>Can’t find what you are looking for?</p>
                            <ButtonLink styleType='secondary' to={'/how-it-works'}>More FAQs</ButtonLink>
                        </div>
                }
            </div>
        </section>
    )
};

export default FAQ;
