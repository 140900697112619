import React from 'react';
import {Link} from "react-router-dom";
import Logo from 'assets/img/logo.svg'
import FooterEnhancer from "../../layout/footer/FooterEnhancer";

const PageEnhancer = ({
                          noFooter,
                          children,
                          className = '',
                          withContactSupport
                      }) => {
    return (
        <div className={`enhancer-page ${className}`}>

            <div className="container">
                <div className="logo text-center">
                    <Link to={'/'}><img src={Logo} alt=""/></Link>
                </div>
                <div className={`enhancer-inner ${withContactSupport ? 'margin-sm' : ''}`}>{children}</div>
                {
                    withContactSupport &&
                    <div className='text-center pb-10'>
                        <Link to={'/contact-us'} className='underline'>Contact support</Link>
                    </div>
                }
                {
                    !noFooter &&
                    <FooterEnhancer />
                }
            </div>
        </div>
    );
};

export default PageEnhancer;
