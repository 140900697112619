import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import HeaderSignedIn from "../../../layout/header/signedIn/Header";
import HeaderGuest from "../../../layout/header/guest/Header";
import Footer from "../../../layout/footer/Footer";
import Priority from "assets/img/trustAndSafety/priority.png"
import Review from "assets/img/trustAndSafety/review.png"
import Privacy from "assets/img/trustAndSafety/privacy.png"
import YourMind from "assets/img/trustAndSafety/your-mind.png"
import PoweredByStripe from "assets/img/trustAndSafety/powered_by_stripe_blurple.svg"


const TrustAndSafety = () => {
    const dispatch = useDispatch();

    const {loggedIn} = useSelector(state => state.auth);
    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }
            <main className='main-wrapper with-padding'>
                <section className='text-center '>
                    <h1 className='section-title pb-7'>Trust & Safety</h1>
                    <h2>Our #1 priority</h2>
                    <img src={Priority} alt={'Trust & Safety'} className='max-width-480 w-100' />
                </section>
                <section className='bg-color-secondary pt-10'>
                    <div className="container">
                        <div className='flex-child-half mobile-reverse'>
                            <div>
                                <img className='block w-100' src={Review} alt="review"/>
                            </div>
                            <div className='pl-10 mb-10'>
                                <h3 className='pb-10'>Ratings & Reviews</h3>
                                <p className='pb-3'>To maintain the integrity of our community, we ask both students and coaches to submit their reviews after each engagement is completed.
                                    These reviews are independent of any external pressures, providing honest feedback from which the community can learn and grow.
                                    Remember, reviews are subjective opinions and unfortunately, you can’t please everyone all of the time.
                                </p>
                                <p>Each engagement can be rated from 1 - 5 stars, and this is calculated into community members public rating result.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='py-10'>
                    <div className='section-content lg'>
                        <div className='image-with-text pb-7'>
                            <div className='image'>
                                <img src={Privacy} alt="Post your problem"/>
                            </div>

                            <div className='text'>
                                <h4>Your privacy, our concern</h4>
                                <p className='pb-3'>
                                    We only collect information that is necessary to operate the platform and provide the service to you.
                                    Your data is always secure and we will never share your personal information with third parties.
                                </p>
                                <p>Payment security is extremely important. We integrate with industry leading payment gateway,{" "}
                                    <a target="_blank" style={{textDecoration: 'underline'}} href="https://stripe.com/en-sg/">Stripe.</a>
                               </p>
                            </div>
                        </div>
                        <div className='image-with-text pb-7 mobile-reverse'>
                            <div className='text'>
                                <h4>Safe payments</h4>
                                <p>At Veepow we want the best tools available for our community of learners and coaches. This is why we use Stripe to fulfil our payment needs.
                                    Stripe has been audited by a PCI-certified auditor and endorsed at the most stringent level of certification available in the payments industry.
                                    Read more <a target="_blank"  style={{textDecoration: 'underline'}} href="https://stripe.com/en-sg/about">here.</a>
                                   </p>
                            </div>
                            <div>
                                <img src={PoweredByStripe} alt="Powered By Stripe"/>
                            </div>
                        </div>
                        <div className='image-with-text pb-7'>
                            <div className='image'>
                                <img src={YourMind} alt="Solve the problem together"/>
                            </div>

                            <div className='text'>
                                <h4>Something on your mind?</h4>
                                <p>We’re here to help so reach out through our <a  style={{textDecoration: 'underline'}} href="/contact-us">contact form</a> anytime, even if it’s just to say hi!</p>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default TrustAndSafety;
