import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/problem";
import {setActiveProblem} from "./activeProblemSlice";

const initialState = {
    data: {
        files: []
    },
    voucher: {
        ui_message: '',
        exists: null
    },
    filteredLevels: [],
    currentStep: 1,
    level: null,

    error: null,
    success: null,
    pending: null
};

const postProblemSlice = createSlice({
    name: 'postProblemSlice',
    initialState,
    reducers: {
        setProblemData: (state, action) => {
            Object.assign(state.data, {...action.payload});
            return state;
        },
        setFilteredLevels:(state, action) => {
            state.filteredLevels = action.payload;
            return state;
        },
        resetFilteredLevels:(state, action) => {
            state.filteredLevels = initialState.filteredLevels;
            return state;
        },
        resetProblemData: (state) => {
            state.data = initialState.data;
            return state;
        },
        setVoucher: (state, action) => {
            state.voucher.ui_message = action.payload.ui_message || '';
            state.voucher.exists = !action.payload.ui_message;
            return state;
        },
        resetVoucher: (state) => {
            state.voucher = initialState.voucher;
            return state;
        },
        setUploadedFiles: (state, action) => {
            state.data.files = [...state.data.files, ...action.payload];
            return state;
        },
        removeFile: (state, action) => {
            state.data.files = state.data.files.filter((val, i) => i !== action.payload )
            return state;
        },
        changeCurrentStep: (state, action) => {
            state.currentStep = action.payload;
            return state;
        },
        setLevel: (state, action) => {
            state.level = action.payload;
            return state;
        },
        resetLevel: (state) => {
            state.level = null;
            return state;
        },
        resetProblem: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        updateParam: (state, action) => {
            const {prop, value} = action.payload;
            state[prop] = value;
            return state;
        },
    }
});

export const {
    setProblemData,
    setFilteredLevels,
    resetFilteredLevels,
    resetProblemData,
    setModifiedData,
    resetModifiedData,
    setVoucher,
    resetVoucher,
    setUploadedFiles,
    removeFile,
    changeCurrentStep,
    setLevel,
    resetLevel,
    resetProblem,
    updateParam,
    actionError
}
    = postProblemSlice.actions;


export const postProblem = (data) => async dispatch => {
    try {
        dispatch(updateParam({prop: 'pending', value: true}));
        const res = await Api.createProblem(data);
        dispatch(setActiveProblem(res.data.problem));
        dispatch(updateParam({prop: 'pending', value: false}));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const uploadFile = (data) => async dispatch => {
    try {
        const payload = await Api.addFile(data);
        dispatch(setUploadedFiles(payload.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};
export const deleteFile = (data, index) => async dispatch => {
    try {
        await Api.deleteFile(data);
        dispatch(removeFile(index));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const applyVoucher = (data) => async dispatch => {
    try {
        const payload = await Api.checkVoucher(data);
        dispatch(setVoucher(payload.data));
    } catch (e) {
        if (e?.data) {
            return dispatch(setVoucher(e.data))
        }
        dispatch(actionError(e))
    }
};

export default postProblemSlice.reducer;
