import React from 'react';
import {useSelector} from "react-redux";
import HeaderGuest from 'layout/header/guest/Header';
import HeaderSignedIn from 'layout/header/signedIn/Header';
import Footer from "../../../layout/footer/Footer";
import FAQ from "../../../component/FAQ/FAQ";
import PricingImg from "assets/img/pricing.png";
import {Button, ButtonLink} from "elements"

const Pricing = () => {
    const {loggedIn} = useSelector(state => state.auth);

    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }
            <main className='main-wrapper with-padding pricing-page'>
                <div className="container">
                    <div className='pricing-content'>
                        <img src={PricingImg} alt=""/>
                        <h1 className='section-title pb-5'>Pricing</h1>
                        <p>Get learning support at an affordable price.</p>
                        <div className="pricing-box">
                            <h2>S$15 <span>/30 min session</span></h2>
                            <ul>
                                <li>No subscriptions, no monthly payments</li>
                                <li>Study sessions are private and one-to-one</li>
                                <li>Connect instantly from any device</li>
                                <li>A safe place to learn online</li>
                                <li>Coaching is available daily, between 9am-5pm</li>
                            </ul>
                            {
                                !loggedIn &&
                                <ButtonLink to={'sign-up'}>Get started</ButtonLink>
                            }
                        </div>
                    </div>
                    <FAQ type="pricing"/>
                </div>
            </main>
            <Footer/>
        </>
    );
};

export default Pricing;
