import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {getTutors, resetTutors} from '../../vendors/redux/slices/searchTutorSlice';
import HeaderGuest from 'layout/header/guest/Header';
import HeaderSignedIn from 'layout/header/signedIn/Header';
import Footer from "layout/footer/Footer";
import { ButtonLink, Icomoon } from "elements";
import FilterBar from "./FilterBar";
import SingleTutor from "./SingleTutor";
import {getLevels, getSubjects} from "../../vendors/redux/slices/staticSlice";
import {resetSelectSubjectForm} from "../../vendors/redux/slices/selectSubjectSlice";


const SearchTutor = (props)=> {
    const dispatch = useDispatch();

    const {tutors} = useSelector(state => state.searchTutor);
    const {loggedIn, user: {user_uuid}} = useSelector(state => state.auth);

    const [openFilter, setOpenFilter] = useState(false);

    useEffect(() => {
        if (openFilter) {
            document.body.classList.add('overflow-hidden-mobile');
        }
        return () => {
            document.body.classList.remove('overflow-hidden-mobile')
        }
    },[openFilter]);

    useEffect(() => {
        const {subject_id, level_id} = props.location.state || {};
        if(level_id) {
            dispatch(getTutors({
                user_uuid,
                subject_id,
                level_id: level_id})
            );
        }
        dispatch(getSubjects());
        dispatch(getLevels());

        return () => {
            dispatch(resetTutors())
            dispatch(resetSelectSubjectForm())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onToggleFilter = () => {
        setOpenFilter(!openFilter)
    };


    return (
        <div>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }

            <main className='bg-color-secondary main-wrapper'>

                <div className="container">
                    <div className="search-tutor">
                        <FilterBar openFilter={openFilter} />
                        <section className='main-content'>
                            <ul className="result-list">
                                {!tutors.length && <li className='result-list-item'>No results to show</li>}
                                {
                                    tutors.map(({avatar, family_name, first_name, about, averageRating, practiceAreas, user_uuid}) => {
                                        return(
                                            <li key={user_uuid} className='result-list-item'>
                                                <SingleTutor
                                                    avatar={avatar}
                                                    family_name={family_name}
                                                    first_name={first_name}
                                                    about={about}
                                                    averageRating={averageRating}
                                                    practiceAreas={practiceAreas}
                                                    user_uuid={user_uuid}
                                                />
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            {loggedIn}
                            {
                                !loggedIn &&
                                <div className="fixed-wrapper">
                                    <div className="fixed-content">
                                        <ButtonLink to={'/sign-up'} className='fixed-button'>
                                            <span className='hide-mobile'>Sign up to post a problem to our community of coaches</span>
                                            <span className='show-mobile'>Sign up</span>
                                        </ButtonLink>
                                    </div>
                                </div>
                            }

                            <button className='show-mobile filter-button'  onClick={onToggleFilter}>
                                <Icomoon name='filter' color='#fff' size={20} />
                            </button>
                        </section>
                    </div>
                </div>

            </main>
            <Footer zIndex/>
        </div>
    );
};

export default SearchTutor;
