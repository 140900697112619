import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/reviews";


const sharedStructure = {
    user_uuid: '',
    tutor: {
        offset: 0,
        index: 1,
        limit: 5,
        count: 0,
        rows: [],
        averageRating: 0
    },
    student: {
        offset: 0,
        index: 1,
        limit: 5,
        count: 0,
        rows: [],
        averageRating: 0
    },
    error: null
}
const initialState = {
    guestProfile: {
        ...sharedStructure
    },
    publicProfile: {
        ...sharedStructure,
        isPublicProfile: true
    }
}

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        setTutorReviews: (state, action) => {
            const {subState} = action.payload;
            const targetState = state[subState];

            let rows = [...targetState.tutor.rows, ...action.payload.rows];
            if (action.payload.afterReplay) {
                Object.assign(action.payload, {
                    limit: targetState.tutor.limit,
                    offset: targetState.tutor.offset
                });
                rows = [...action.payload.rows];
            }
            targetState.tutor = {
                ...action.payload,
                rows,
            };
            return state;
        },
        setStudentReviews: (state, action) => {
            const {subState} = action.payload;
            const targetState = state[subState];

            let rows = [...targetState.student.rows, ...action.payload.rows];
            if (action.payload.afterReplay) {
                Object.assign(action.payload, {
                    limit: targetState.student.limit,
                    offset: targetState.student.offset
                });
                rows = [...action.payload.rows];
            }
            targetState.student = {
                ...action.payload,
                rows,
            };
            return state;
        },
        reset: () => {
            return initialState;
        },
        resetReviews: (state, action) => {
            const {subState} = action.payload;

            state[subState] = {...initialState[subState]};
            return state;
        },
        actionError: (state, action) => {
            const {subState} = action.payload;
            const targetState = state[subState];

            targetState.error = action.payload;
            return state;
        }
    }
});

export const {
    actionError,
    setTutorReviews,
    setStudentReviews,
    resetReviews,
    reset
}
    = reviewsSlice.actions;

export const getTutorReviews = ({user_uuid, offset, limit, role}, {
    index,
    afterReplay = null,
    subState = 'publicProfile'
}) => async dispatch => {
    try {
        const payload = await Api.getReviews(user_uuid, offset, limit, role);
        dispatch(setTutorReviews({...payload.data.asTutor, offset, limit, index, afterReplay, subState}));
    } catch (e) {
        dispatch(actionError({...e, subState}))
    }
};

export const getStudentReviews = ({user_uuid, offset, limit, role}, {
    index,
    afterReplay = null,
    subState = 'publicProfile'
}) => async dispatch => {
    try {
        const payload = await Api.getReviews(user_uuid, offset, limit, role);
        dispatch(setStudentReviews({...payload.data.asStudent, offset, limit, index, afterReplay, subState}));
    } catch (e) {
        dispatch(actionError({...e, subState}))
    }
};


export const addReply = (data) => async dispatch => {
    try {
        await Api.addReply(data);
    } catch (e) {
        dispatch(actionError({...e, subState: 'publicProfile'}))
    }
};

export default reviewsSlice.reducer
