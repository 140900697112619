import Step1 from 'assets/img/homepage/how-it-works/step1.png'
import Step2 from 'assets/img/homepage/how-it-works/step2.png'
import Step3 from 'assets/img/homepage/how-it-works/step3.png'
import StepResp1 from 'assets/img/homepage/how-it-works/step1-resp.png'
import StepResp2 from 'assets/img/homepage/how-it-works/step2-resp.png'
import StepResp3 from 'assets/img/homepage/how-it-works/step3-resp.png'

export const studentView = [
    {
        title: 'Step 1. Post your problem to our community.',
        tabTitle: 'Step 1: Post your problem',
        img: Step1,
        imgResp: StepResp1
    },
    {
        title: 'Step 2. We match you with a coach who has the right experience…',
        tabTitle: 'Step 2: We match you to a coach',
        img: Step2,
        imgResp: StepResp2
    },
    {
        title: 'Step 3. Connect over a video call and solve the problem together!',
        tabTitle: 'Step 3: Solve your problem together',
        img: Step3,
        imgResp: StepResp3
    }
];

export const coachView = [
    {
        title: 'Step 1: Students post their problem on Veepow.',
        tabTitle: 'Step 1: Students post a problem',
        img: Step1,
        imgResp: StepResp1
    },
    {
        title: 'Step 2. If you have the right experience, you receive a job notification…',
        tabTitle: 'Step 2: As a coach, you accept the job',
        img: Step2,
        imgResp: StepResp2
    },
    {
        title: 'Step 3. Accept the job and connect over a video call.',
        tabTitle: 'Step 3: Solve the problem over a video call',
        img: Step3,
        imgResp: StepResp3
    }
];
