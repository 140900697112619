import {getStudentReviews, getTutorReviews} from "../../vendors/redux/slices/reviewSlice";


export const roles = {
    "student": 1,
    "tutor": 2
};

export const reviewsReducers = {
    "student": getStudentReviews,
    "tutor": getTutorReviews
};
