class LocalStorage {
    static getLocalStorageAsync(key) {
        return localStorage.getItem(key);
    };
    static async setLocalStorageAsync(key, val) {
        localStorage.setItem(key, val);
        await null;
    };

    static async rmLocalStorageAsync(key) {
        localStorage.removeItem(key);
        await null;
    }
}

export default LocalStorage;
