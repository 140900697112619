import React from 'react';
import HeaderGuest from 'layout/header/guest/Header';
import HeaderSignedIn from 'layout/header/signedIn/Header';
import Footer from 'layout/footer/Footer';
import {useSelector} from "react-redux";

const Privacy = () => {
    const {loggedIn} = useSelector(state => state.auth);

    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }
            <main className="main-wrapper">
                <div className='small-page text-page'>
                    <div className="container">
                        <div className="small-page-inner">
                            <h2>Veepow Privacy Policy</h2>
                            <p className='text-md'>This Privacy Policy (<strong>“Policy”</strong>) sets out the basis which Veepow (<strong>“we”</strong>, <strong>“us”</strong>, or <strong>“our”</strong>) may collect, use, disclose or otherwise process personal data of our customers in accordance with the Personal Data Protection Act (<strong>“PDPA”</strong>). This Notice applies to personal data in our possession or under our control, including personal data in the possession of organisations which we have engaged to collect, use, disclose or process personal data for our purposes. </p>
                            <h4>Personal information we collect</h4>
                            <p>We collect Personal Data about you in the ways listed below. We may also combine the collected Personal Data with other Personal Data in our possession. If you have or are a party to multiple relationships with us (for example if you are both a Student as well as a Coach on our platform), we will link your Personal Data collected across your various capacities to facilitate your use of our Services and for the Purposes described below.</p>
                            <p>We collect your Personal Data when you voluntarily provide it to us. For example, you may provide your Personal Data to us when you:</p>
                            <ul>
                                <li>complete a user profile or registration forms (such as your name, contact information and other identification information where needed);</li>
                                <li>provide information to assess your eligibility as a Coach;</li>
                                <li>interact with our social media pages (such as your social media account ID, profile photo and any other publicly available data);</li>
                                <li>participate in contests or events organised by us (such as the pictures, audio files, or videos you may submit, which may include images of yourself);</li>
                                <li>verify your identity through SMS mobile verification or independently verified payment card information);</li>
                                <li>fill up demographic information in surveys (such as your age, gender, and other information you may volunteer such as your marital status, occupation and income information); and</li>
                                <li>agree to engage in a coaching session that has video recording features.</li>
                            </ul>
                            <p>In certain circumstances, you may need to provide your Personal Data in order to comply with legal requirements or contractual obligations, or where it is necessary to conclude a contract. Failure to provide such Personal Data, under such circumstance, may constitute failure to comply with legal requirements or contractual obligations, or inability to conclude a contract with you, as the case may be.</p>
                            <p>Personal Data may be collected through the normal operation of our Website. Some examples are:</p>
                            <ul>
                                <li>your location (to ensure you are in Singapore);</li>
                                <li>feedback, ratings and compliments;</li>
                                <li>transaction information (such as payment method);</li>
                                <li>information about how you interacted with our Website (such as features used and content viewed);</li>
                                <li>device information (such as hardware model and serial number, IP address, file names and versions and advertising identifiers or any information that may provide indication of device);</li>
                                <li>personal data you enter in messages when you use our Website contact form; and</li>
                                <li>personal data that may be captured through your interaction with us, our Coaches, video recording during a session (such as your image or voice or both, and its related metadata).</li>
                            </ul>
                            <h6>Coaching session recording</h6>
                            <p>Veepow records video of every coaching session via the in-app recording feature on Zoom Video Communications, Inc. interface. This promotes the safety and security of Students and Coaches. Your Personal Data may be captured in these audio and/or video recordings. Where audio and/or video recordings are made, such recordings are collected, processed, used and stored in a manner that is compliant with applicable laws.</p>
                            <h6>Personal Data of minors</h6>
                            <p>As a parent or legal guardian, please do not allow minors under your care to submit Personal Data to Veepow. In the event that such Personal Data of a minor is disclosed to Veepow, you hereby consent to the processing of the minor’s Personal Data and accept and agree to be bound by this Policy and take responsibility for his or her actions.</p>


                            <h4>Use of personal data</h4>
                            <p>Veepow may use, combine and process your Personal Data for the following purposes (“Purposes”):</p>
                            <h6>Providing services and features</h6>
                            <p>Your Personal Data will be used to provide, personalise, maintain and improve our Website and Services. This includes using your Personal Data to:</p>
                            <ul>
                                <li>provide you with Services;</li>
                                <li>engage you to provide Services;</li>
                                <li>create, administer and update your account;</li>
                                <li>conduct due diligence checks;</li>
                                <li>verify your identity;</li>
                                <li>verify your age (where necessary);</li>
                                <li>validate your coaching session and process payments;</li>
                                <li>track the progress of your coaching session;</li>
                                <li>make your experience more seamless, such as automatically filling in your registration information (such as your name or phone number) when you participate in our surveys;</li>
                                <li>perform internal operations necessary to provide our Services, including troubleshooting software bugs and operational problems, conducting data analysis, testing and research, monitoring and analysing usage and activity trends;</li>
                                <li>protect the security or integrity of the Services and any facilities or equipment used to make the Services available;</li>
                                <li>enable communications between our users;</li>
                                <li>enable our partners to manage and allocate coach resources.</li>
                            </ul>
                            <h6>Safety and security</h6>
                            <p>We use your data to ensure the safety and security of our Services and all users. This includes:</p>
                            <ul>
                                <li>identifying negative behaviour such as verbal and visual abuse, and providing personalised feedback to Coaches;</li>
                                <li>verifying your identity when you log in to Veepow;</li>
                                <li>using device, location, profile, usage and other Personal Data to prevent, detect and combat fraud or unsafe activities;</li>
                                <li>monitoring compliance with our terms and conditions, policies and Coaches Code of Conduct; and</li>
                                <li>detecting, preventing and prosecuting crime.</li>
                            </ul>
                            <h6>Customer support</h6>
                            <p>We use Personal Data to resolve customer support issues. For example, we may:</p>
                            <ul>
                                <li>investigate and address concerns;</li>
                                <li>monitor and improve our customer support responses;</li>
                                <li>respond to questions, comments and feedback; and</li>
                                <li>inform you about steps taken to resolve customer support issues.</li>
                            </ul>
                            <h6>Legal purposes</h6>
                            <p>We may use the Personal Data we collect to investigate and resolve claims or disputes, or as allowed or required by applicable law.</p>
                            <p>We may also use your Personal Data when we are required, advised, recommended, expected or requested to do so by our legal advisors or any local or foreign legal, regulatory, governmental or other authority.</p>
                            <p>For example, we may use your Personal Data to:</p>
                            <ul>
                                <li>comply with court orders or other legal, governmental or regulatory requirements;</li>
                                <li>enforce our Terms of Service or other agreements; and</li>
                                <li>protect our rights or property in the event of a claim or dispute.</li>
                            </ul>
                            <p>We may also use your Personal Data in connection with mergers, acquisitions, joint ventures, sale of company assets, consolidation, restructuring, financing, business asset transactions, or acquisition of all or part of our business by another company.</p>
                            <h6>Marketing and promotions</h6>
                            <p>We may use your Personal Data to market Veepow and advertisers’ products, services, events or promotions. For example, we may:</p>
                            <ul>
                                <li>send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings; and</li>
                                <li>notify, invite and manage your participation in our events or activities.</li>
                            </ul>
                            <p>We may communicate such marketing to you by post, telephone call, short message service, online messaging service, push notifications, and by email.</p>
                            <p>If you wish to unsubscribe to the processing of your Personal Data for marketing and promotions, please click on the unsubscribe link in the relevant email or message.</p>
                            <p>Alternatively, you may also update your preferences in our Website settings.</p>

                            <h4>Disclosure of personal data</h4>
                            <p>We need to share Personal Data with various parties for the Purposes. These parties include:</p>
                            <h6>Other users</h6>
                            <p>For example:</p>
                            <ul>
                                <li>If you are a Student, we may share your posted problem/learning request, your public profile, including name, photo, location and average rating, with our Coaches fulfilling your service request.</li>
                                <li>If you are a Coach, we may share your Personal Data with your Student including your public profile, including name, photo, location and average rating.</li>
                            </ul>
                            <h6>With Veepow’s service providers and business partners</h6>
                            <p>We may provide Personal Data to our vendors, consultants, marketing partners, research firms, and other service providers or business partners. This includes:</p>
                            <ul>
                                <li>payment processors and facilitators;</li>
                                <li>debt collectors;</li>
                                <li>credit bureaus and other credit reporting agencies;</li>
                                <li>background check and anti-money laundering service providers;</li>
                                <li>cloud storage providers;</li>
                                <li>marketing partners and marketing platform providers;</li>
                                <li>data analytics providers;</li>
                                <li>research partners, including those performing surveys or research projects in partnership with Veepow or on Veepow’s behalf;</li>
                                <li>insurance and financing partners;</li>
                                <li>service providers who perform identity verification services; and</li>
                                <li>third-party integration suppliers such as Zoom Video Communications, Inc., used to provide the video conferencing service.</li>
                            </ul>
                            <h6>With our legal advisors and governmental authorities</h6>
                            <p>We may share your Personal Data with our legal advisors, law enforcement officials, government authorities and other third parties. This may take place to fulfil the legal purposes (mentioned above), or any of the following circumstances:</p>
                            <ol>
                                <li>where it is necessary to respond to an emergency that threatens the life, health or safety of a person; or</li>
                                <li>where it is necessary in the public interest (e.g. in a public health crisis, for contact tracing purposes and safeguarding our community).</li>
                            </ol>

                            <h4>Retention of personal data</h4>
                            <p>We retain your Personal Data for the period necessary to fulfill the Purposes outlined in this Policy unless a longer retention period is required or allowed by law. Once your Personal Data is no longer necessary for the Services or Purposes, or we no longer have a legal or business purpose for retaining your Personal Data, we take steps to erase, destroy, anonymise or prevent access or use of such Personal Data for any purpose other than compliance with this Policy, or for purposes of safety, security, fraud prevention and detection, in accordance with the requirements of applicable laws.</p>

                            <h4>Protection of personal data</h4>
                            <p>We will take reasonable legal, organisational and technical measures to ensure that your Personal Data is protected. This includes measures to prevent Personal Data from getting lost, or used or accessed in an unauthorised way. We limit access to your Personal Data to our employees on a need to know basis. Those processing your Personal Data will only do so in an authorised manner and are required to treat your information with confidentiality.
                                Nevertheless, please understand that the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted through any online means, therefore, any transmission remains at your own risk.
                            </p>

                            <h4>Your rights with respect to your personal data</h4>
                            <p>In accordance with applicable laws and regulations, you may be entitled to:</p>
                            <ul>
                                <li>ask us about the processing of your Personal Data, including to be provided with a copy of your Personal Data;</li>
                                <li>request the correction and/or (in some cases) deletion of your Personal Data;</li>
                                <li>in some cases, request the restriction of the processing of your Personal Data, or object to that processing;</li>
                                <li>withdraw your consent to the processing of your Personal Data (where we are processing your Personal Data based on your consent);</li>
                                <li>request receipt or transmission to another organisation, in a machine-readable form, of the Personal Data that you have provided to us where we are using your Personal Data based on consent or performance of a contract; and</li>
                                <li>complain to the relevant data privacy authority if your data privacy rights are violated, or if you have suffered as a result of unlawful processing of your Personal Data.</li>
                            </ul>
                            <p>Where you are given the option to share your Personal Data with us, you can always choose not to do so. If we have requested your consent to processing and you later choose to withdraw it, we will respect that choice in accordance with our legal obligations.</p>
                            <p>However, choosing not to share your Personal Data with us or withdrawing your consent to our use of it could mean that we are unable to perform the actions necessary to achieve the purposes of processing described in Section titled “Use of Personal Data”, or that you are unable to make use of the Services. After you have chosen to withdraw your consent, we may be able to continue to process your Personal Data to the extent required or otherwise permitted by applicable laws and regulations.</p>
                            <p>If you wish to make a request to exercise your rights, you can contact us through our contact details set out below (How to Contact Us).</p>
                            <p>We will screen and verify all requests beforehand. In order to verify your authority to make the request, we may require you to provide supporting information or documentation to corroborate the request.</p>
                            <p>Once verified, we will give effect to your request within the timelines prescribed by applicable laws.</p>

                            <h4>Amendments and updates</h4>
                            <p>Veepow shall have the right to modify, update or amend the terms of this Policy at any time by placing the updated Policy on the Website. By continuing to use the Website and Services from Veepow or continuing to communicate or engage with Veepow following the modifications, updates or amendments to this Policy, you signify your acceptance of such modifications, updates or amendments.</p>

                            <h4>How to contact us</h4>
                            <p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact our Data Protection Officer at: Veepow Data Protection Officer, <a href="mailto:contact@veepow.com" className='underline'>contact@veepow.com</a>.</p>

                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
};

export default Privacy;
