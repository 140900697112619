import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import HeaderGuest from 'layout/header/guest/Header';
import HeaderSignedIn from 'layout/header/signedIn/Header';
import Footer from 'layout/footer/Footer';
import SubjectForm from "component/selectSubject/subjectForm";
import HowItWorksCarousel from "./how-it-works/HowItWorksCarousel";
import TestimonialCarousel from "./testimonials/Testimonials";
import { getSubjects, getLevels } from "vendors/redux/slices/staticSlice";
import { ButtonLink } from 'elements';
import banner1 from 'assets/img/homepage/home-banner-1.png';
import banner2 from 'assets/img/homepage/home-banner-2.png';
import banner3 from 'assets/img/homepage/home-banner-3.png';
import {hashToId, scrollToId} from "../../../vendors/utils";
import SubjectCards from "./subjectCards/SubjectCards";
import {useLocation} from "react-router";


const Home = () => {
    const dispatch = useDispatch();
    const {hash} = useLocation()
    const {roles} = useSelector(state => state.staticData);
    const {role} = useSelector(state => state.auth.user);
    const {loggedIn} = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(getSubjects());
        dispatch(getLevels());

    }, []);

    useEffect(() => {
        if(hash) {
            scrollToId(hashToId(hash))
        }

    }, [hash]);

    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }

            {/*banner*/}
            <main className='main-wrapper with-padding'>
                <section className="banner">
                    <div className="container">
                        <div className={`banner-content ${loggedIn ? 'logged-in' : ''}`}>
                            <h1>{loggedIn ? 'Don’t get frustrated, get it done.' : 'Help me. Help you.'}</h1>
                            {
                                !loggedIn &&
                                <div className="banner-content-inner">
                                    <p>Veepow, the online collaboration platform to quickly bring students with a problem together with people who can solve it.
                                        <span>Don’t get frustrated, get it done.</span></p>
                                    <ButtonLink to={'sign-up'}>Get started</ButtonLink>
                                </div>
                            }
                            <img src={banner1} className={'banner-img-1'} alt=""/>
                            <img src={loggedIn ? banner3 : banner2} className='banner-img-2' alt=""/>
                        </div>
                    </div>
                </section>

                {/*search bar*/}
                {/*{<section className='search-section'>
                    <div className="container">
                        <div className="search-content">
                            <h3 className='pr-3'>I need help with </h3>
                            <SubjectForm/>
                        </div>
                    </div>
                </section>}*/}

                {/*subject-section*/}
                <section id='subjects' className='subject-section'>
                    <div className="container">
                        <div className='text-center'>
                            <h2 className='section-title'>Give it a go!</h2>
                            <p className='text-normal'>Connect to local experts on the following subjects</p>
                        </div>
                        <SubjectCards />
                    </div>
                </section>

                {/*testimonial-section*/}
                {/*<section className="testimonial-section">
                    <div className="container">
                        <div className="testimonial-content">
                            <TestimonialCarousel />
                        </div>
                    </div>

                </section>*/}

                {/*how-works-section*/}
                <section className='how-works-section'>
                    <div className="container">
                        <div className="how-works-content">
                            <h4>How it works</h4>
                            <HowItWorksCarousel />
                        </div>
                    </div>
                </section>
                <section className="extra-income text-center">
                    <div className="container">
                        <h4>You know more than you know</h4>
                        <h2 className='font-secondary fw-500 pb-4'>Earn extra income by coaching others</h2>
                        {
                            role !== roles?.TUTOR &&
                            <ButtonLink
                                to={'/become-a-coach'}
                                styleType='secondary medium-text'
                            >
                                Find out more
                            </ButtonLink>
                        }

                    </div>
                </section>
            </main>
            {/*footer*/}

            <Footer />
        </>
    );
};



export default Home;
