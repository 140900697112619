import React from 'react';
import AuthEnhancer from '../AuthEnhancer'
import { ButtonLink } from 'elements'

const CheckRecoveryEmail = () => {
    return (
        <AuthEnhancer>
            <h3 className="text-center form-title">Password reset email sent</h3>
            <p className="text-center text-normal mb-10">Check your email for the link to update your password. This link will expire in 1 hour.</p>
            <ButtonLink to={'/sign-in'} className='w-100' >Continue to sign in</ButtonLink>
        </AuthEnhancer>
    );
};

export default CheckRecoveryEmail;
