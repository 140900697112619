import {createSlice} from '@reduxjs/toolkit';
import Api from '../../services/api/auth'
import {openGeneralErrorModal} from "./layoutSlice";
import LocalStorage from "../../services/localStorage";
import {assignLanguageData, clearIntervals} from "vendors/utils"
import {resetActiveProblem} from "./activeProblemSlice";
import {getAccount} from "./accountSlice";


const initialState = {
    user: {},
    signUp: {
        data: {
            first_name: '',
            family_name: '',
            email: '',
            phone: null,
            practiceAreas: [],
            username: '',
            password: '',
            role: 1
        },
        emailExists: {
            ui_message: '',
            exists: null
        },
        usernameExists: {
            ui_message: '',
            exists: null
        },
        phoneCodeSend: {
            ui_message: '',
            success: null
        },
        phoneCodeVerify: {
            ui_message: '',
            valid: null
        },
    },
    currentStep: 1,
    forgetPassword: {
        ui_message: '',
        success: null
    },
    resetPassword: {
        success: null
    },
    checkToken: {
        success: null
    },
    loggedIn: null,
    error: null
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            state.error = null;
            return state;
        },
        setRole: (state, action) => {
            state.signUp.data.role = action.payload;
            return state;
        },
        reSetRole: (state, action) => {
            state.signUp.data.role = 1;
            return state;
        },
        setSignUpData: (state, action) => {
            Object.assign(state.signUp.data, {...action.payload});
            return state;
        },
        resetSignUpData: (state) => {
            state.signUp.data = initialState.signUp.data;
            return state;
        },
        changeLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
            return state;
        },
        changeCurrentStep: (state, action) => {
            state.currentStep = action.payload;
            return state;
        },
        resetAuth: () => {
            return initialState;
        },
        authError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        resetAuthError: (state) => {
            state.error = null;
            return state;
        },
        setSignUpEmailApiError: (state, action) => {
            state.signUp.emailExists.ui_message = action.payload.ui_message || '';
            state.signUp.emailExists.exists = !!action.payload.ui_message;
            return state;
        },
        resetSignUpEmailApiError: (state) => {
            state.signUp.emailExists.ui_message = '';
            state.signUp.emailExists.exists = null;
            return state;
        },
        setSignUpUserNameApiError: (state, action) => {
            state.signUp.usernameExists.ui_message = action.payload.ui_message || '';
            state.signUp.usernameExists.exists = !!action.payload.ui_message;
            return state;
        },
        resetSignUpUserNameApiError: (state) => {
            state.signUp.usernameExists.ui_message = '';
            state.signUp.usernameExists.exists = null;
            return state;
        },
        setForgetPassword: (state, action) => {
            state.forgetPassword.ui_message = action.payload.ui_message || '';
            state.forgetPassword.success = !!action.payload.success;
            return state;
        },
        resetForgetPassword: (state) => {
            state.forgetPassword.ui_message = '';
            state.forgetPassword.success = null;
            return state;
        },
        checkToken: (state, action) => {
            state.checkToken.success = !!action.payload.success;
            return state;
        },
        resetCheckToken: (state) => {
            state.checkToken.success = null;
            return state;
        },
        setResetPassword: (state, action) => {
            state.resetPassword.success = !!action.payload.success;
            return state;
        },
        phoneSendCode: (state, action) => {
            state.signUp.phoneCodeSend.ui_message = action.payload.ui_message || '';
            state.signUp.phoneCodeSend.success = !!action.payload.success;
            return state;
        },
        resetPhoneSendCode: (state) => {
            state.signUp.phoneCodeSend.ui_message = '';
            state.signUp.phoneCodeSend.success = null;
            return state;
        },
        phoneVerifyCode: (state, action) => {
            state.signUp.phoneCodeVerify.ui_message = action.payload.ui_message || '';
            state.signUp.phoneCodeVerify.valid = action.payload.valid;
            return state;
        },
        resetVerifyCode: (state) => {
            state.signUp.phoneCodeVerify.ui_message = '';
            state.signUp.phoneCodeVerify.valid = null;
            return state;
        },
        setPracticeAreas: (state, action) => {
            state.signUp.data.practiceAreas = action.payload;
            return state;
        }
    }
});

export const {
    setUser,
    authError,
    setSignUpData,
    resetSignUpData,
    setSignUpEmailApiError,
    resetSignUpEmailApiError,
    setSignUpUserNameApiError,
    resetSignUpUserNameApiError,
    phoneSendCode,
    resetPhoneSendCode,
    phoneVerifyCode,
    changeLoggedIn,
    setForgetPassword,
    resetForgetPassword,
    checkToken,
    resetCheckToken,
    setResetPassword,
    resetAuth,
    resetAuthError,
    resetVerifyCode,
    changeCurrentStep,
    setPracticeAreas,
    setRole,
    reSetRole
}
    = auth.actions;

export const getUserInfo = () => async (dispatch) => {
    try {
        const user = await Api.me();
        dispatch(setUser(user?.data));
        dispatch(changeLoggedIn(true));
    } catch (e) {
        dispatch(authError(e));
        dispatch(changeLoggedIn(false));
    }
};

export const signUp = (data) => async (dispatch, getState) => {
    try {
        const user = assignLanguageData(data.user, getState);

        const payload = await Api.create({user});

        await LocalStorage.setLocalStorageAsync('token', payload.data?.user?.token);
        dispatch(setUser(payload?.data.user));
        dispatch(changeLoggedIn(true));
    } catch (e) {
        dispatch(authError(e));
        dispatch(openGeneralErrorModal(e))
    }
};

export const login = (data) => async (dispatch) => {
    try {
        const payload = await Api.login(data);

        await LocalStorage.setLocalStorageAsync('token', payload?.data?.token);
        dispatch(setUser(payload?.data));
        dispatch(changeLoggedIn(true));
    } catch (e) {
        if (!e.data) {
            dispatch(openGeneralErrorModal(e))
        }
        dispatch(authError(e))
    }
};

export const logOut = () => async (dispatch) => {
    try {
        localStorage.clear();
        clearIntervals();
        dispatch(setUser({}));
        dispatch(changeLoggedIn(false));
        dispatch(resetActiveProblem())
    } catch (e) {
        dispatch(authError(e))
    }
};

export const forgetPassword = (data) => async (dispatch) => {
    try {
        const payload = await Api.forgotPassword(data);
        dispatch(setForgetPassword(payload?.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(setForgetPassword(e.data))
        }
        dispatch(authError(e))
    }
};

export const checkResetPassToken = (token) => async (dispatch) => {
    try {
        const payload = await Api.checkResetPassToken(token);
        dispatch(checkToken(payload?.data))
    } catch (e) {
        dispatch(checkToken({success: false}))
    }
};

export const resetPassword = (data) => async (dispatch) => {
    try {
        const payload = await Api.resetPassword(data);
        dispatch(setResetPassword(payload?.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(setResetPassword(false))
        }
        dispatch(authError(e))
    }
};

export const emailExists = (data) => async (dispatch) => {
    try {
        const payload = await Api.emailExists(data);
        dispatch(setSignUpEmailApiError(payload?.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(setSignUpEmailApiError(e.data))
        }
        dispatch(authError(e))
    }
};

export const usernameExists = (data) => async (dispatch) => {
    try {
        const payload = await Api.usernameExists(data);
        dispatch(setSignUpUserNameApiError(payload?.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(setSignUpUserNameApiError(e.data))
        }
        dispatch(authError(e))
    }
};

export const twilioSendCode = (data) => async (dispatch) => {
    try {
        const payload = await Api.twilioSendCode(data);
        dispatch(phoneSendCode(payload?.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(phoneSendCode(e.data))
        }
        dispatch(authError(e))
    }
};

export const twilioVerifyCode = (data) => async (dispatch) => {
    try {
        const payload = await Api.twilioVerifyCode(data);
        dispatch(phoneVerifyCode(payload?.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(phoneVerifyCode(e.data))
        }
        dispatch(authError(e))
    }
};

export const becomeCoach = (data) => async (dispatch) => {
    try {
        await Api.becomeCoach(data);
        await dispatch(getUserInfo());
        await dispatch(getAccount());
    } catch (e) {
        dispatch(authError(e))
    }
};




export default auth.reducer
