import React, {useEffect, useState} from "react";
import { Switch } from 'elements';
import {useDispatch, useSelector} from "react-redux";
import {NotificationTurnOff, NotificationTurnOn, setNotificationAlert} from "../../vendors/redux/slices/accountSlice";
import InfoBanner from "../../elements/InfoBanner";

const notificationContent = () => {
    return [
        {
            alertType: 'warning',
            alertText: `Got it. We will stop sending you SMS notifications. This includes possible jobs.
             If you are an active coach on Veepow we recommend leaving it on.`,
        },
        {
            alertType: 'success',
            alertText: `SMS notifications are on! This is the most efficient way to receive job requests, 
            and it’s free for you \uD83D\uDE42 Do make sure we have the right mobile number!`,
        },
    ];
};

const Notifications = () => {
    const dispatch = useDispatch()
    const {notifications: {sms, email}, notificationAlert} = useSelector(state => state.account);
    const {notificationTypes: {SMS, EMAIL}} = useSelector(state => state.staticData);



    const handleNotificationChange = (e, type) => {
        const checked = e.target.checked;
        if(checked) {
            dispatch(NotificationTurnOn({type}));
        } else {
            dispatch(NotificationTurnOff({type}));
        }
    };

    return (
        <>
            <div className="account-info">
                <div className="content-top">
                    <h3>Notification</h3>
                </div>
                <div className="content flex">
                    <ul className="w-100 data-list space-b-sm">
                        <li className="justify-between align-start">
                            <div className="content-left">
                                <label className="text-label w-100">Mobile</label>
                                <p className="text-info">Leaving this enabled ensures you receive messages on time.</p>
                            </div>
                            <Switch checked={sms} onChange={(e) => handleNotificationChange(e, SMS)}/>
                        </li>
                        <li className="justify-between align-start">
                            <div className="content-left">
                                <label className="text-label w-100">Email</label>
                                <p className="text-info">Don’t worry, we know these can get annoying so we promise to only send
                                    the occasional email to help you get the most out of veepow.</p>
                            </div>
                           {/* <Switch checked={email} onChange={(e) => handleNotificationChange(e, EMAIL)}/>*/}
                        </li>
                    </ul>
                </div>
            </div>
            <InfoBanner
                inProp={notificationAlert !== null}
                type={notificationContent()[notificationAlert]}
                onClose={() => dispatch(setNotificationAlert(null))}
            />
        </>

    );
};

export default Notifications
