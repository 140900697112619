import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Modal} from "react-responsive-modal";
import EditProfile from "./modals/EditProfile";
import { Icomoon } from 'elements';
import {getAccount} from "vendors/redux/slices/accountSlice";
import UploadImage from "./modals/UploadImage";
import {resetEmailApiError} from "vendors/redux/slices/accountSlice";
import Avatar from "assets/img/avatar-default.svg";
import errorMessages from "vendors/services/yup-schemas/errorMessages";
const { aboutNote } = errorMessages ('info');


const PersonalInfo= () => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        dispatch(resetEmailApiError());
        setOpen(false)
    };

    const [openAvatarModal, setOpenAvatarModal] = useState(false);
    const onOpenAvatarModal = () => setOpenAvatarModal(true);
    const onCloseAvatarModal = () => setOpenAvatarModal(false);


    const {avatarUpload, account, userLanguages = []} = useSelector(state => state.account);
    const {first_name, family_name, username, email, location, about, avatar, was_tutor, date_of_birth} = account;


    useEffect(() => {
        if(Object.keys(account).length === 0) {
            dispatch(getAccount())
        }
    }, [account, dispatch]);

    return (
        <div className="account-info wrap-mobile">
            <div className="content-top flex align-center justify-between">
                <h3>Personal information</h3>
                <button onClick={onOpenModal} className='no-style pointer btn-icon'>
                    <Icomoon name='edit'/>
                </button>
            </div>
            <div className="content flex align-start">
                <ul className="content-left data-list w-75">
                    <li>
                        <label className="text-label">Username</label>
                        <p className="text-value">{username}</p>
                    </li>
                    <li>
                        <label className="text-label">First name</label>
                        <p className="text-value">{first_name}</p>
                    </li>
                    <li>
                        <label className="text-label">Family name</label>
                        <p className="text-value">{family_name}</p>
                    </li>
                    <li>
                        <label className="text-label">Date of birth</label>
                        <p className="text-value">{date_of_birth || 'N/A'}</p>
                    </li>
                    <li>
                        <label className="text-label">Email</label>
                        <p className="text-value">{email}</p>
                    </li>
                    {
                        was_tutor && !!userLanguages.length &&
                        <li>
                            <label className="text-label">Languages spoken</label>
                            <p className="text-value">{userLanguages?.map(l => l.name).join(', ')}</p>
                        </li>
                    }

                    <li>
                        <label className="text-label">Location</label>
                        <p className="text-value">{location}</p>
                    </li>
                    <li>
                        <label className="text-label">About</label>
                        <p className={`text-value break-word ${!about ? 'disabled' : ''}`}>{about || aboutNote}</p>
                    </li>
                </ul>
                <div className="content-right flex justify-end  w-25 hide-mobile">
                    {

                            <button
                                onClick={onOpenAvatarModal}
                                className='pointer'
                            >
                                {
                                    avatarUpload !== false ? <img className="avatar-image lg" src={avatar || Avatar}  alt="avatar"/> : <div className='shimmer-image lg' />
                                }
                            </button>

                    }
                </div>
            </div>

            {/*modal*/}
            <Modal
                open={open}
                onClose={onCloseModal}
                center>
                <EditProfile
                    onCloseModal={onCloseModal}
                />
            </Modal>
            <Modal
                open={openAvatarModal}
                onClose={onCloseAvatarModal}
                center>
                <UploadImage
                    onCloseAvatarModal={onCloseAvatarModal}
                />
            </Modal>
        </div>
    );
};

export default PersonalInfo;
