import socketIOClient from "socket.io-client";
import ProblemInboxUpdate from "./problemInboxUpdate";
import NotificationUpdate from "./notificationUpdate.js";
import ProblemUpdate from "./problemUpdate.js";
import workspaceUpdate from "./workspaceUpdate.js"
import deleteAccount from "./deleteAccount.js"

const ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT;


export default class Socket {
    static socket;
    static init ({token}) {
        this.socket = socketIOClient(ENDPOINT,{query: {token}});

        this.socket.on("problemInboxUpdate", ProblemInboxUpdate);

        this.socket.on("notificationUpdate", NotificationUpdate);

        this.socket.on("problemUpdate", ProblemUpdate);

        this.socket.on("workspaceUpdate", workspaceUpdate);

        this.socket.on("deleteAccount", deleteAccount);
    };
}
