import React, {useState} from 'react';
import Avatar from "assets/img/avatar-default.svg";
import SingleReviewForm from "./SingleReviewForm";
import {useSelector } from "react-redux";
import {StarRatings} from "elements";
import GuestProfile from "../publicProfile/modals/guestProfile";
import {Modal} from "react-responsive-modal";
import {formatDate} from "../../vendors/utils"

const SingleReview = ({review, user_uuid, subState, userType}) => {
    const {isPublicProfile} = useSelector(state => state.reviews[subState]);
    const [addReply, setAddReply] = useState(false);
    const [guestOpen, setGuest] = useState(false);
    const guestProfile = {
        open: () => setGuest(true),
        close: () => setGuest(false),
    }

    const onReply = () => {
        setAddReply(true)
    };

    const onReplyClick = (value) => {
        setAddReply(value)
    };

    return (
        <>
            <div className="flex justify-between review-top">
                <div disabled={!review.FromUser}
                     onClick={()=> review.FromUser && isPublicProfile && guestProfile.open()}
                     className={`content-left mr-5 flex align-center ${isPublicProfile && review.FromUser ? 'pointer' : ''}`}>
                    <button className={`mr-5 ${isPublicProfile && review.FromUser ? 'pointer' : ''}`}>
                        <img className="avatar-image sm" src={review.FromUser?.avatar || Avatar}  alt="avatar"/>
                    </button>
                    <p className='break-word'>{review.FromUser?.first_name || 'Anonymous'} {review.FromUser?.family_name || 'User'}</p>
                </div>
                <StarRatings
                    initialRating={review.rating}
                />
            </div>
            <div className="review">
                <p className="review-comment text-normal">{review.review}</p>
                <div className="review-bottom mt-7 flex align-center justify-between">
                    <p className="color-secondary-2 text-sm publish-info">Published {formatDate(review.published_date)}</p>
                    {isPublicProfile && <ul className="list-x-border reply-actions flex align-center">
                        {
                            !review.reply && !addReply && review.FromUser &&
                            <li>
                                <button
                                    className="text-underline btn-link"
                                    onClick={onReply}
                                >
                                    Reply
                                </button>
                            </li>
                        }

                       {/* <li>
                            <button className="text-underline btn-link">Report</button>
                        </li>*/}
                    </ul>}
                </div>

                {
                    review.reply && !addReply &&
                    <div className="review-replied mt-5">
                        <div className="reply-content mr-5 flex align-center">
                            <button className="mr-5">
                                <img className="avatar-image sm" src={review.ToUser?.avatar || Avatar}  alt="avatar"/>
                            </button>
                            <p>{review.ToUser?.first_name} {review.ToUser?.family_name}</p>
                        </div>
                        <div className="review">
                            <p className="reply-comment text-normal">{review.reply}</p>
                            <p className="color-secondary-2 text-sm publish-info mt-6">Published {formatDate(review.reply_published_date)}</p>
                        </div>

                    </div>
                }
                {
                    !review.reply && addReply && isPublicProfile &&
                    <SingleReviewForm
                        review={review}
                        onReplyClick={onReplyClick}
                        user_uuid={user_uuid}
                        subState={subState}
                        userType={userType}
                    />
                }
            </div>
            {review.FromUser && guestOpen && <Modal
                open={guestOpen}
                onClose={guestProfile.close}
                center
                classNames={{
                    modal: 'large-modal',
                }}
            >
                <GuestProfile user_uuid={review.FromUser?.user_uuid}/>
            </Modal>}
        </>
    );
};

export default SingleReview;
