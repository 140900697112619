import React, { memo } from 'react';
import {Button, StarRatings} from "elements";
import {useSelector} from "react-redux";
import ReviewForm from "./ReviewForm";

const ratingNames = {
    1: 'Poor',
    2: 'Fair',
    3: 'Good',
    4: 'Very good',
    5: 'Excellent'
};
const ReviewSecondStep = () => {
    const {reviewee: {profile: {first_name, family_name}}, reviewData: {rating}} = useSelector(state => state.addReview);

    return (
        <div className='text-center'>
            <h3>{ratingNames[rating]}</h3>
            <StarRatings
                initialRating={rating}
                className='inline-block lg py-8'
            />
            <p className='pb-5'>Leave a review for {first_name} {family_name}</p>
            <ReviewForm />
        </div>
    );
};

export default memo(ReviewSecondStep);
