import React from "react";
import NotFound from './404/NotFound'
import Login from './auth/login/Login'
import SignUp from './auth/signUp/SignUp'
import ForgotPassword from './auth/forgotPassword/ForgotPassword'
import { CheckRecoveryEmail, ResetPassword, ResetPasswordSuccess } from './auth/resetPassword'
import Home from './static/home/Home'
import ContactUs from './contactUs/ContactUs'
import AboutUs from './static/aboutUs/AboutUs'
import Privacy from './static/privacy/Privacy'
import Pricing from './static/pricing/Pricing'
import BecomeCoach from './becomeCoach/BecomeCoach'
import HowItWorks from './static/howItWorks/HowItWorks'
import TrustAndSafety from './static/trustAndSafety/TrustAndSafety'
import SearchTutor from "./searchTutor/SearchTutor";
import AccountProfile from "./accountProfile/AccountProfile";
import RegisterCoach from "./becomeCoach/RegisterCoach";
import PostProblem from "./postProblem/PostProblem";
import CountdownStudent from "./countDown/CountdownStudent";
import CountdownCoach from "./countDown/CountdownCoach";
import ProblemDetails from "./postProblem/ProblemDetails";
import Review from "./review/Review";
import StripeVerifyAccount from "./stripeVerifyAccount";
const Zoom = React.lazy(() => import('./zoom/zoom'));


export {
    NotFound,
    Login,
    SignUp,
    Home,
    ContactUs,
    AboutUs,
    Privacy,
    Pricing,
    BecomeCoach,
    HowItWorks,
    TrustAndSafety,
    SearchTutor,
    ForgotPassword,
    CheckRecoveryEmail,
    ResetPassword,
    ResetPasswordSuccess,
    AccountProfile,
    RegisterCoach,
    PostProblem,
    CountdownStudent,
    CountdownCoach,
    ProblemDetails,
    Review,
    Zoom,
    StripeVerifyAccount
}
