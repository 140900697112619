import React from 'react';
import {Button, Icomoon} from "elements";
import { CSSTransition } from 'react-transition-group';
import {Link} from "react-router-dom";
import CountdownZoom from "../pages/countDown/CountDownZoom";

const InfoBanner = ({
    inProp,
    type,
    className,
    onClose,
    path,
    hash,
    countdown
}) => {

    const content = type || '';

    let icon = ''

    switch (content.alertType) {
        case 'success':
            icon = 'info-circle'
            break;
        case 'warning':
            icon = 'exclamation-circle'
            break;
        case 'danger' :
            icon = 'exclamation-circle'
    }
    return (
        <CSSTransition
            in={inProp}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div
                className={`info-banner ${content.alertType || ''} ${className = ''}`}
            >
                <div className="info-banner-content">
                    <div className="content-left">
                        <Icomoon name={icon} size={20}/>
                        {
                            path ?
                                <Link to={{pathname: path,hash}} onClick={()=> onClose && onClose()}>
                                    {content.alertText}
                                    {countdown && <CountdownZoom className='ml-5'/>}
                                </Link> :
                                <p className="message">
                                    {content.alertText}
                                </p>
                        }
                    </div>
                    {
                        onClose &&
                        <div className="content-right">
                            <Button
                                className="with-icon no-width pl-0 pr-0"
                                styleType='tertiary'
                                onClick={onClose}
                            >
                                <Icomoon size={18} name='close' />
                            </Button>
                        </div>
                    }

                </div>
            </div>
        </CSSTransition>
    );
};

export default InfoBanner;

