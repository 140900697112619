import React from 'react';
import {useDispatch} from "react-redux";
import {Icomoon} from "elements";
import {setCurrentEducation} from "../../vendors/redux/slices/publicProfileSlice";

const Education =  ({item, openModal}) => {
    const dispatch = useDispatch();

    const setValues = (item) => {
        const currentValues = {
            school: item.school || '',
            field_study: item.field_study || '',
            from: {
                label: item.from || '',
                value: item.from || ''
            },
            to: {
                label: item.to || '',
                value: item.to || ''
            },
            education_id: item.id
        };
        dispatch(setCurrentEducation(currentValues));
        openModal();
    };

    return (
        <div className="content-top flex align-center justify-between mb-6">
            <ul className="li-space-xxs break-word">
                <li className="text-value mr-3 text-bold">{item?.school}</li>
                <li className="text-value mr-3 text-normal">{item?.field_study}</li>
                <li className="text-value mr-3 text-normal"><span>{item?.from}</span> - <span>{item?.to}</span></li>
            </ul>
            <button onClick={() => setValues(item)} className='no-style pointer btn-icon'>
                <Icomoon name='edit' />
            </button>
        </div>
    );
};

export default Education;
