import Base from './base';

class Payment extends Base {

    getStripeExpressUrl() {
        return this.get('payment/stripe-express-url');
    }

    authorizeStripeAccount(data) {
        return this.post('payment/authorize-user-stripe-account', data);
    }

    getUserLoginLink() {
        return this.get('payment/stripe-user-login-link');
    }

    createPaymentMethod(data) {
        return this.post('payment/create-payment-method', data);
    }

    editPaymentMethod(data) {
        return this.post('payment/edit-payment-method', data);
    }

    deletePaymentMethod() {
        return this.delete('payment/delete-payment-method');
    }

    topUp(data) {
        return this.post('payment/top-up', data);
    }
}

export default new Payment();
