import React, {useEffect, useState} from "react";
import Footer from "../../layout/footer/Footer";
import HeaderGuest from "../../layout/header/guest/Header";
import HeaderSignedIn from "../../layout/header/signedIn/Header";
import { useDispatch, useSelector } from "react-redux";
import ContactForm from "../../component/contactUs/ContactForm";
import { clearResponse } from "./../../vendors/redux/slices/supportSlice";
import {useHistory} from "react-router";

const ContactUs = () => {
  const dispatch = useDispatch();
  const {key} = useHistory().location;
  const { loggedIn } = useSelector((state) => state.auth);
  const { error, response } = useSelector((state) => state.supportData);


  useEffect(() => {
    dispatch(clearResponse());
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [response]);


  useEffect(() => {
    dispatch(clearResponse());
  }, [key])
  return (
      <>
        {loggedIn ? <HeaderSignedIn /> : <HeaderGuest />}
        <main className="main-wrapper with-padding">
          <div className="small-page">
            <div className="container">
              <div className="small-page-inner">
                <div className="max-width-680">
                  <h1 className="pb-6">Contact us</h1>
                  {response ? (
                      <>
                        <p className="mb-5">Message sent!</p>
                        <p>
                          Thank you for your email. Our support team will be in
                          touch with you within 48 hours.
                        </p>
                      </>
                  ) : (
                      <>
                        <p className="mb-3">
                          To reach our customer service team, please submit your question using the contact form below.
                        </p>
                        <ContactForm />
                        {error && (
                            <p className='color-danger text-right text-xs pt-5'>The message was not sent. Please try again.</p>
                        )}
                      </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </>
  );
};

export default ContactUs;
