import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Button} from "elements";
import {filterLevels, setSubject} from "vendors/redux/slices/selectSubjectSlice";
import {assignDisableToLevels} from "vendors/utils";
import {useDispatch, useSelector} from "react-redux";

const SubjectCards = ({bgWhite}) => {
    const dispatch = useDispatch();
    const {subjects, levels} = useSelector(state => state.staticData);

    const onSubjectClick = (subject) => {
        dispatch(setSubject({
            ...subject,
            value: subject.name,
            label: subject.name
        }));
        const modified = assignDisableToLevels(subject, levels);

        dispatch(filterLevels(modified))
    };
    const [extendSubjects, setExtendSubjects] = useState(false);


    const handleLoadMoreClick = () => {
        setExtendSubjects(true)
    };

    return (
        <>
            <ul className={`subject-list ${extendSubjects ? 'extended' : ''}`}>
                {
                    subjects.map((item, index) => {
                        return (
                            <li
                                style={{pointerEvents: item.coming_soon ? 'auto' : "none"}}
                                key={`item-${index}`}
                                className={`${item.coming_soon ? 'subject-coming-soon' : ''} ${bgWhite ? 'white' : ''} subject-item`}
                                onClick={() =>onSubjectClick(item)}
                            >
                                {/**
                                 will be activated when there will be coaches
                                 */}
                                <Link to={'/search-tutor'} >
                                    <div className="subject-item-content">
                                        <h4>{item.name}</h4>
                                        <p>{item.level_range}</p>
                                    </div>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
            {
                !extendSubjects &&
                <div className='text-center pt-10 show-mobile '>
                    <Button
                        styleType='secondary'
                        onClick={handleLoadMoreClick}
                    >
                        Load more subjects
                    </Button>
                </div>
            }
        </>
    );
};

export default SubjectCards;
