import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import {useFormik} from "formik";
import {addEducation, deleteEducation, updateEducation} from "vendors/redux/slices/publicProfileSlice";
import {manageEducation as validationSchema} from "vendors/services/yup-schemas/publicAccountSchemas";
import {Button, Input} from "elements";

const buildYears = (offsetNumber) => {
    const arr = [];
    const thisYear = (new Date()).getFullYear();
    const minOffset = 10;
    const maxOffset = 70;
    for (let i = offsetNumber; i <= maxOffset; i++)  {
        const year = thisYear - i;
        arr.push(
            {label: year, value: year},
        );
    }

    return arr;
};

const ManageEducation = ({onCloseModal}) => {
    const dispatch = useDispatch();
    const {currentEducation} = useSelector(state => state.profile);
    const {user: {user_uuid}} = useSelector(state => state.auth);

    const offsetFrom = 0;
    const offsetTo = -10;

    const optionsFrom = buildYears(offsetFrom);
    const optionsTo = buildYears(offsetTo);

    const initialValues = {
        school: currentEducation.school,
        field_study: currentEducation.field_study,
        from: currentEducation.from,
        to: currentEducation.to
    };

    const onSubmit = () => {
        //add or update education ( for update we send education_id )
        const educationReducer = currentEducation?.education_id ? updateEducation : addEducation;

        const params = {
            school: values.school,
            field_study: values.field_study,
            from: values.from.value || null,
            to: values.to.value || null,
            education_id: currentEducation?.education_id || null
        };

        dispatch(educationReducer(params, user_uuid));
        onCloseModal();
    };

    const onDelete = () => {

        const params = {
            education_id: currentEducation.education_id
        };

        dispatch(deleteEducation(params, user_uuid));
        onCloseModal();
    };

    const {
        values,
        errors,
        className = '',
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        handleFocus,
        isValid,
        dirty,
        setFieldValue
    } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: validationSchema
    });
    
    return (
        <>
            <div className="modal-body">
                <h3 className="modal-title">  {currentEducation.education_id == null ? 'Education' : 'Edit my education'}</h3>
                <form onSubmit={handleSubmit}>
                    <ul className="li-space">
                        <li className="label-inline">
                            <label>School*</label>
                            <Input
                                name='school'
                                type='text'
                                className='mb-0 w-100'
                                errorMessage={errors.school}
                                value={values.school}
                                touched={touched.school}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />
                        </li>
                        <li className="label-inline">
                            <label>Field of study</label>
                            <Input
                                name='field_study'
                                type='text'
                                value={values.field_study}
                                className='mb-0 w-100'
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                handleFocus={handleFocus}
                                maxLength={500}
                            />
                        </li>
                        <li className="label-inline">
                            <label>From</label>
                            <div
                                className={`form-input mb-0 select-box single w-158 ${errors.from && touched ? 'has-error' : ''} ${className}`}>
                                <Select
                                    name='from'
                                    classNamePrefix='select'
                                    placeholder=''
                                    options={optionsFrom}
                                    onChange={v => setFieldValue('from', v)}
                                    value={values.from || ''}
                                    errorMessage={errors.to}
                                />
                                {
                                    errors.from && touched && <p className='error-message'>{errors.from}</p>
                                }
                            </div>

                        </li>
                        <li className="label-inline">
                            <label>To (or expected)</label>
                            <div
                                className={`form-input mb-0 select-box single w-158 ${errors.to && touched ? 'has-error' : ''} ${className}`}>
                                <Select
                                    name='to'
                                    classNamePrefix='select'
                                    placeholder=''
                                    options={optionsTo}
                                    onChange={v => setFieldValue('to', v)}
                                    errorMessage={errors.to}
                                    value={values.to || ''}
                                />
                                {
                                    errors.to && touched && <p className='error-message'>{errors.to}</p>
                                }
                            </div>
                        </li>
                    </ul>
                    <div className="modal-footer side-aligned">
                        <div className="content-left">
                            {currentEducation.education_id !== null &&
                                < Button type="button" className="w-90" styleType='secondary' onClick={onDelete}>Delete</Button>
                            }
                        </div>
                        <div className="content-right">
                            <Button className="text-underline" styleType='tertiary' onClick={onCloseModal}>Cancel</Button>
                            <Button className='w-126 small' type='submit'
                                    disabled={!(isValid && dirty)}>Save</Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
};
export default ManageEducation;
