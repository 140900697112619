import React from 'react';
import {useSelector} from "react-redux";


const AreaOfExpertiseView = () => {
    const {practiceAreas = [], profile: {user_uuid, role, was_tutor}} = useSelector(state => state.guestProfile);

    return (
        <>
            {practiceAreas.length ? <div className="account-info wrap-mobile">
                <div>
                    <div className="content-top pb-6">
                        <h3 className='pb-3'>Subjects I can help with</h3>
                    </div>
                    <div className="pb-7 mt-0 border-bottom-light">
                        <ul className="profile-editable-list">
                            {[...practiceAreas]?.map(((item) =>
                                    <li key={item.id}>
                                        <div className="list-item-main">
                                            <label className='text-label w-100 pb-2'>{item?.name}</label>
                                            <p className='text-xs color-text-primary-light'>
                                                {item?.levels?.map(level => level.name).join(', ')}
                                            </p>
                                        </div>
                                    </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div> : <></>}
        </>
    )
};

export default AreaOfExpertiseView;
