import * as _ from 'lodash';
import * as moment from 'moment';
import BaseApi from '../vendors/services/api/base';

const {REACT_APP_ENV} = process.env;

const commonFormat = 'YYYY MMM DD';

const serializeParams = {
    lang: {
        fields: ['id', 'name'],
        prefix: 'language_',
        defaults: ['user_language_id', 'language_new', 'language_removed']
    }
}

export const expires_in = (REACT_APP_ENV === "local") ? 61 : 601;
// will shown when in 25 minutes
export const zoom_overlay_seconds = (REACT_APP_ENV === "local") ? 120 : 600;
// will shown when in 30 minutes
export const zoom_buffer_seconds = (REACT_APP_ENV === "local") ? 60 : 300;
// overlay minutes
export const warningMinute = (REACT_APP_ENV === "local") ? 1 : 5;
// overlay minutes 5
export const warningMinute5 = (REACT_APP_ENV === "local") ? 1 : 5;

export const addTimerInterval = (date= moment.utc()) =>  {
    return moment(date).add(expires_in,'seconds');
}

export const formatDate = (date, format = commonFormat) => moment(date).format(format);

export const constructSelectOption = (arr, opt = {}) => {
    return arr.map((prop) => {
        const option =  {
            ...prop,
            value: prop.name,
            label: prop.name
        };

        if (opt.check_coming_soon){
         Object.assign(option,{isDisabled: prop.isDisabled || prop.coming_soon})
        }

        return option;
    });
};

export const assignDisableToLevels = (subject, levels) => {
    if (subject?.disabled_levels?.length) {
        return levels.map(l => {
            if (subject.disabled_levels.includes(l.id || l.level_id) || l.coming_soon) return {...l, isDisabled: true};

            return l;
        })
    }
    return levels;
};

export const markAllLevelsDisable = (levels) => {
    return levels.map(l => {
        return {...l, isDisabled: true}
    });
};

export const serializeData = (type, values) => {
    const params = serializeParams[type];
    const data = [];

    for (const obj of values) {
        //assign serialized fields
        const newObj = {}
        for (const field of params['fields']) {
            if (obj[field]) {
                Object.assign(newObj, {[params['prefix'] + field]: obj[field]})
            }
        }

        //assign default fields
        for (const field of params['defaults']) {
            if (obj[field]) {
                Object.assign(newObj, {[field]: obj[field]})
            }
        }

        data.push(newObj);
    }

    return data;
};

export const assignLanguageData = (data, getState)=> {
    data = _.cloneDeep(data);

    //assign languages
    const userLangForm = getState().userLangForm;
    Object.assign(data, {
        languages: serializeData('lang', userLangForm.formLanguages)
    });
    //assign languages end

    return data;
};

export const filterLangs = (lanuages) => {
    // filter only English and Chinese
    return lanuages.filter(l=>l.id < 3);
}

export const calcCirclePercent = (seconds) => {
    return Math.floor((seconds / expires_in) * 100)
}

export const getThisYear = () => {
    return new Date().getFullYear();
}

export const clearIntervals = () => {
    if (Object.keys(window?.timers).length) {
        for (const i in window.timers) {
            clearInterval(window.timers[i])
        }
    }
}

export const registerInterval = (id, interval) => {
    if (!window.timers) {
        window.timers = {};
    }
    window.timers[id] = interval;
}

export const clearInWindowInterval = (id) => {
    return new Promise((res, rej)=>{
        if (window?.timers[id]) {
            localStorage.removeItem(id);
            clearInterval(window.timers[id]);
            delete window?.timers[id];
            return res();
        }
        return res();
    })
}

export const scrollToId = (id) => {
    const element = document.getElementById(id);
    const yOffset = -100;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
}

export const hashToId = (hash) => {
    return hash.substring(1);
}

export const getRole = (problem, user_uuid) => {
    if (problem.student === user_uuid) {
        return 1
    }
    if (problem.tutor === user_uuid) {
        return 2
    }
}

export const downloadFile = async (e, image_url, name) => {
    e.preventDefault();
    e.stopPropagation();
    decodeURIComponent(image_url.replace(/\+/g, " "))
    const response = await BaseApi.getPublic(decodeURIComponent(image_url.replace(/\+/g, " ")));
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(response.data);
    a.href = url;
    a.download = name;
    return a.click();
};

export const modifyMonth = (m) => {
    if (m < 10) {
        return '0' + m
    }
    return m;
}
