import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    formLanguages: [],
    error: null
};

const userLangFormSlice = createSlice({
    name: 'userLangForm',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.formLanguages = [...new Set([...action.payload])];
            return state;
        },
        resetLanguages: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setLanguage,
    resetLanguages,
    actionError
}
    = userLangFormSlice.actions;

export default userLangFormSlice.reducer
