import * as Yup from 'yup';
import errorMessages from './errorMessages.js';
import {passRegex, _onlyLetters, emailRegEx, dateOfBirthRegex, dateOfBirthRegexPattern} from "./helpers";


const validationMessages = errorMessages ('validation');


export const personalInfoSchema = ({ui_message, exists}) => {

    return Yup.object().shape({

        first_name: Yup
            .string()
            .test({
                test: function (value) {
                    return _onlyLetters(this, value);
                }
            })
            .min(2, validationMessages.twoOrMore),
        family_name: Yup
            .string()
            .test({
                test: function (value) {
                    return _onlyLetters(this, value);
                }
            })
            .min(2, validationMessages.twoOrMore),
        date_of_birth: Yup
            .string()
            .matches(dateOfBirthRegexPattern, validationMessages.dateOfBirth)
            .required(validationMessages.required),
        email: Yup.string()
            .required(validationMessages.required)
            .matches(emailRegEx, validationMessages.email)
            .test('This email is already registered. Please try again.', ui_message , ()=> exists !== true),

        about: Yup.string()
            .max(1000),

        language: Yup.lazy(value => {
            if(value !== undefined) {
                return Yup.array()
                    .min(1, validationMessages.oneOrMoreLanguage)
                    .required(validationMessages.required)
                    .nullable()
            }
            return Yup.array().notRequired()
        })

    });
};


export const editPasswordSchema = ({ui_message, success}) => {

    return Yup.object().shape({

        oldPassword: Yup
            .string()
            .required(validationMessages.required)
            .min(8, validationMessages.eightOrMore)
            .matches(passRegex, validationMessages.combine)
            .test('This email is already registered. Please try again.', ui_message , () => success === true),

        newPassword: Yup
            .string()
            .required(validationMessages.required)
            .min(8, validationMessages.eightOrMore)
            .matches(passRegex, validationMessages.combine),

        confirmPassword: Yup
            .string()
            .required(validationMessages.required)
            .oneOf(
                [Yup.ref('newPassword'), null],
                validationMessages.confirmation)

    });
};


export const reviewSchema = () => {

    return Yup.object().shape({
        review: Yup.string()
            .max(500),
    });
};
