import React, { Suspense } from 'react';
import { useSelector} from 'react-redux'
import {Route, Router, Switch, Redirect} from "react-router-dom";
import ScrollToTop from 'hocs/ScrollToTop'
import * as Pages from 'pages/pagesIndex';
import {history} from 'vendors/redux/store';


const PrivateRoute = ({component: Component, redirect, authed, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{pathname: redirect || '/', state: {from: props.location}}}/>}
        />
    )
};

const OutRoute = ({component: Component, authed, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => !authed
                ? <Component {...props} />
                : <Redirect to={{pathname: '/account-profile', state: {from: props.location}}}/>}
        />
    )
};

const Routes = () => {
    const {loggedIn} = useSelector(state => state.auth);

    return (
        <div>
            <Router history={history}>
                <ScrollToTop>
                    <Switch>
                        <OutRoute exact={true} path='/' component={Pages.Home}/>
                        <OutRoute path='/contact-us' component={Pages.ContactUs}/>
                        <OutRoute path='/about-us' component={Pages.AboutUs}/>
                        <OutRoute path='/privacy' component={Pages.Privacy}/>
                        <OutRoute path='/pricing' component={Pages.Pricing}/>
                        <OutRoute path='/become-a-coach' component={Pages.BecomeCoach}/>
                        <OutRoute path='/how-it-works' component={Pages.HowItWorks}/>
                        <OutRoute path='/trust-and-safety' component={Pages.TrustAndSafety}/>
                        <OutRoute path='/register-coach' component={Pages.RegisterCoach}/>
                        <OutRoute authed={loggedIn} path='/sign-in' component={Pages.Login}/>
                        <OutRoute authed={loggedIn} path='/sign-up' component={Pages.SignUp}/>
                        <OutRoute authed={loggedIn} path='/forgot-password/:token' component={Pages.ResetPassword}/>
                        <OutRoute authed={loggedIn} path='/forgot-password' component={Pages.ForgotPassword}/>
                        <OutRoute authed={loggedIn} path='/reset-password' component={Pages.ResetPassword}/>
                        <OutRoute authed={loggedIn} path='/reset-password-success' component={Pages.ResetPasswordSuccess}/>
                        <OutRoute authed={loggedIn} path='/check-email' component={Pages.CheckRecoveryEmail}/>
                        <Route path="/search-tutor" component={Pages.SearchTutor}/>
                        <PrivateRoute exact={false}  authed={loggedIn} path='/authorize-user-stripe-account' component={Pages.StripeVerifyAccount}/>
                        <PrivateRoute authed={loggedIn} redirect={'/sign-in'} path='/account-profile' component={Pages.AccountProfile}/>
                        <PrivateRoute authed={loggedIn} path='/post-problem' component={Pages.PostProblem}/>
                        <PrivateRoute authed={loggedIn} path='/countdown-student' component={Pages.CountdownStudent}/>
                        <PrivateRoute authed={loggedIn} path='/countdown-coach/:problem_uuid' component={Pages.CountdownCoach}/>
                        <PrivateRoute exact={true} authed={loggedIn} redirect={'/sign-in'} path='/problem-details/:problem_uuid' component={Pages.ProblemDetails}/>
                        <PrivateRoute exact={true} authed={loggedIn} redirect={'/sign-in'} path='/:problem_uuid' component={Pages.ProblemDetails}/>
                        <PrivateRoute authed={loggedIn} path='/review/:problem_uuid/:user_uuid' component={Pages.Review}/>
                        <Suspense fallback={<div>Zoom is Loading...</div>}>
                            <PrivateRoute authed={loggedIn} path="/workspace/:problem_uuid/:meeting_id" component={Pages.Zoom}/>
                        </Suspense>
                        <PrivateRoute authed={loggedIn} path="/workspace/:problem_uuid/:meeting_id" component={Pages.Zoom}/>

                        <Route path="/404" component={Pages.NotFound}/>
                        <Route path='*' exact={true} component={Pages.NotFound}/>
                    </Switch>
                </ScrollToTop>
            </Router>
        </div>
    );
};

export default Routes;
