import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/payment";
import {openGeneralErrorModal} from "./layoutSlice";
import {getUserInfo} from "./authSlice";
import {getAccount} from "./accountSlice";


const initialState = {
    cardForm: {
        data: {},

        pending: null,
        success: null,
        error: null
    },
    topUp: {
        data: {},

        pending: null,
        success: null,
        error: null
    },
    deletionSuccess: null,
};

const PaymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPending: (state, action) => {
            state.cardForm.pending = action.payload;
            return state;
        },
        reset: () => {
            return initialState;
        },
        resetError: (state) => {
            state.cardForm.error = null;
            return state;
        },
        actionError: (state, action) => {
            state.cardForm.error = action.payload;
            return state;
        },
        setTopPending: (state, action) => {
            state.topUp.pending = action.payload;
            return state;
        },
        topUpError: (state, action) => {
            state.topUp.error = action.payload;
            return state;
        },
        resetTopError: (state) => {
            state.topUp.error = null;
            return state;
        },
        cardDeletionSuccess: (state) => {
            state.deletionSuccess = true;
            return state;
        },
    }
});

export const {
    reset,
    resetError,
    setPending,
    actionError,
    setTopPending,
    topUpError,
    resetTopError,
    cardDeletionSuccess
}
    = PaymentSlice.actions;



export const addCard = (req,{update}) => async dispatch => {
    try {
        dispatch(setPending(true));
        dispatch(resetError(null));

        const data = await Api.createPaymentMethod(req);

        if (update) {
            dispatch(getUserInfo());
            dispatch(getAccount());
        }

        dispatch(setPending(false));
    } catch (e) {
        await dispatch(actionError(e?.data || e))
        dispatch(setPending(false));
    }
};

export const editCard = (req) => async dispatch => {
    try {
        dispatch(setPending(true));
        dispatch(resetError(null));

        await Api.editPaymentMethod(req);

        dispatch(setPending(false));
    } catch (e) {
        await dispatch(actionError(e?.data || e))
        dispatch(setPending(false));
    }
};

export const deleteCard = (req) => async dispatch => {
    try {
        dispatch(setPending(true));
        dispatch(resetError(null));
        dispatch(cardDeletionSuccess(true));
        await Api.deletePaymentMethod(req);
        dispatch(setPending(false));
    } catch (e) {
        dispatch(openGeneralErrorModal(e));
        dispatch(setPending(false));
    }
};

export const authorizeStripeAccount = (code) => async dispatch => {
    try {
        const res = await Api.authorizeStripeAccount({code});

        if (res?.data?.success){
            window.location.href = '/account-profile';
        }
    } catch (e) {
        dispatch(openGeneralErrorModal(e));
    }
}

export const getStripeExpressUrl = () => async dispatch => {
    try {
        const res = await Api.getStripeExpressUrl();

        if (res?.data){
            window.location.href = res.data.stripeExpressUrl;
        }
    } catch (e) {
        dispatch(openGeneralErrorModal(e));
    }
}

export const getUserLoginLink = () => async dispatch =>  {
    try {
        const res = await Api.getUserLoginLink();

        if (res?.data){
            window.location.href = res.data.stripeUserLink;
        }
    } catch (e) {
        dispatch(openGeneralErrorModal(e));
    }
}

export const topUp = (data) => async dispatch =>  {
    try {
        await dispatch(setTopPending(true));

        const res = await Api.topUp(data);

        dispatch(getUserInfo());
        dispatch(getAccount());

        await dispatch(setTopPending(false));
    } catch (e) {
        await dispatch(topUpError(e?.data || e))
        dispatch(setTopPending(false));
    }
}


export default PaymentSlice.reducer
