import {useState, useEffect} from "react";
import {Timer, problemRemaining, TimerTypes} from  "../../vendors/services/timers"


const Countdown = (props) => {
    const {end, after, getRemaining, type} = props;

    const [remainingTime, setRemaining] = useState(null);

    const updateRemaining = (timer) => {
        const remaining = problemRemaining(end);
        setRemaining(remaining?.date);

        if (!remaining) {
            timer.clear();
            after && after();
        }

        getRemaining && getRemaining(remaining);
    };

    useEffect(() => {
        const timer = new Timer({
            after,
            name: type || TimerTypes.new_problem,
            update: updateRemaining
        }).start();
        // return () => timer.clear();
    }, [end]);


    return (
        <div>
            <div className='countdown-timer'>
                {remainingTime && <div>
                    <span>{remainingTime?.getMinutes() || '0'}m </span><span>{remainingTime?.getSeconds() || '00'}s</span>
                </div>}
            </div>
        </div>
    );
};

export default Countdown;
