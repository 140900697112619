import Base from './base';

class SearchTutor extends Base {

    searchTutors(data) {
        return this.get('user/search-tutors', data);
    }
}

export default new SearchTutor();
