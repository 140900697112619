import React from 'react';
import {useFormik} from "formik";
import { reviewSchema as validationSchema } from 'vendors/services/yup-schemas/accountSchemas'
import {useDispatch, useSelector} from "react-redux";
import {addReply} from "vendors/redux/slices/reviewSlice";
import {reviewsReducers, roles} from "./config"


const SingleReviewForm = ({onReplyClick, review, user_uuid, subState, userType}) => {
    const dispatch = useDispatch();
    const {index, limit} = useSelector(state => state.reviews[subState][userType]);
    const reviewsReducer = reviewsReducers[userType];
    const role = roles[userType];

    const initialValues = {
        review: ''
    };

    const onSubmit = async () => {
     await dispatch(addReply({
            review_uuid: review.review_uuid,
            reply: values.review
        }));
        dispatch(reviewsReducer({user_uuid, offset: 0, limit: index * limit, role}, {index, afterReplay: true, subState}));
        onReplyClick(false);
    };


    const { values, handleChange, handleSubmit, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });
    return (
        <form onSubmit={handleSubmit} className='pt-4'>
            <div className="form-input mb-0">
                <textarea
                    name='review'
                    value={values.review}
                    onChange={handleChange}
                    cols="30"
                    rows="5"
                />
            </div>
            <ul className="list-x-border reply-actions flex align-center justify-end">
                <li>
                    <button
                        className="text-underline btn-link"
                        onClick={()=>onReplyClick(false)}
                    >
                        Cancel
                    </button>
                </li>
                <li>
                    <button
                        type='submit'
                        className="text-underline btn-link"
                        disabled={!(isValid && dirty)}
                    >
                        Submit
                    </button>
                </li>
            </ul>
        </form>
    );
};

export default SingleReviewForm;
