import React from 'react';
import { Icomoon } from 'elements';

const Checkbox = ({
    id,
    name,
    label,
    className='',
    checked,
    onChange,
    onClick,
    auth,
    disabled
}) => {
    return (
        <label className={`checkbox ${className} ${disabled ? 'disabled' : ''}`}>
            <input
                id={id}
                type="checkbox"
                checked={checked && !disabled}
                onChange={onChange}
                onClick={onClick}
                name={name}
                className="checkbox-control"
                disabled={disabled}
            />
            {/*<span className="check-icon" />*/}
            <span className='check-icon'>
                <Icomoon name='check' size={10} />
            </span>

            <span className={`checkbox-label ${auth ? 'auth' : ''}`}>{label}</span>
        </label>
    );
};

export default Checkbox;
