import React from 'react';
import PersonalInfo from "./PersonalInfo";
import Password from "./Password";
import Payment from "./Payment";
import Notifications from "./Notification";
import AccountDeletion from "./AccountDeletion";

const Account = (props) => {


    return (
        <div className="tab-content">
            <PersonalInfo />
            <Password />
            <Payment hash={props.hash}/>
            <Notifications />
            <AccountDeletion />
        </div>
    );
};

export default Account;
