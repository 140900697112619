import React from 'react';
import {Icomoon} from 'elements';
import InputMask from "react-input-mask";


const InputMasked = ({
                    className='',
                    withIcon = false,
                    type = 'text',
                    info,
                    name,
                    value,
                    touched,
                    errorMessage,
                    handleChange,
                    handleBlur,
                    handleFocus,
                    handleKeyPress,
                    flag,
                    disabled,
                    label,
                    existedErrorMessage,
                    withCheck,
                    placeholder,
                    mask,
                    maskChar,
                    alwaysShowMask = false
                }) => {


    return (
        <div className={`form-input ${errorMessage && touched? 'has-error': ''} ${flag ? 'flag-input' : ''} ${className}`} >
            {label && <label >{label}</label>}
            <div className='pos-rel w-100'>
                {
                    flag &&
                    <div className='flag-icon'>
                        <img src={flag} alt={flag} className='flag card-img'/>
                    </div>
                }
                <InputMask
                    type={`${type || 'text'}` }
                    name={name}
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onKeyPress={handleKeyPress}
                    className={`${withCheck && withIcon && (!errorMessage && touched) ? 'pr-80' : (withIcon && withCheck) || (!errorMessage && touched) ? 'pr-60' : '' }`}
                    disabled={disabled}
                    placeholder={placeholder}
                    mask={mask}
                    alwaysShowMask={alwaysShowMask}
                    maskChar={maskChar}
                />

                <div className='input-abs'>
                    {
                        !errorMessage && touched && withCheck &&
                            <>
                                <Icomoon name='check-thin' color='#17B429'/>
                            </>

                    }
                </div>

            </div>
            {info && <p className='info-message'>{info}</p>}
            {
                errorMessage && touched && !existedErrorMessage && <p className='error-message'>{ errorMessage }</p>
            }
        </div>
    );
};

export default InputMasked
