import * as Yup from 'yup';
import errorMessages from './errorMessages.js';


const validationMessages = errorMessages ('validation');


export const problemFirstSchema = () => {

    return Yup.object().shape({

        subject: Yup.object().shape({
            name: Yup.string()
                .required(validationMessages.requiredSubject),
        }),

        level: Yup.object().shape({
            name: Yup.string()
                .required(validationMessages.requiredLevel),
        }),

        comment: Yup.string()
            .max(1000)
            .required(validationMessages.required),
    });
};


export const addReviewSchema = () => {
    return Yup.object().shape({

        review: Yup.string()
            .trim()
            .max(500, validationMessages.max500)
            .required(validationMessages.required),
    });
}
