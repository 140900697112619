import React, {useEffect} from "react";
import {useDispatch} from "react-redux"
import AboutInfo from "../AboutInfo";
import EducationsView from "../EducationsView"
import AreaOfExpertiseView from "../AreaOfExpertiseView"
import Reviews from "../../reviews/Reviews";

import {getGuestProfile} from "vendors/redux/slices/guestProfileSlice";


const GuestProfile = (props) => {
    const dispatch = useDispatch();
    const {user_uuid} =  props;

    useEffect(() => {
        dispatch(getGuestProfile(user_uuid));
    }, []);

    return (
        <>
            <AboutInfo slice={'guestProfile'}/>
            <EducationsView/>
            <AreaOfExpertiseView/>
            <Reviews
                subState={'guestProfile'}
                user_uuid={user_uuid}
                guest={true}
            />
        </>
    )
};

export default GuestProfile;
