import {store} from "../../redux/store";
import {getInbox} from "../../redux/slices/inboxSlice";
import {getBells} from "../../redux/slices/bellsSlice";
import {getProblem} from "../../redux/slices/singleProblemSlice";

const defaultCase = async (data) => {
    const {problem = {}} = store.getState().activeProblem;

    await store.dispatch(getProblem({problem_uuid: problem?.problem_uuid || data?.data.problem_uuid}));
    await store.dispatch(getInbox());
}

export default async (data) => {
    const {staticData: {problemEvents}} = store.getState();

    switch (data?.event) {
        case problemEvents.new:
            await store.dispatch(getInbox());
            if (data?.data.student) {
                store.dispatch(getBells({user_uuid: data?.data.student}));
            }
            return;
        default:
            return defaultCase(data);
    }
}
