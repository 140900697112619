import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Timer, problemRemaining, TimerTypes} from "../../vendors/services/timers"
import {closeProblem} from "../../vendors/redux/slices/singleProblemSlice";
import {setGeneralBanner} from "vendors/redux/slices/layoutSlice";
import {endMeeting} from "../../vendors/redux/slices/workspaceSlice";
import {bannerTypes} from "../../layout/header/signedIn/configs";
import {zoom_overlay_seconds, zoom_buffer_seconds, warningMinute, warningMinute5} from "vendors/utils";


const CountdownZoom = ({after, getRemaining, className}) => {
    const dispatch = useDispatch();

    const {meeting} = useSelector(state => state.workspace);
    const {problem: {meeting_end, problem_uuid}} = useSelector(state => state.activeProblem);

    const [remainingTime, setRemaining] = useState(null);

    const _endMeeting = async () => {
        await dispatch(closeProblem({problem_uuid}));
        await dispatch(endMeeting({zoom_meeting_id: meeting.zoom_meeting_id}));

        window.location.reload();
    }

    const updateRemaining = (timer) => {
            const remaining = problemRemaining(meeting_end);
            setRemaining(remaining);

            if (!remaining) {
                timer.clear();
                after && after();
            }

            getRemaining && getRemaining(remaining);

            if (remaining?.in_seconds === zoom_overlay_seconds) {
                dispatch(setGeneralBanner(bannerTypes.zoomOverlay))
            }

            if (remaining?.in_seconds === zoom_buffer_seconds) {
                dispatch(setGeneralBanner(bannerTypes.zoomBuffer))
            }
    };

    const startMeeting = () => {
        let timer = {clear: ()=>{}}

        if (meeting_end){
            // set after method
            after = after || _endMeeting;

            timer = new Timer({
                after,
                name: TimerTypes.zoom_meeting,
                update: updateRemaining
            }).start();
        }
        return () => timer.clear();
    }

    useEffect(startMeeting, [meeting_end]);

    const checkShowTimer = () => {
        const m = remainingTime?.date?.getMinutes();
        const s = remainingTime?.date?.getSeconds();

        if (meeting.duration === m && s > 0) {
           return null
        }

        return true
    }

    const getMinutes = (minutes) => {
        if (remainingTime.in_seconds > zoom_overlay_seconds) {
            return minutes - warningMinute5;
        } else if(remainingTime.in_seconds > zoom_buffer_seconds) {
            return minutes - warningMinute;
        }
        return minutes
    }


    return (
        remainingTime?.date && checkShowTimer() ?
       <span className={`${getMinutes(remainingTime?.date?.getMinutes()) <= warningMinute5 ? 'color-danger' : ''} ${className}`}>
           {getMinutes(remainingTime?.date?.getMinutes())}:{("0" + remainingTime?.date?.getSeconds()).slice(-2)}
       </span> : <></>
    );
}

export default CountdownZoom;
