import Base from './base';

class User extends Base {

    getReviews(user_uuid, offset = 1, limit = 1, role) {
        return this.get(`/reviews/received` , {user_uuid, offset, limit, role})
    }

    addReview(data) {
        return this.post(`reviews/add`, data)
    }

    addReply(data) {
        return this.post(`reviews/reply`, data)
    }

    problemsPendingReview(data) {
        return this.get(`reviews/pending-problems`, data)
    }

}

export default new User();
