import React from 'react';
import { Link } from 'react-router-dom';


const ButtonLink = ({
    className='',
    styleType='primary',
    size='',
    to='',
    onClick,
    disabled,
    target = '',
    children
}) => {
    return (
        <Link
            target={target}
            to={to}
            onClick={onClick}
            disabled={disabled}
            className={`btn ${styleType} ${size} ${className}`}
        >
            {children}
        </Link>
    );
};

export default ButtonLink;

