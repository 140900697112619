import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/problem";
import {setActiveProblem} from "./activeProblemSlice";
import {getMeeting} from "./workspaceSlice";

const initialState = {};

const singleProblemSlice = createSlice({
    name: 'inboxSlice',
    initialState,
    reducers: {
        setSingleProblem: (state, action) => {
            state = action.payload;
            return state;
        },
        resetSingleProblem: (state) => {
            state = initialState;
            return state;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
    }
});

export const {
    setSingleProblem,
    resetSingleProblem,
    actionError
}
    = singleProblemSlice.actions;

export const getProblem = (data) => async dispatch => {
    try {
        const payload = await Api.singleProblem(data);
        dispatch(setSingleProblem(payload.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const acceptProblem = (data) => async dispatch => {
    try {
        const payload = await Api.acceptProblem(data);

        await dispatch(setSingleProblem(payload.data.problem));
        await dispatch(setActiveProblem(payload.data.problem));
        dispatch(getMeeting({problem_uuid: payload.data.problem.problem_uuid}));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const closeProblem = (data) => async dispatch => {
    try {
        await Api.closeProblem(data);
        await dispatch(resetSingleProblem());
    } catch (e) {
        dispatch(actionError(e))
    }
};

export default singleProblemSlice.reducer;
