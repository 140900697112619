import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import Header from "layout/header/signedIn/Header";
import Footer from "layout/footer/Footer";
import Account from "component/account/Account";
import PublicProfile from "component/publicProfile/PublicProfile";
import "react-responsive-modal/styles.css";
// import {useParams} from "react-router-dom";
import {useHistory, useLocation} from "react-router";
import {hashToId, scrollToId} from "../../vendors/utils";

const AccountProfile = () => {
    const {state, hash} = useLocation()
    const {push} = useHistory();
    const {user} = useSelector(state => state.auth);
    const [showTab, setShowTab] = useState(state?.tab || 1);
    const [activeHash, setActiveHash] = useState();

    const hashChangeListener = () => {
        if (hash === '#payment-section' && showTab === 2) {
            setShowTab(1);
        }
    };

    const renderListener = () => {
        if (hash) {
            setActiveHash(hash)
            if (activeHash === hash) {
                setTimeout(() => scrollToId(hashToId(hash)), 50)
            }
        }
    };

    useEffect(renderListener);

    useEffect(hashChangeListener, [hash]);

    const changeTab = (number) => {
        if (hash) {
            push('/account-profile')
        }
        setShowTab(number)
    };

    return (
        <div>
            <Header/>
            <main className="main-wrapper">
                {/* Account content*/}
                <section className="bg-color-secondary account-page">
                    <div className="container overflow-hidden">
                        <div className="bar-wrapper text-left">
                            <div className="bar-tabs">
                                <button onClick={() => changeTab(1)}
                                        className={`btn-tab ${showTab === 1 ? 'active' : ''}`}>My account
                                </button>
                                <button onClick={() => changeTab(2)}
                                        className={`btn-tab ${showTab === 2 ? 'active' : ''}`}>My public profile
                                </button>
                            </div>

                            {
                                showTab === 1 && <Account hash={hash}/>
                            }

                            {
                                showTab === 2 && <PublicProfile user_uuid={user.user_uuid} hash={hash}/>
                            }

                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </div>
    );
};

export default AccountProfile;
