import React from 'react';
import {Button, Textarea} from "../../elements";
import { addReviewSchema as validationSchema } from "vendors/services/yup-schemas/problemSchemas";
import {useFormik} from "formik";
import {changeCurrentStep, addReview} from "../../vendors/redux/slices/addReviewSlice";
import {useDispatch, useSelector} from "react-redux";

const ReviewForm = () => {
    const dispatch= useDispatch();
    const {user_uuid} = useSelector(state => state.auth.user);
    const { problem: {problem_uuid, student, tutor}, reviewData: {rating}} = useSelector(state => state.addReview);

    const initialValues = {
        review: ''
    }

    console.log('from', user_uuid)
    console.log('to', user_uuid === student ? tutor : student)
    const onSubmit = (values) => {
        dispatch(addReview({
            from: user_uuid,
            to: user_uuid === student ? tutor : student,
            review: values.review,
            rating: rating,
            problem_uuid: problem_uuid
        }));
        dispatch(changeCurrentStep(3));
    }
    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, handleFocus, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-input">
                <Textarea
                    name="review"
                    placeholder={'Leave a short review with information that will help future students engage with the coach. For example, you can share about your interactions with your coach, their level of expertise and coaching qualities.'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    value={values.review}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched.review}
                    handleFocus={handleFocus}
                    errorMessage={errors.review}
                    rows="7"
                />
            </div>
            <Button
                type='submit'
                className='small medium-text'
                disabled={!(isValid && dirty)}
            >
                Submit my review
            </Button>
        </form>
    );
};

export default ReviewForm;
