import Base from "./base";

class Static extends Base {
  subjects() {
    return this.get("static/subjects");
  }

  levels() {
    return this.get("static/levels");
  }

  languages() {
    return this.get("static/languages");
  }

  roles() {
    return this.get("static/roles");
  }

  prices() {
    return this.get("static/prices");
  }
  topics() {
    return this.get("static/contact-us-topics");
  }

  problemStatuses() {
    return this.get("static/problem-statuses");
  }

  notificationTypes() {
    return this.get("static/notification-types");
  }

  problemEvents() {
    return this.get("static/problem-events");
  }
}

export default new Static();
