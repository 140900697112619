import React from 'react';
import {SignUpThird} from "../../component/auth/signUpForm";
import AuthEnhancer from "../auth/AuthEnhancer";

const RegisterCoach = () => {
    return (
        <AuthEnhancer>
            <h3 className="text-center form-title mt-10">What subjects would you like to teach?</h3>
            <SignUpThird registerCoach/>
        </AuthEnhancer>
    );
};

export default RegisterCoach;
