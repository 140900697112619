import {configureStore} from "@reduxjs/toolkit";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "react-router-redux";
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

const {REACT_APP_ENV} = process.env;



const history = createBrowserHistory();
const middleware = [thunk,routerMiddleware(history)];


const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: REACT_APP_ENV !== 'production'
});

export  {store, history};
