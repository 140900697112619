import * as moment from "moment";
import {registerInterval, clearInWindowInterval} from "../utils";

const TimerTypes = {
    new_problem: 'timer_new_problem',
    matched_problem: 'timer_matched_problem',
    zoom_meeting: 'zoom_meeting',
    timer_30_sec: 'timer_30_sec'
}

class Timer {
    name;
    interval;
    timeOut = 1000;
    update = () =>{};
    count;

    constructor(props) {
        this.props = props;
        this.name = props.name;
        this.update = props.update || this.update;
        this.timeOut = props.timeOut || this.timeOut;
    }

    start() {
        this.interval = setInterval(() => this.localUpdate(), this.timeOut);

        registerInterval(this.name,this.interval);
        localStorage.setItem(this.name, 'true');
        return this;
    }

    clear(){
        localStorage.removeItem(this.name);
        clearInterval(this.interval);
        clearInWindowInterval(this.name)
    }

    localUpdate() {
        this.update(this);
    }
}

class TimerCount {
    name;
    interval;
    timeOut = 1000;
    update = () =>{};
    count;

    constructor(props) {
        this.props = props;
        this.name = props.name;
        this.update = props.update || this.update;
        this.timeOut = props.timeOut || this.timeOut;
        this.setCount()
    }

    start() {
        this.interval = setInterval(() => {
            this.localUpdate()
        }, this.timeOut);

        registerInterval(this.name,this.interval);

        return this;
    }

    clear(){
        clearInterval(this.interval);
        clearInWindowInterval(this.name)
        localStorage.removeItem(this.name);
    }

    localUpdate() {
        this.checkCount();
        this.update(this);
    }

    checkCount() {
        let count = localStorage.getItem(this.name);
        if (count) {
            count = parseInt(count) - 1;
            localStorage.setItem(this.name, count);
            this.count = count;
        }
    }

    setCount() {
        let count = localStorage.getItem(this.name);
        if (!count) {
            localStorage.setItem(this.name, this.props.count);
            this.count = this.props.count;
        } else {
            this.count = count;
        }
    }
}

const problemRemaining = (count_down_end) => {
    if (count_down_end) {
        let in_seconds = 0;
        const currentTime = moment.utc(moment()).valueOf();
        const end = moment.utc(count_down_end).valueOf();

        const distanceToDate = end - currentTime;

        const seconds_from_end_to_currentTime = distanceToDate / 1000;

        const minutes = Math.floor(
            (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
        );
        let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

        // remaining time in seconds
        in_seconds = (minutes*60) + seconds

        // For visual appeal, add a zero to each number that's only one digit
        const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

        if (numbersToAddZeroTo.includes(seconds)) {
            seconds = `0${seconds}`;
        }

        const updated = new Date(end);
        updated.setMinutes(minutes);
        updated.setSeconds(seconds);

        return seconds_from_end_to_currentTime <= 0 ? null : {date: updated,in_seconds};
    }
}

export {
    Timer,
    TimerCount,
    problemRemaining,
    TimerTypes
};

