import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useFormik } from "formik";
import { Input, Button, Textarea } from "elements";
import { contactSchema as validationSchema } from "vendors/services/yup-schemas/contactSchema";
import { getTopics } from "./../../vendors/redux/slices/staticSlice";
import { sendMessage } from "./../../vendors/redux/slices/supportSlice";

const ContactForm = () => {
  const dispatch = useDispatch();
  const { topics } = useSelector((state) => state.staticData);

  useEffect(() => {
    dispatch(getTopics());
  }, []);

  const initialValues = {
    name: "",
    email: "",
    topic: "",
    message: "",
  };

  const onReset = () => {
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      const message = {
        name: value.name,
        email: value.email,
        topic: value.topic.name,
        topic_id: value.topic.id,
        message: value.message,
      };
      dispatch(sendMessage(message));
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    handleFocus,
    setFieldTouched,
    isValid,
    dirty,
  } = formik;

  return (
    <form onSubmit={handleSubmit} className="pt-10">
      <ul className="li-space pb-7">
        <li className="label-inline">
          <label>Name*</label>
          <Input
            name="name"
            className="mb-0 max-width-330"
            value={values.name}
            touched={touched.name}
            errorMessage={errors.name}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleFocus={handleFocus}
          />
        </li>
        <li className="label-inline">
          <label>Email address*</label>
          <Input
            name="email"
            className="mb-0 max-width-330"
            type="email"
            value={values.email}
            touched={touched.email}
            errorMessage={errors.email}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleFocus={handleFocus}
          />
        </li>
        <li className="label-inline">
          <label>Topic*</label>
          <div
            className={`select-box w-100  ${
              errors.topic && touched.topic ? "has-error form-input mb-0 " : ""
            }`}
          >
            <Select
              name="topic"
              className="max-width-330 "
              classNamePrefix="select"
              options={topics}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={values.topic}
              touched={touched.topic}
              onChange={(selectedOption) => {
                handleChange({
                  target: { name: "topic", value: selectedOption },
                });
              }}
              onBlur={() => {
                handleBlur({ target: { name: "topic" } });
              }}
            />
            {errors.topic && touched.topic && (
              <p className="error-message">{errors.topic.name}</p>
            )}
          </div>
        </li>
        <li className="label-inline">
          <label>Message*</label>
          <div className="form-input mb-0">
            <Textarea
              name="message"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              value={values.message}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched.message}
              handleFocus={handleFocus}
              errorMessage={errors.message}
              maxLength="3500"
            />
          </div>
        </li>
      </ul>
      <div className="form-button-group">
        <Button
          className="text-underline"
          styleType="tertiary"
          onClick={onReset}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="w-126 small"
          disabled={!(isValid && dirty)}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
