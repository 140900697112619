import React, {useEffect} from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordSchema as validationSchema } from 'vendors/services/yup-schemas/authSchemas'
import { Input, Button } from 'elements'
import {forgetPassword} from "vendors/redux/slices/authSlice";
import {resetForgetPassword} from "../../../vendors/redux/slices/authSlice";
import {useHistory} from "react-router";


const ForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const {forgetPassword: forgetPasswordStore} = useSelector(state => state.auth);
    const initialValues = {
        email: '',
    };

    useEffect(()=>{
        if (forgetPasswordStore.success) {
            dispatch(resetForgetPassword());
            push('/check-email')
        }
        if (forgetPasswordStore.success === false) {
            setFieldTouched('email')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[forgetPasswordStore.success]);


    const onEmailChange = (e) => {
        if (forgetPasswordStore.success === false) {
            dispatch(resetForgetPassword());
        }

        handleChange(e)
    };


    const onSubmit = (values) => {
        dispatch(forgetPassword(values))
    };

    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, setFieldTouched, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema: ()=>validationSchema(forgetPasswordStore),
    });

    return (
        <form onSubmit={handleSubmit}>
            <Input
                type='email'
                name='email'
                label='Enter your email address'
                errorMessage={errors.email}
                value={values.email}
                touched={touched.email}
                handleBlur={handleBlur}
                handleChange={onEmailChange}
            />

            <Button
                type="submit"
                className="w-100"
                disabled={!(isValid && dirty)}
            >
                Send reset email
            </Button>
        </form>
    );
};


export default ForgotPasswordForm;
