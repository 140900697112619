import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/problem";


const initialState = {
    inboxList: [],

    pending: null,
    success: null
};

const inboxSlice = createSlice({
    name: 'inboxSlice',
    initialState,
    reducers: {
        setInboxList: (state, action) => {
            state.inboxList = action.payload;
            return state;
        },
        changeCurrentStep: (state, action) => {
            state.currentStep = action.payload;
            return state;
        },
        setPending: (state, action) => {
            state.pending = action.payload
            return state;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
    }
});

export const {
    setInboxList,
    setPending,
    actionError
}
    = inboxSlice.actions;

export const getInbox = (data) => async (dispatch, getState) => {
    try {
        dispatch(setPending(true));

        const {user_uuid} = getState().auth.user;
        const params = data || {user_uuid};
        const payload = await Api.inbox(params);

        await dispatch(setInboxList(payload.data));
        dispatch(setPending(false));
    } catch (e) {
        dispatch(setPending(false));
        dispatch(actionError(e))
    }
};

export default inboxSlice.reducer;
