const companyList = () => {
    return [
        {
            linkText: 'How it works',
            path: '/how-it-works'
        },
        {
            linkText: 'Pricing',
            path: '/pricing'
        },
        {
            linkText: 'Become a coach',
            path: '/become-a-coach'
        },
        {
            linkText: 'About us',
            path: '/about-us'
        },
        {
            linkText: 'Privacy Policy',
            path: '/privacy'
        },
        // {
        //     linkText: 'Terms of Service',
        //     path: '/'
        // },
    ]
};

const supportList = () => {
    return [
        // {
        //     linkText: 'Help & Support',
        //     path: '/'
        // },
        {
            linkText: 'Trust & Safety',
            path: '/trust-and-safety'
        },
        {
            linkText: 'Contact us',
            path: '/contact-us'
        },
    ]
};

export {companyList, supportList}
