import React, {useEffect} from 'react';
import { useLottie } from 'lottie-react';
import spinner from './spinner.json';

const Spinner = () => {

    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden')
        }
    },[]);

    const options = {
        animationData: spinner,
        loop: true,
        autoplay: true,
        style: {
            width: '100px',
            height: '100px'
        },

    };

    const { View } = useLottie(options);

    return (
        <div className='spinner-wrapper'>{View}</div>
    )
};

export default Spinner;
