import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useDigitInput from 'react-digit-input';
import {
    twilioVerifyCode,
    resetVerifyCode,
    changeCurrentStep
} from "../../../vendors/redux/slices/authSlice";
import { Button } from 'elements'


const SignUpSecond = () => {
    const dispatch = useDispatch();
    const [value, onChange] = useState('');
    const {data} = useSelector(state=>state.auth.signUp);
    const {phoneCodeVerify} = useSelector(state=>state.auth.signUp);
    const [showError, setShowError] = useState(false);

    const digits = useDigitInput({
        acceptedCharacters: /^[0-9]$/,
        length: 4,
        value,
        onChange,
    });


    useEffect(()=>{
        if (phoneCodeVerify.valid) {
            dispatch(changeCurrentStep(3));
            dispatch(resetVerifyCode())
        }
    },[phoneCodeVerify.valid]);

    const onSubmit = async () => {
        await dispatch(twilioVerifyCode({
            phone: data.phone,
            code: value
        }));
        setShowError(true)
    };

    const goBack = () => {
        dispatch(changeCurrentStep(1));
    };
    const checkValid = () => {
        const digitsValues = [];
        digits.map(x => x.value && digitsValues.push(x.value));
        return (digitsValues.length < 4)
    };

    return (
        <form>
            <div className='segmented-input-content'>
                <ul className='segmented-input-list'>
                    <li className='segmented-input'>
                        <input inputMode="decimal" autoFocus {...digits[0]} />
                    </li>
                    <li className='segmented-input'>
                        <input inputMode="decimal" {...digits[1]}/>
                    </li>
                    <li className='segmented-input'>
                        <input inputMode="decimal" {...digits[2]}/>
                    </li>
                    <li className='segmented-input'>
                        <input inputMode="decimal" {...digits[3]}/>
                    </li>
                </ul>
                {
                    !phoneCodeVerify.valid && showError && <p className='error-text'>{phoneCodeVerify.ui_message}</p>
                }
            </div>

            <Button
                className="w-100"
                onClick={onSubmit}
                disabled={checkValid()}
            >
                Next
            </Button>
            <p className='pt-10 text-xs color-secondary-2 text-center'>Need to change your number? <Button styleType='no-style underline' onClick={goBack}> Go back to step 1</Button></p>
        </form>
    );
};

export default SignUpSecond;

