import { combineReducers } from "@reduxjs/toolkit";
import { routerReducer as route } from "react-router-redux";
import auth from "./slices/authSlice";
import staticData from "./slices/staticSlice";
import searchTutor from "./slices/searchTutorSlice";
import selectSubject from "./slices/selectSubjectSlice";
import account from "./slices/accountSlice";
import profile from "./slices/publicProfileSlice";
import guestProfile from "./slices/guestProfileSlice";
import reviews from "./slices/reviewSlice";
import layout from "./slices/layoutSlice";
import userLangForm from "./slices/userLanguages";
import editPracticeArea from "./slices/editPracticeAreaSlice";
import postProblem from "./slices/postProblemSlice";
import activeProblem from "./slices/activeProblemSlice";
import singleProblem from "./slices/singleProblemSlice";
import inbox from "./slices/inboxSlice";
import bells from "./slices/bellsSlice";
import supportData from "./slices/supportSlice";
import workspace from "./slices/workspaceSlice";
import addReview from "./slices/addReviewSlice";
import banners from "./slices/bannerSlice";
import payment from "./slices/paymentSlice";


const rootReducer = combineReducers({
  auth,
  staticData,
  supportData,
  searchTutor,
  selectSubject,
  account,
  profile,
  guestProfile,
  reviews,
  layout,
  userLangForm,
  editPracticeArea,
  postProblem,
  activeProblem,
  singleProblem,
  workspace,
  addReview,
  banners,
  inbox,
  bells,
  payment,
  route,
});

export default rootReducer;
