import {createSlice} from '@reduxjs/toolkit'
import Api from '../../services/api/user'

const initialState = {
    profile: {},
    averageRating: null,
    educations: [],
    practiceAreas: [],
    userLanguages: [],
    isGuest: true
};

const guestProfileSlice = createSlice({
    name: 'guestProfile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state = {...state, ...action.payload};
            return state;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setProfile,
    actionError
}
    = guestProfileSlice.actions;

export const getGuestProfile= (user_uuid) => async dispatch => {
    try {
        const payload = await Api.getPublicProfile(user_uuid);
        dispatch(setProfile(payload.data));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export default guestProfileSlice.reducer;
