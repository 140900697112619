import { createSlice } from "@reduxjs/toolkit";
import Api from "../../services/api/workspace";

const initialState = {
    meeting: {},

    pending: null,
    success: null,
    error: null,
};

const workspaceSlice = createSlice({
    name: "workspace",
    initialState,
    reducers: {
        setMeeting: (state, action) => {
            state.meeting = action.payload;
            return state;
        },
        resetMeeting: (state, action) => {
            state.meeting = {};
            return state;
        },
        setPending: (state, action) => {
            state.pending = action.payload
            return state;
        },
        reset: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
    },
});

export const {
    setMeeting,
    resetMeeting,
    setPending,
    reset,
    actionError,
} = workspaceSlice.actions;


export const createMeeting = (data) => async (dispatch) => {
    try {
        const res = await Api.createMeeting(data);
        dispatch(setMeeting(res?.data.meeting || {}));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const getMeeting = (data) => async (dispatch) => {
    try {
        dispatch(setPending(true));
        const res = await Api.getMeeting(data);

        await dispatch(setMeeting(res?.data || {}));
        dispatch(setPending(false));
    } catch (e) {
        dispatch(setPending(false));
        dispatch(actionError(e));
    }
};


export const userReady = (data) => async (dispatch) => {
    try {
        const res = await Api.userReady(data);
        await dispatch(setMeeting(res?.data.meeting || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const userJoin = (data) => async (dispatch) => {
    try {
        const res = await Api.userJoin(data);
        await dispatch(setMeeting(res?.data.meeting || []));
    } catch (e) {
        dispatch(actionError(e));
    }
};

export const userLeave = (data) => async (dispatch) => {
    try {
        const res = await Api.userLeave(data);
        await dispatch(setMeeting(res?.data.meeting || []));
    } catch (e) {
        dispatch(actionError(e));
    }
}

export const endMeeting = (data) => async (dispatch) => {
    try {
        const res = await Api.endMeeting(data);
        await dispatch(setMeeting(res?.data.meeting || {}));
    } catch (e) {
        dispatch(actionError(e));
    }
};


export default workspaceSlice.reducer;
