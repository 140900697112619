import axios from "axios";
import {store} from "../../redux/store";
import {logOut} from 'vendors/redux/slices/authSlice';

class BaseApi {
    axios;

    constructor() {
        const {REACT_APP_API_URL} = process.env;
        const config = {
            baseURL: REACT_APP_API_URL,
            timeout: 60000,
        };
        this.axios = axios.create(config);

        // before the request is sent to the server, add jwt to the Authorization header
        this.axios.interceptors.request.use((config) => {
            const token = localStorage.getItem('token');
            if (token) {
                /**
                 x-jwt custom header for hack safari and nginx basic authorization on staging
                 */
                config.headers['x-jwt'] = `Bearer ${token.replace('%20', ' ')}`;
            }
            return config;
        });
        this.axios.interceptors.response.use(response => {
            return response;
        }, error => {

            this.signOut(error);

            return Promise.reject(error.response ? {
                code: error.response.status,
                data: error.response.data
            } : {message: error});
        });
    }

    static async getPublic(url) {
        let requestData = {
            method: 'GET',
            url
        };
        requestData.responseType = "blob";
        requestData.headers = {
            "Content-Type": "application/octet-stream",
            "Access-Control-Allow-Origin": "*"
        }
        return axios(requestData);
    }

    post(url, data = {}, headers = {}) {
        return this.req('post', url, data, headers)
    }

    delete(url, data = {}) {
        return this.req('delete', url, {data})
    }

    patch(url, data = {}) {
        return this.req('patch', url, data)
    }

    put(url, data = {}) {
        return this.req('put', url, data)
    }

    get(url, data = {}) {
        return this.req('get', url, {params: {...data}});
    }

    async req(method, url, data = {}, headers = {}) {
        if (!method)
            throw new Error('Method is required');
        if (!url)
            throw new Error('URL is required');
        const response = await this.axios[method](url, data, headers);
        if (response.status && response.status === 'error') {
            throw response
        }
        return response || false
    }

    signOut(error) {
        if (error.response.status === 401 || error.response.status === 404) {
            const token = localStorage.getItem('token');
            token && store.dispatch(logOut())
        }
    }
}

export default BaseApi
