import React from 'react';
import { LoginForm } from "component/auth";
import AuthEnhancer from '../AuthEnhancer'
import {Link} from "react-router-dom";

const Login = () => {
    return (
        <AuthEnhancer>
            <h3 className="text-center form-title">Sign in</h3>
            <p className="text-center text-normal form-subtitle">Not a member yet? <Link to={'/sign-up'} className="link-primary">Join now</Link></p>
            <LoginForm />
        </AuthEnhancer>
    );
};

export default Login;
