import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import * as validateUuid from "uuid-validate";
import {getProblem} from "../../vendors/redux/slices/singleProblemSlice";
import Header from "../../layout/header/signedIn/Header";
import FooterEnhancer from "../../layout/footer/FooterEnhancer";
import ProblemExpire from "../countDown/elements/ProblemExpire";
import ProblemDetailsInner from "./ProblemDetailsInner";
import {Spinner} from "../../elements";
import {clearInWindowInterval} from "../../vendors/utils";
import {TimerTypes} from "../../vendors/services/timers";


const ProblemDetails = () => {
    const dispatch = useDispatch();
    const {problem_uuid} = useParams();
    const {push} = useHistory()

    const {status, tutor, student, problem_uuid: single_problem_uuid} = useSelector(state => state.singleProblem);
    const {problem: activeProblem} = useSelector(state => state.activeProblem);
    const {user: {user_uuid}} = useSelector(state => state.auth);
    const {inboxList = [], pending: inboxPending} = useSelector(state => state.inbox);
    const {problemStatuses, roles} = useSelector(state => state.staticData);

    const [allowed, setAllowed] = useState(true);

    useEffect(() => {
        if (problem_uuid) {

            if(!validateUuid(problem_uuid)) push('/');

            clearInWindowInterval(TimerTypes.new_problem);
            dispatch(getProblem({problem_uuid}))
        }
    }, [problem_uuid]);

    useEffect(() => {
        if (single_problem_uuid && inboxPending === false) {
            if (!notAllowed() && student !== user_uuid) return push('/');
        }
    }, [single_problem_uuid,inboxPending]);

    const unavailableFo3rd = () => {
        return (tutor !== user_uuid && student !== user_uuid && status > problemStatuses.new)
            // || activeProblem?.status;
    }

    const unavailableFoSecondProblem = () => {
        return activeProblem?.problem_uuid && activeProblem?.problem_uuid !== problem_uuid;
    }

    const notAllowed = () => {
        const p = inboxList.find(p=>p.problem_uuid === single_problem_uuid);
        const allow = !!p;

        setAllowed(allow);
        return allow;
    }


    return (
        <>
            <Header />
            <div className='enhancer-page with-padding'>
                <div className="container">
                    {inboxPending === false ?
                        unavailableFo3rd() || unavailableFoSecondProblem() ?
                            <div className={'enhancer-inner large'}>
                                <ProblemExpire/>
                            </div> :
                            <ProblemDetailsInner allowed={allowed}/>
                        : <Spinner/>
                    }

                    <FooterEnhancer />
                </div>
            </div>
        </>
    );
}

export default ProblemDetails;
