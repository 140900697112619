import React from 'react';
import AuthEnhancer from '../AuthEnhancer'
import { ButtonLink } from 'elements'

const ResetPasswordSuccess = () => {
    return (
        <AuthEnhancer>
            <h3 className="text-center form-title">Your password has been changed successfully</h3>
            <ButtonLink to={'/sign-in'} className='w-100'>Continue to sign in</ButtonLink>
        </AuthEnhancer>
    );
};

export default ResetPasswordSuccess;
