import React from 'react';
import {useSelector} from "react-redux";
import HeaderGuest from 'layout/header/guest/Header';
import HeaderSignedIn from 'layout/header/signedIn/Header';
import Footer from "../../layout/footer/Footer";
import {Button} from "elements";
import HowItWorksCarousel from "../static/home/how-it-works/HowItWorksCarousel";
import FAQ from "../../component/FAQ/FAQ";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";

const path = {
    pathname: '/',
    hash: "#subjects",
}

const BecomeCoach = () => {
    const {loggedIn} = useSelector(state => state.auth);
    const {push} = useHistory()
    const {role, was_tutor} = useSelector(state => state.auth.user);
    const {roles} = useSelector(state => state.staticData);

    const onBecomeCoach = () => {
        if(!loggedIn) {
            push({
                pathname: '/sign-up',
                state: {
                    role: roles?.TUTOR
                }
            })
        }
        if(loggedIn && roles?.STUDENT) {
            push('/register-coach')
        }
    }

    return (
        <>
            {
                loggedIn ? <HeaderSignedIn /> : <HeaderGuest />
            }
            <main className='main-wrapper with-padding coach-page'>
                <section className='text-center'>
                    <div className='container'>
                        <h1 className='section-title pb-5'>Get paid for coaching students!</h1>
                        <div className='section-content text-lg'>
                            <p className='pb-3'>We believe that students value the precise, personalised learning that comes from reaching out when help is needed the most.</p>
                            <p className='pb-10'>If you have a good grasp of the <Link to={path}><span className='underline'>subjects we support</span></Link>, and you want to be in full control of your earnings and schedule, join us and become a veepow coach.</p>
                            {
                                role !== roles?.TUTOR && !was_tutor  && <Button onClick={onBecomeCoach} styleType='small primary'>Become a coach</Button>
                            }
                        </div>
                        <div className='coach-banner-bg' />
                    </div>
                </section>
                {/*how-works-section*/}
                <section className='how-works-section'>
                    <div className="container">
                        <div className="how-works-content">
                            <h4>How it works</h4>
                            <HowItWorksCarousel  isCoach/>
                        </div>
                    </div>
                </section>
                <section className='text-center coach-details'>
                    <div className="container">
                        <div className="flex justify-between pb-10">
                            <div className='coach-details-item'>
                                <div className='img'/>
                                <h4>Help others</h4>
                                <p className='pb-5'>As more and more parents are working from home, schools are increasingly implementing a home based learning system.
                                                    The pressure to deliver at work and coach their child has never been bigger.</p>
                                <p>By signing up as a coach on Veepow you will be helping to fulfil this unmet need.</p>
                            </div>
                            <div className='coach-details-item'>
                                <div className='img'/>
                                <h4>Earn extra income</h4>
                                <p className='pb-5'>According to a study, Singaporeans spend twice the global average on children’s local education.*</p>
                                <p>You worked hard to accumulate your knowledge, use it wisely to build an extra revenue stream on your terms.</p>
                            </div>
                            <div className='coach-details-item'>
                                <div className='img'/>
                                <h4>Grow your abilities</h4>
                                <p>It’s well known that teaching others helps you master your own subject knowledge.</p>
                            </div>
                        </div>
                        {
                           role !== roles?.TUTOR && !was_tutor  && <Button onClick={onBecomeCoach} styleType='small primary'>Become a coach</Button>
                        }
                    </div>
                </section>
                <FAQ/>

                <a target="_blank" className="external-link" href="https://www.todayonline.com/singapore/singaporeans-spend-twice-global-average-childrens-local-education-hsbc">*https://www.todayonline.com/singapore/singaporeans-spend-twice-global-average-childrens-local-education-hsbc</a>
            </main>
            <Footer/>
        </>
    );
};

export default BecomeCoach;
