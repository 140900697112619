import * as Yup from "yup";
import * as cardValidate  from 'card-validator';

import errorMessages from "./errorMessages.js";
import {topUpField} from './topUpSchemas';
const validationMessages = errorMessages("validation");


export const creditCardSchema = ({topUp}) => {
    const schema = {
        card: Yup.string()
            .required(validationMessages.required)
            .test('test-number',
                validationMessages.card,
                value => cardValidate.number(value).isValid),

        card_exp: Yup.string()
            .required(validationMessages.required)
            .test('test card exp',
                validationMessages.cardExp,
                value => cardValidate.expirationDate(value).isValid),

        cvc: Yup.string()
            .required(validationMessages.required)
            .min(3,validationMessages.cvc),

        postal_code: Yup.string()
            .required(validationMessages.required)
            .test('test postal code',
                validationMessages.postalCode,
                value => cardValidate.postalCode(value,{minLength: 6}).isValid)

    };

    if(topUp) {
        Object.assign(schema,topUpField)
    }

    return Yup.object().shape(schema);
};
