import React, {useEffect} from "react";
import {useDispatch} from "react-redux"
import AboutInfo from "./AboutInfo";
import AreaOfExpertiseEdit from "./AreaOfExpertiseEdit";
import EducationsEdit from "./EducationsEdit";
import Reviews from "../reviews/Reviews";
import {getPublicProfile} from "vendors/redux/slices/publicProfileSlice";


const PublicProfile = (props) => {
    const dispatch = useDispatch();
    const {push} = useDispatch();
    const {user_uuid} =  props;

    useEffect(() => {
        dispatch(getPublicProfile(user_uuid));

    }, []);

     return (
        <div className='tab-content'>
            <AboutInfo/>
            <EducationsEdit/>
            <AreaOfExpertiseEdit hash={props.hash}/>
            <Reviews
                user_uuid={user_uuid}
            />
        </div>
    )
};

export default PublicProfile;
