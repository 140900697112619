import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-responsive-modal";
import {useFormik} from "formik";
import * as cardValidate  from 'card-validator';
import {addCard, editCard, setPending, resetError} from "vendors/redux/slices/paymentSlice";
import {getAccount} from "vendors/redux/slices/accountSlice";
import {getUserInfo} from "vendors/redux/slices/authSlice";
import {creditCardSchema} from "vendors/services/yup-schemas/creditCardSchema";
import {Button, InputMasked} from "elements";
import errorMessages from "../../../vendors/services/yup-schemas/errorMessages";
import {modifyMonth} from "../../../vendors/utils";

// card icons
import holder from "../../../assets/img/card/card-p-holder.svg";
import amex from "../../../assets/img/card/amex.svg";
import diners from "../../../assets/img/card/diners.svg";
import discover from "../../../assets/img/card/discover.svg";
import unionpay from "../../../assets/img/card/unionpay.svg";
import visa from "../../../assets/img/card/visa.svg";
import mastercard from "../../../assets/img/card/mastercard.svg";
import jcb from "../../../assets/img/card/jcb.svg";
import maestro from "../../../assets/img/card/maestro.svg";
import Amount from "../elements/Amount";
import CardDeletionModal from "./CardDeletionModal";

const cards = {
    discover,
    unionpay,
    visa,
    mastercard,
    jcb,
    maestro,
    "unknown": holder,
    "american-express": amex,
    "diners-club": diners
};

const { wentWrong } = errorMessages ('info');


const CreditCardForm = ({open, onCloseModal, topUp}) => {
    const dispatch = useDispatch();

    const {paymentInfo = {}} = useSelector(state => state.account);
    const {cardForm: {pending, error}} = useSelector(state => state.payment);
    const {method = {}} = paymentInfo;
    const [deleteCreditCardOpened, setDeleteCreditCardOpened] = useState(false);

    const initialValues = {
        card_exp: method?.card?.exp_month ? `${modifyMonth(method?.card?.exp_month)}/${method?.card?.exp_year?.toString().slice(-2)}` : '',
        postal_code: method?.billing_details?.address?.postal_code
    };

    const closeOnSuccess = () => {
        if(pending === false && !error) {
            dispatch(getUserInfo());
            dispatch(getAccount());
            onCloseModal()
        }
        if(pending === false) {
            setSubmitting(false);
        }
    }

    const resetState = () => {
        resetForm({});
        dispatch(setPending(null));
        dispatch(resetError())
    }

    const init = () => {
       return resetState
    }

    useEffect(init,[]);

    useEffect(closeOnSuccess,[pending]);

    const onSubmit = (values) => {
        if (topUp && values.amount) {
            values.amount = values.amount.replace('S$', '');
        }
        dispatch(addCard(values,{update: topUp}));
    };

    const onSubmitEdit = () => {
         dispatch(editCard(values));
    };

    const _handleChange = (e) => {
        handleChange(e);

        if (error) {
            dispatch(resetError())
        }
    }

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        handleFocus,
        resetForm,
        isValid,
        dirty,
        isSubmitting,
        setSubmitting
    } = useFormik({
        initialValues,
        onSubmit: paymentInfo?.method ? onSubmitEdit : onSubmit,
        validationSchema: ()=>creditCardSchema({topUp})
    });

    const getNiceType = (val) => {
        if (!val) return cards["unknown"];
        return cards[cardValidate.number(values.card)?.card?.type?.toLowerCase().replace(" ", "")] || cards["unknown"]
    }

    const openDeleteModal = () => {
        resetForm({})
        setDeleteCreditCardOpened(!deleteCreditCardOpened);
    }


    return (
        <>
            {
                open && (
                  <Modal
                    open={open}
                    onClose={onCloseModal}
                    center
                  >
                      {
                          deleteCreditCardOpened ? (
                            <CardDeletionModal open={true} onClose={openDeleteModal}/>
                          ): (
                            <div className="modal-body">
                                <h3 className="modal-title">{paymentInfo?.method ? 'Edit' : 'Add'} Credit card</h3>
                                <form onSubmit={handleSubmit}>
                                    <ul className="li-space">
                                        <li className="label-inline">
                                            <label>Card Number*</label>
                                            <InputMasked
                                              flag={getNiceType(values.card)}
                                              name='card'
                                              type="text"
                                              className='mb-0 w-100 form-input'
                                              errorMessage={errors.card}
                                              value={values.card}
                                              touched={touched.card}
                                              handleBlur={(e)=>setTimeout(()=>handleBlur(e),200)}
                                              handleChange={_handleChange}
                                              placeholder={'0000 0000 0000 0000'}
                                              mask="9999 9999 9999 9999"
                                              maskChar=""/>
                                        </li>
                                        <li className="label-inline">
                                            <label>Card Expiration*</label>
                                            <InputMasked
                                              name='card_exp'
                                              value={values.card_exp}
                                              errorMessage={errors.card_exp}
                                              touched={touched.card_exp}
                                              className='mb-0 w-100'
                                              handleBlur={handleBlur}
                                              handleChange={_handleChange}
                                              handleFocus={handleFocus}
                                              placeholder={'MM/YY'}
                                              mask="99/99"
                                              maskChar=""/>
                                        </li>
                                        <li className="label-inline">
                                            <label>CVC*</label>
                                            <InputMasked
                                              name='cvc'
                                              value={values.cvc}
                                              errorMessage={errors.cvc}
                                              touched={touched.cvc}
                                              className='mb-0 w-100'
                                              handleBlur={handleBlur}
                                              handleChange={_handleChange}
                                              handleFocus={handleFocus}
                                              placeholder={''}
                                              mask="9999"
                                              maskChar=""/>
                                        </li>
                                        <li className="label-inline">
                                            <label>Postal Code*</label>
                                            <InputMasked
                                              name='postal_code'
                                              value={values.postal_code}
                                              errorMessage={errors.postal_code}
                                              touched={touched.postal_code}
                                              className='mb-0 w-100'
                                              handleBlur={handleBlur}
                                              handleChange={_handleChange}
                                              handleFocus={handleFocus}
                                              placeholder={''}
                                              mask="999999"
                                              maskChar=""/>
                                        </li>
                                    </ul>
                                    {topUp && <>
                                        <h3 className="modal-title"> Top up </h3>
                                        <Amount
                                          errors={errors}
                                          values={values}
                                          touched={touched}
                                          handleBlur={handleBlur}
                                          _handleChange={_handleChange}
                                          handleFocus={handleFocus}
                                        />
                                    </>}

                                    <div className={`modal-footer ${paymentInfo?.method && 'side-aligned'}`}>
                                        {
                                            paymentInfo?.method && (
                                              <Button
                                                styleType='tertiary'
                                                className='w-166 small text-underline mr-10 btn primary-text'
                                                onClick={openDeleteModal}
                                              >Delete card</Button>
                                            )
                                        }
                                        <Button styleType='tertiary' className="text-underline ml-10" onClick={onCloseModal} >
                                            Cancel
                                        </Button>
                                        <Button
                                          className='w-126 small'
                                          type='submit'
                                          disabled={error || isSubmitting || !(isValid && dirty)}
                                        >
                                            { topUp ? 'Submit' : 'Save' }
                                        </Button>
                                        {error && (
                                          <p className='error-message-general bottom text-right w-100 pr-10'>
                                              {error?.ui_message || error?.exception?.message || wentWrong}
                                          </p>
                                        )}
                                    </div>
                                </form>
                            </div>
                          )
                      }
                  </Modal>
                )
            }
        </>
    )
};

export default CreditCardForm;
