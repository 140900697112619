import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordSchema as validationSchema } from 'vendors/services/yup-schemas/authSchemas'
import { Input, Button, Spinner } from 'elements'
import {checkResetPassToken, resetPassword, resetCheckToken} from "vendors/redux/slices/authSlice";
import {useHistory, useParams} from "react-router";
import Expired from "./Expired";




const ResetPasswordForm = () => {
    let { token } = useParams();
    const {push} = useHistory();
    const dispatch = useDispatch();
    const {checkToken} = useSelector(state => state.auth);
    const {resetPassword: resetPasswordStore} = useSelector(state => state.auth);
    const [activeView, setActiveView] = useState(0);

    useEffect(() => {
        if (token) {
            dispatch(checkResetPassToken(token))
        }
    }, []);

    useEffect(() => {
        if (resetPasswordStore.success) {
            push('/reset-password-success')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetPasswordStore.success]);

    useEffect(() => {
        if (checkToken.success !== null) {
            setActiveView(checkToken.success ? 2 : 1);
        }
        return () => {
            dispatch(resetCheckToken())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkToken.success]);

    const onSubmit = () => {
        dispatch(resetPassword({
            password: values.password,
            token: token
        }))
    };

    const initialValues = {
        password: '',
        confirmPassword: '',
    };


    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });

    const views = () => {
        switch(activeView) {
            case 1:
                return <Expired />;
            case 2:
                return(
                    <form onSubmit={handleSubmit}>
                        <Input
                            withIcon
                            type='password'
                            name='password'
                            label='Enter new password'
                            errorMessage={errors.password}
                            value={values.password}
                            touched={touched.password}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />
                        <Input
                            withIcon
                            type='password'
                            name='confirmPassword'
                            label='Retype new password'
                            errorMessage={errors.confirmPassword}
                            value={values.confirmPassword}
                            touched={touched.confirmPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                        />

                        <Button
                            type="submit"
                            className="w-100"
                            disabled={!(isValid && dirty)}
                        >
                            Save
                        </Button>
                    </form>
                );
            default:
                return <Spinner />
        }
    };

    return (
        <>
            { views() }
        </>
    );
};

export default ResetPasswordForm;

