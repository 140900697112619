import {createSlice} from '@reduxjs/toolkit'
import Api from "../../services/api/problem";
import {clearInWindowInterval} from "../../utils";
import {problemRemaining, Timer, TimerCount, TimerTypes} from "../../services/timers";
import {cloneDeep} from "lodash";

const initialState = {
    problem: {},
    wait30seconds: null,
    count30seconds: 30,
    timer: {
        timerEnd: null
    },

    getError: null,
    error: null,
    success: null,
    pending: null
};

const ActiveProblemSlice = createSlice({
    name: 'activeProblem',
    initialState,
    reducers: {
        setProblem: (state, action) => {
            state.problem = action.payload;
            return state;
        },
        start30seconds: (state) => {
            state.wait30seconds = true;
            return state;
        },
        update30seconds: (state, action) => {
            state.count30seconds = action.payload;
            return state;
        },
        reset30: (state) => {
            state.wait30seconds = null;
            state.count30seconds = 30;
            return state;
        },
        updateTimer: (state, action) => {
            state.timer = action.payload;
            return state;
        },
        setTimer: (state, action) => {
            state.timer = action.payload;
            return state;
        },
        setPending: (state, action) => {
            state.pending = action.payload
            return state;
        },
        reset: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        problemError: (state, action) => {
            state.getError = action.payload;
            return state;
        },
    }
});

export const {
    setProblem,
    start30seconds,
    update30seconds,
    reset30,
    updateTimer,
    setTimer,
    setApiStatus,
    problemError,
    reset,
    setPending,
    actionError
}
    = ActiveProblemSlice.actions;

export const extendTimer = (data) => async dispatch => {
    try {
        const payload = await Api.extendTimer(data);
        await dispatch(reset30seconds());
        dispatch(setActiveProblem(payload.data.problem));

    } catch (e) {
        dispatch(actionError(e))
    }
};

export const expireProblem = (data) => async dispatch => {
    try {
        await Api.expireProblem(data);
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const setActiveProblem = (data) => async dispatch => {
    try {
        await dispatch(setProblem(data));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const getActiveProblem = (data) => async dispatch => {
    try {
        dispatch(problemError(null));
        dispatch(setPending(true));

        const payload = await Api.getActiveProblem(data);
        if (payload.data) {
             await dispatch(setActiveProblem(payload.data))
             return dispatch(setPending(false));
        }

        await dispatch(setPending(false));
        await dispatch(resetActiveProblem());
    } catch (e) {
        dispatch(problemError(e));
        dispatch(setPending(false));
    }
};

export const updateProblem = (data) => async dispatch => {
    try {
        const payload = await Api.updateProblem(data);
        dispatch(setActiveProblem(payload.data.problem));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const resetActiveProblem = () => async dispatch => {
    try {
         await dispatch(setProblem({}));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const _start30seconds = ({count = 30}) => async (dispatch,getState) => {

    const {problem} = getState().activeProblem;

    clearInWindowInterval(TimerTypes.new_problem);
    await clearInWindowInterval(TimerTypes.timer_30_sec);

    dispatch(start30seconds());

    const timer = new TimerCount({
        name: TimerTypes.timer_30_sec,
        count,
        update: (timer) =>{
            dispatch(_update30Seconds(timer))
        }
    }).start();


    dispatch(update30seconds(timer));

    if (problem.extended) {
        dispatch(expireProblem({problem_uuid: problem.problem_uuid}));
    }
};

export const _update30Seconds = (timer) => async (dispatch,getState) =>  {
    if (!timer?.count) {
        timer.clear();

        const {problem} = getState().activeProblem;

        if (problem.status === 0){
            console.log('problem.status === 0')
            return dispatch(resetProblem());
        }
        return;
    }

    dispatch(update30seconds(cloneDeep(timer)));
};

export const reset30seconds = () => async dispatch => {
    try {
        await dispatch(reset30());
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const startProblemTimer = () => async dispatch => {
      new Timer({
        name: TimerTypes.new_problem,
        update: (e)=> {
            dispatch(updateRemaining(e))
        }
    }).start();
};

const updateRemaining = timer => async (dispatch, getState) => {
    const {problem} = getState().activeProblem;

    const remaining = problemRemaining(problem.count_down_end);

    if (!remaining) {
        timer.clear();
        dispatch(_start30seconds({}))
    }
};

const resetProblem = () => async (dispatch, getState) => {
    const {problem} = getState().activeProblem;

    await dispatch(expireProblem({problem_uuid: problem.problem_uuid}));
    await dispatch(reset());

    window.location.href = '/account-profile';
};


export default ActiveProblemSlice.reducer
