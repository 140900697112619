import React, {useEffect, useState} from 'react';
import {Button, ButtonLink, Checkbox, Radio} from "../../elements";
import Select from "react-select";
import {
    assignDisableToLevels,
    constructSelectOption,
    markAllLevelsDisable,
    filterLangs
} from "../../vendors/utils";
import {useDispatch, useSelector} from "react-redux";
import {filterLevels, resetLevel, setSubject} from "../../vendors/redux/slices/selectSubjectSlice";
import {getTutors, resetTutors} from "../../vendors/redux/slices/searchTutorSlice";
import {useHistory} from "react-router";

const FilterBar = ({openFilter}) => {
    const dispatch = useDispatch();
    const {goBack} = useHistory();
    const {loggedIn, user: {user_uuid}} = useSelector(state => state.auth);
    let {subjects, levels, languages} = useSelector(state => state.staticData);
    languages = filterLangs(languages);
    const {subject, level, filteredLevels} = useSelector(state => state.selectSubject);
    const [levelList, setLevelList] = useState([]);
    const [languageList, setLanguageList] = useState([]);

    const allLevelsDisabled = markAllLevelsDisable(levels);
    const availableLevels = filteredLevels || allLevelsDisabled;

    const changeSubject = (subject) => {
        dispatch(setSubject(subject));
        dispatch(resetLevel());
        const modified = assignDisableToLevels(subject, levels);

        dispatch(filterLevels(modified))
    };

    const onFilterLevelChange = (e) => {
        const {id} = e.target;
        const exists = levelList.find(level =>level === Number(id));

        let newList = [...levelList];
        if (exists) {
            newList = newList.filter(level => level !== Number(id))
        } else {
            newList.push(Number(id))
        }
        setLevelList(newList);
    };

    const onAllFilterLevelChange = () => {
        if(availableLevels.length === levelList.length) {
            setLevelList([]);
            dispatch(resetTutors())
        } else {
            const list = availableLevels.map(level => level.id);
            setLevelList(list)
        }
    };

    const onFilterLanguageChange = (e) => {
        let {id} = e.target;
        id = Number(id);
        const exists = languageList.find(lang =>lang === id);

        let newList = [...languageList];
        if (exists) {
            newList = newList.filter(lang => lang !== id)
        } else {
            newList.push(id)
        }

        setLanguageList(newList);
    };

    useEffect(() => {
        const list = languages.map(lang => lang.id);
        setLanguageList(list)
    }, [languages.length]);

    useEffect(() => {
        if(subject?.id) {
            window.scrollTo(0, 0);
            dispatch(getTutors({
                user_uuid,
                subject_id: subject?.id,
                level_id: levelList.length === availableLevels.length ? '' : levelList,
                language_id: languageList.length === languages.length ? '' : languageList
            }))
        }
    }, [levelList?.length, subject?.id, languageList?.length]);

    useEffect(() => {
        if(level?.id) {
            setLevelList([level.id])
        }
    }, [level?.id]);

    useEffect(() => {
        if(!level || !level.id) {
            const list = availableLevels.map(level => level.id);
            setLevelList(list)
        }
    }, [subject?.id]);

    return (
        <aside className={`filter-bar ${openFilter ? 'opened' : ''}`}>
            <div className='go-back hide-mobile'>
                <Button onClick={goBack} styleType='secondary small narrow'>Back</Button>
            </div>

            <h5>Filter coaches by</h5>
            <div className='list-group-wrapper'>
                <h6 className='list-group-title'>Subject</h6>
                <div className={'select-box w-100 pb-7'}>
                    <Select
                        name='subject'
                        className='select-sm'
                        classNamePrefix='select'
                        placeholder={false}
                        value={subject}
                        onChange={changeSubject}
                        options={constructSelectOption(subjects,{check_coming_soon: true})}
                        isSearchable={false}
                    />
                </div>
                <h6 className='list-group-title'>Subject level</h6>
                <ul className="list-group">
                    <li>
                        <Checkbox
                            name="All"
                            label="All available"
                            onChange={onAllFilterLevelChange}
                            checked={availableLevels.length === levelList.length}
                            disabled={!subject}
                        />
                    </li>
                    {
                        availableLevels.map(({id, name, isDisabled, coming_soon}) => {
                            return (
                                <li key={id}>
                                    <Checkbox
                                        id={id}
                                        name={name}
                                        label={name}
                                        disabled={isDisabled || coming_soon}
                                        onChange={onFilterLevelChange}
                                        checked={levelList.find(level => level === id)}
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='list-group-wrapper'>
                <h6 className='list-group-title'>Coach language</h6>
                <ul className="list-group">
                    {
                        languages.map(({id, name}) => {
                            return (
                                <li key={id}>
                                    <Checkbox
                                        id={id}
                                        name={name}
                                        label={name}
                                        checked={languageList.find(lang => lang === id)}
                                        onChange={onFilterLanguageChange}
                                    />
                                </li>
                            )
                        })
                    }

                </ul>
            </div>
        </aside>
    );
};

export default FilterBar;
