import React from 'react';
import {formatDate} from "../../vendors/utils";
import Avatar from "../../assets/img/avatar-default.svg";
import {StarRatings} from "../../elements";
import {useDispatch, useSelector} from "react-redux";
import {changeCurrentStep, setReviewData} from "../../vendors/redux/slices/addReviewSlice";

const ReviewFirstStep = ({user_uuid}) => {
    const dispatch = useDispatch()
    const {avatar, first_name, family_name} = useSelector(state => state.addReview.reviewee.profile);
    const {student, matched_date, subject} = useSelector(state => state.addReview.problem);
    const {rating} = useSelector(state => state.addReview.reviewData);

    const handleChange = (rating) => {
        dispatch(setReviewData({rating}))
        dispatch(changeCurrentStep(2))
    }


    return (
        <div className='text-center'>
            <h3 className='pb-10'>Rate your experience</h3>
            <ul className='preview-list'>
                <li>
                    <label>When</label>
                    <p>{formatDate(matched_date, 'DD/MM/YYYY @ HH:mm')}</p>
                </li>
                <li>
                    <label>Subject</label>
                    <p>{subject}</p>
                </li>
                <li>
                    <label className='block pb-4'>{user_uuid === student ? 'Student' : 'Coach'}</label>
                    <img className="avatar-image lg" src={avatar || Avatar}  alt="avatar"/>
                    <p>{first_name} {family_name}</p>
                </li>
            </ul>
            {user_uuid === student && <p className='py-8'>How did the session go? Did you solve your problem?</p>}
            <StarRatings
                initialRating={rating || 0}
                className='inline-block lg'
                readonly={false}
                onChange={handleChange}
            />
        </div>
    );
};

export default ReviewFirstStep;
