import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { loginSchema as validationSchema } from 'vendors/services/yup-schemas/authSchemas'
import { login } from 'vendors/redux/slices/authSlice'
import { Input, Button, Checkbox } from 'elements'
import {Link, useHistory} from 'react-router-dom';
import {resetAuthError} from "../../../vendors/redux/slices/authSlice";


const LoginForm = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();
    const {loggedIn, error} = useSelector(state => state.auth);

    const initialValues = {
        username: '',
        password: '',
        rememberMe: false
    };

    useEffect(() => {
        if(loggedIn) {
            push('/account-profile')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    useEffect(() => {
        return () => {
            dispatch(resetAuthError())
        };
    }, []);

    const onSubmit = () => {
        dispatch(login(values))
    };

    const { values, errors,  touched, handleChange, handleSubmit, handleBlur, setFieldValue, isValid, dirty } = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    });
    return (
        <form onSubmit={handleSubmit}>
            <Input
                type='text'
                name='username'
                label='Enter your username'
                errorMessage={errors.username}
                value={values.username}
                touched={touched.username}
                handleBlur={handleBlur}
                handleChange={handleChange}
            />
            <Input
                withIcon
                type='password'
                name='password'
                label='Enter your password'
                errorMessage={errors.password}
                value={values.password}
                touched={touched.password}
                handleBlur={handleBlur}
                handleChange={handleChange}/>

                <div className='pt-5 pos-rel'>
                    {
                        error?.data?.ui_message && <p className='error-message-general'>{error?.data?.ui_message}</p>
                    }

                    <Button
                        type="submit"
                        className="w-100"
                        disabled={!(isValid && dirty)}
                    >
                        Continue
                    </Button>
                </div>

            <div className="flex align-center justify-between box-footer mt-8">
                <Checkbox
                    name="rememberMe"
                    label="Remember me"
                    checked={values.rememberMe}
                    onChange={() => setFieldValue("rememberMe", !values.rememberMe)}
                    auth
                />
                <Link to={'/forgot-password'} className="link-primary text-xs">Forgot Password?</Link>
            </div>
        </form>
    );
};



export default LoginForm;
