import React from 'react';
import { useSelector } from 'react-redux';
import { StarRatings } from 'elements';
import Avatar from "../../assets/img/avatar-default.svg";

const AboutInfo = (props) => {
    const {slice = 'profile'} = props;

    const {profile, userLanguages = [], averageRating} = useSelector(state => state[slice]);
    const {first_name, family_name, about, location, avatar, was_tutor} = profile;

    return (
        <>
            <div className="account-info with-img wrap-mobile">
                <div className="content flex">
                    <ul className="content-left data-list w-75">
                        <li>
                            <h2 className="name break-word">{first_name}{" "}{family_name}</h2>
                        </li>
                        <li>
                            <p className="break-word">{about}</p>
                        </li>
                        <li>
                            <label className="text-label sm">Location</label>
                            <p className="text-value text-normal">{location}</p>
                        </li>
                        <li>
                            <label className="text-label sm">Rating</label>
                            <StarRatings
                                initialRating={averageRating}
                            />
                        </li>
                        {
                            was_tutor && !!userLanguages.length &&
                            <li>
                                <label className="text-label sm">Languages spoken</label>
                                <p className="text-value text-normal">{userLanguages?.map(l => l.name).join(', ')}</p>
                            </li>
                        }
                    </ul>
                    <div className="content-right flex justify-end  w-25">
                        <img className="avatar-image lg" src={avatar || Avatar}  alt="avatar"/>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AboutInfo;
