import {createSlice} from '@reduxjs/toolkit'
import Api from '../../services/api/searchTutor'

const initialState = {
    tutors: [],
    error: null
};

const searchTutorsSlice = createSlice({
    name: 'searchTutor',
    initialState,
    reducers: {
        setTutors: (state, action) => {
            state.tutors = action.payload;
            return state;
        },
        resetTutors: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setTutors,
    resetTutors,
    actionError
}
    = searchTutorsSlice.actions;

export const getTutors = (data) => async (dispatch) => {
    try {
        const tutors = await Api.searchTutors(data);
        dispatch(setTutors(tutors?.data.tutors))
    } catch (e) {
        dispatch(actionError(e))
    }
};

export default searchTutorsSlice.reducer
