import React from 'react';
import {Link} from "react-router-dom";
import {getThisYear} from "../../vendors/utils";

const FooterEnhancer = () => {
    return (
        <div className="enhancer-footer">
            <ul className="footer-nav">
                <li>
                    <Link className="disabled">© { getThisYear() } Veepow Asia Pte Ltd}</Link>
                </li>
                <li>
                    <Link to={'/privacy'}>Privacy Policy</Link>
                </li>
                {/*<li>*/}
                {/*    <Link to={'/'}>Terms of Service</Link>*/}
                {/*</li>*/}
            </ul>
        </div>
    );
};

export default FooterEnhancer;
