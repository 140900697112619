import { warningMinute } from '../../../vendors/utils';

export const mobileMenu = () => {
    return [
        {
            to: '/account-profile',
            text: 'My account'
        },
        {
            to: '/how-it-works',
            text: 'How it works'
        },
        {
            to: '/pricing',
            text: 'Pricing'
        },
        {
            to: '/become-a-coach',
            text: 'Become a coach',
            roleType: 2
        },
        {
            to: '/about-us',
            text: 'About us'
        },
        {
            to: '/trust-and-safety',
            text: 'Trust & Safety'
        },
        {
            to: '/contact-us',
            text: 'Contact us'
        },
    ]
}

export const problemBannerData = () => {
    return [
        {
            alertType: 'warning',
            alertText: 'Your request has been sent to our coaches. Please click here to wait in your private workspace.',
        },
        {
            alertType: 'warning',
            alertText: 'Good news! Your job is LIVE. Please click here to join the private workspace.',
        },
        {
            alertType: 'danger',
            alertText: 'Good news! Your job is LIVE. Please click here to join the private workspace.',
        },
    ];
};

export const reviewBannerData = {
    alertType: 'warning',
    alertText: 'Hi there, please click here to review a previous session. These reviews improve our service to you and the community.'
}

export const paymentBannerData = {
    alertType: 'warning',
    alertText: 'Please add a card to your account before posting a problem.',
    path: '/account-profile',
    hash: "#payment-section",
}

export const banners = {
    account_delete: {
        alertType: 'success',
        alertText: 'Your request was successfully sent. Our Customer Support representative will contact you shortly to delete your account.',
        path: '',
        hash: "",
    },
    card_delete: {
        alertType: 'success',
        alertText: 'Credit card deleted successfully!',
        path: '',
        hash: "",
    },
    error: {
        alertType: 'danger',
        alertText: 'Something went wrong, please try again.',
        path: '',
        hash: "",
    },
    zoom_overlay: {
        alertType: 'warning',
        alertText: `There are ${warningMinute} minutes remaining in the session.`
    },
    zoom_buffer: {
        alertType: 'warning',
        alertText: `Time’s up! We hope you have been able to finish the work. As a gesture of goodwill we are giving you an extra ${warningMinute} minutes for free! 
                Please use this time to wrap up and say goodbye.`
    }
}

export const bannerTypes = {
    cardDelete: 'card_delete',
    accountDelete: 'account_delete',
    zoomOverlay: 'zoom_overlay',
    zoomBuffer: 'zoom_buffer',
    error: 'general_error'
}
