import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {
    _start30seconds,
    reset30seconds,
    reset,
    extendTimer,
    updateProblem,
    expireProblem,
    getActiveProblem
} from "../../vendors/redux/slices/activeProblemSlice";
import {userReady} from "../../vendors/redux/slices/workspaceSlice";
import {calcCirclePercent, addTimerInterval, clearInWindowInterval} from "../../vendors/utils";
import LocalStorage from "../../vendors/services/localStorage";
import {TimerTypes} from "../../vendors/services/timers";
import "react-circular-progressbar/dist/styles.css";
import {CircularProgressbar, ButtonLink, Button} from "elements";
import HappyWoman from 'assets/img/postProblem/happy.png'
import SadWoman from 'assets/img/postProblem/sad.png'
import CountDownTimer from "../../component/postProblem/CountDownTimer";
import PageEnhancer from "../postProblem/PageEnhancer";
import {Spinner} from "elements";


const CountdownStudent = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();

    const {
        problem: activeProblem,
        wait30seconds,
        count30seconds,
        getError: problemError
    } = useSelector(state => state.activeProblem);
    const {meeting} = useSelector(state => state.workspace);
    const {user: {user_uuid}} = useSelector(state => state.auth);
    const {roles, problemStatuses} = useSelector(state => state.staticData);

    const [countDown, setCountDown] = useState(0);
    const [countDownEnd, setCountDownEnd] = useState(null);
    const [localProblem, setLocalProblem] = useState(null);

    useEffect(() => {
        const timer_30_sec = LocalStorage.getLocalStorageAsync(TimerTypes.timer_30_sec);
        if (timer_30_sec) {
            dispatch(_start30seconds({count: timer_30_sec}))
        }
        if (meeting?.tutor_ready && meeting?.student_ready) {
            redirectWorkspace()
        }

        return () => {
            resetAfterSecondRound();
            dispatch(reset30seconds());
        }
    }, []);

    useEffect(() => {
        if (user_uuid && Object.keys(roles).length) {
            dispatch(getActiveProblem({role: roles.STUDENT}));
        }
    }, [user_uuid, roles]);

    useEffect(() => {
        if (!wait30seconds && count30seconds.clear) {
            count30seconds.clear();
        }
    }, [wait30seconds]);

    useEffect(() => {
        setLocalProblem(activeProblem);
        if (Object.keys(activeProblem).length && !activeProblem.count_down_end && !countDownEnd) {
            const {count_down_start, count_down_end} = setTimer();

            dispatch(updateProblem({
                problem_uuid: activeProblem.problem_uuid,
                count_down_start,
                count_down_end,
            }))
        }
    }, [activeProblem]);

    useEffect(() => {
        if (!(Object.keys(activeProblem).length) && problemError) {
            count30seconds?.clear && count30seconds.clear();
            push('/account-profile');
        }

    }, [problemError]);

    const resetAfterSecondRound = () => {
        return () => {
            if (wait30seconds && activeProblem?.extended){
                handleLeave()
            }
        }
    };

    useEffect(resetAfterSecondRound, [wait30seconds,activeProblem]);



    const setTimer = () => {
        const count_down_start = moment.utc();
        const count_down_end = addTimerInterval(count_down_start);
        setCountDownEnd(count_down_end);

        return {count_down_start, count_down_end}
    }

    const resetProblem = async () => {
        await dispatch(expireProblem({problem_uuid: activeProblem.problem_uuid}));
        await dispatch(reset());
        push('/account-profile');
    };

    const handleExtend = () => {
        const {count_down_start, count_down_end} = setTimer();

        dispatch(extendTimer({problem_uuid: activeProblem?.problem_uuid, count_down_start, count_down_end}));
        count30seconds?.clear()
    };

    const handleLeave = () => {
        count30seconds?.clear && count30seconds.clear();
        resetProblem();
    };

    const getTimerTick = (tick) => {
        if (!tick) {
            return;
        }
        setCountDown(calcCirclePercent(tick.in_seconds))
    }

    const sendUserReady = async () => {
        await dispatch(userReady({zoom_meeting_id: meeting.zoom_meeting_id, problem_uuid: activeProblem.problem_uuid}))
    }

    const redirectWorkspace = async () => {
        await clearInWindowInterval(TimerTypes.new_problem);
        await clearInWindowInterval(TimerTypes.matched_problem);
        push(`/workspace/${activeProblem.problem_uuid}/${meeting.zoom_meeting_id}`);
    }


    return (
        !Object.keys(activeProblem).length ? <Spinner/> : <PageEnhancer className='countdown-page'>
            {!wait30seconds && activeProblem?.status === problemStatuses.new &&
            <div className="text-center">
                <div className='text-center pb-8'>
                    <CircularProgressbar
                        src={HappyWoman}
                        value={countDown}
                        pathColor="#F9E47E"
                    />
                    <h3 className='pt-5'>
                        {(countDownEnd || activeProblem.count_down_end) && <CountDownTimer
                            end={countDownEnd || activeProblem.count_down_end}
                            getRemaining={getTimerTick}
                            after={()=> dispatch(_start30seconds({}))}
                        />}
                    </h3>
                    <p className='color-dark'>remaining</p>
                </div>

                <h4 className='fw-500'>Finding you a coach.</h4>
                <p className='py-4'>Your post has been blasted to the best possible matches in our
                    community. </p>
                <h4>Please leave this window open.</h4>
            </div>}

            {wait30seconds && <div className="text-center">
                <div className='text-center pb-8'>
                    <CircularProgressbar
                        src={SadWoman}
                        value={100}
                        pathColor="#C579BD"
                    />
                    {/*<h3 className='pt-5'>0m 00s</h3>
                    <p className='color-dark'>remaining</p>*/}
                </div>

                {
                    Object.keys(activeProblem).length && !activeProblem.extended ?
                        <>
                            <h4 className='fw-500'>PLACEHOLDER MSG: Sorry no coaches.</h4>
                            <h4 className='fw-500 py-8'>Would you like to give them more time to respond?</h4>
                        </> :
                        <h4 className='fw-500 pb-8'>We’re sorry but there are no coaches available at present. Please
                            try again later.</h4>
                }

                <div className='pb-10 btn-row'>
                    <ButtonLink
                        styleType='secondary'
                        size='small medium-text'
                        onClick={handleLeave}
                    >
                        Return to my account
                    </ButtonLink>
                    {Object.keys(activeProblem).length && !activeProblem.extended && <Button
                        size='small medium-text'
                        onClick={handleExtend}
                    >
                        Extend for another 10 mins (FREE)
                    </Button>}
                </div>
                <p>Redirecting in {count30seconds?.count} seconds…</p>
            </div>}

            {!wait30seconds && activeProblem?.status === problemStatuses.matched && <div className="text-center">
                <div className='text-center pb-8'>
                    <CircularProgressbar
                        src={HappyWoman}
                        value={countDown}
                        pathColor="#F9E47E"
                    />
                </div>
                <h3>A coach has accepted the job!</h3>
                <h4 className='fw-500 py-8'>Please connect to the private workspace* within the next <strong>
                    {activeProblem.matched_date_end && <CountDownTimer
                        end={activeProblem.matched_date_end}
                        type={TimerTypes.matched_problem}
                        getRemaining={getTimerTick}
                        after={sendUserReady}
                    />}
                </strong>
                </h4>
                {meeting?.student_ready && <p>Waiting for the other participant to join.</p>}
                {!meeting?.student_ready &&
                <Button onClick={sendUserReady} size='small medium-text'>Launch my workspace</Button>}
                {!meeting?.student_ready &&
                <p className='text-normal pt-10'>*A private workspace is where you and the coach will
                    connect over a video call to solve the problem together.</p>}
            </div>}
        </PageEnhancer>
    );
};

export default CountdownStudent;
