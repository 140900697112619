import React from 'react';
import ReviewRow from "./ReviewRow";
import {useSelector} from "react-redux";

const Reviews = ({user_uuid, subState = 'publicProfile', guest = false }) => {
    const {was_tutor: UserWasTutor} = useSelector(state => state.auth.user);
    const {was_tutor: guestWasTutor} = useSelector(state => state.guestProfile.profile);

    const was_tutor = guest ? guestWasTutor : UserWasTutor

    return (
        <div className="account-info wrap-mobile">
            <div className="content-top flex align-center justify-between pt-7">
                <h3>Reviews</h3>
            </div>
            {
                was_tutor &&
                <ReviewRow
                    subState={subState}
                    userType='tutor'
                    user_uuid={user_uuid}
                />
            }

            <ReviewRow
                subState={subState}
                userType='student'
                user_uuid={user_uuid}
            />
        </div>
    );
};

export default Reviews;
