import React, {useEffect, useState} from 'react';
import CircularProgressbar from "../../elements/CircularProgressbar";
import HappyWoman from "../../assets/img/postProblem/happy.png";
import CountDownTimer from "../../component/postProblem/CountDownTimer";
import {Button, Icon, Icomoon} from "../../elements";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {acceptProblem, getProblem} from "../../vendors/redux/slices/singleProblemSlice";
import {calcCirclePercent, clearInWindowInterval, downloadFile} from "../../vendors/utils";
import {TimerTypes} from "../../vendors/services/timers";

const ProblemDetailsInner = ({allowed}) => {
    const dispatch = useDispatch();
    const {push} = useHistory()
    const {problem_uuid} = useParams();

    const {subject, level, comment, language, count_down_end, files, student, status} = useSelector(state => state.singleProblem);
    const {problemStatuses} = useSelector(state => state.staticData);
    const {user: {user_uuid, role}} = useSelector(state => state.auth);

    const [countDown, setCountDown] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (problem_uuid) {
            dispatch(getProblem({problem_uuid}))
        }
    }, [problem_uuid]);

    const onAcceptJob = async () => {
        setSubmitting(true);
        await dispatch(acceptProblem({problem_uuid}));
        clearInWindowInterval(TimerTypes.new_problem);
        push(`/countdown-coach/${problem_uuid}`)
    };

    const getTimerTick = (tick) => {
        if (!tick) {
            return;
        }
        setCountDown(calcCirclePercent(tick.in_seconds))
    };

    const isCreator = () => {
        return user_uuid === student;
    }

    const isNew = () => {
        return problemStatuses.new === status;
    }

    return (
        <div className={'enhancer-inner'}>
            <div className='text-center pb-8'>
                <CircularProgressbar
                    src={HappyWoman}
                    value={countDown}
                    pathColor="#F9E47E"
                />
                <h3 className='pt-5'>
                    {count_down_end && <CountDownTimer
                        end={count_down_end}
                        getRemaining={getTimerTick}
                    />}
                </h3>
                <p className='color-dark'>remaining</p>
            </div>
            {allowed && !isCreator() && <h3 className='text-center pb-7'>Accept job?</h3>}
            <ul className='preview-list'>
                <li>
                    <label>Subject</label>
                    <p>{subject}</p>
                </li>
                <li>
                    <label>Level</label>
                    <p>{level}</p>
                </li>
                <li>
                    <label>Description</label>
                    <p>{comment}</p>
                </li>

                {
                    files &&
                    <li>
                        <label>Attachments</label>
                        <ul className='uploaded-list pt-5'>
                            {
                                files.map(file => {
                                    return (
                                        <li key={file.id}>
                                            <a className='truncate pointer'  onClick={(e)=>downloadFile(e,file.file_url,file.file_name)}>
                                                <Icomoon name='file-image' size={32}/>
                                                <span className='truncate'>{file.file_name}</span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </li>
                }

                <li>
                    <label>Session to be conducted in</label>
                    <p>{language}</p>
                </li>
            </ul>
            {allowed && !isCreator() && isNew() && <Button
                className='w-100 mt-3'
                onClick={onAcceptJob}
                disabled={submitting}
            >
                Accept job
            </Button>}
        </div>
    );
};

export default ProblemDetailsInner;
