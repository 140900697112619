import Girl from 'assets/img/about/girl-modal.png'
import Boy from 'assets/img/about/boy-modal.png'

export  const Confirmation = {
    deleteSubject: {
        title: 'Delete subject?',
        subject: 'Subject',
        warningTitle: 'Proceed with caution.',
        text: 'Deleting* the subject removes it from your profile. You may want to consider toggling the subject to off instead. Doing so allows you to easily resume helping others in this area.',
        submit: 'Delete',
        note: '*For legal reasons veepow retains an archive of your sessions in which help was given in this subject.'
    },
    deleteLastSubject: {
        title: 'Delete subject?',
        subject: 'Subject',
        warningTitle: 'Proceed with caution. ',
        warningText: 'You are about to delete* the only subject you offer help in. This will deactivate your coach profile.',
        text: 'In future, if you decide to earn extra income by helping others you will need to reregister. You may want to consider toggling the subject to off instead.',
        submit: 'Delete',
        note: '*For legal reasons veepow retains an archive of your sessions in which help was given in this subject.'
    },
};

export const Founder = {
    founder1: {
        src: Girl,
        fullName: 'Carmen Leo',
        position: 'Co-founder and CEO',
        text: '“I believe there is a great wealth of knowledge within our very community that is the key to supporting a learner’s journey every step of the way.”'
    },
    founder2: {
        src: Boy,
        fullName: 'Nicholas Leo',
        position: 'Co-founder and CTO',
        text: '“Someone much smarter than me once said ‘The best way to learn, is to teach!’ A mantra we as founders live by, now everyone else can too.”'
    }
};
