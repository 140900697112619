export default function errorMessages(type) {
    const errorObj = {
        validation: {
            required: 'Please fill in the required field.',
            requiredSubject: 'You must indicate one subject.',
            requiredLevel: 'You must indicate one level.',
            email: 'Please enter a valid email address.',
            emailExist: 'Email is not already registered.',
            onlyLetters: 'No numbers or special characters allowed.',
            oneOrMore: 'You must indicate at least one subject level.',
            oneOrMoreLanguage: 'You must indicate at least one language.',
            twoOrMore: 'Field must be at least 2 characters.',
            eightOrMore: '8 characters or longer',
            combine: 'Combine upper and lowercase letters and numbers.',
            confirmation: 'Passwords do not match. Please try again.',
            mobileNumber: 'Please enter a valid mobile number.',
            max1000: 'Max 1000 characters.',
            max500: 'Max length is 500 characters long.',
            fromTo: 'Expected year must be after the starting year.',
            card: 'Credit Card number is invalid.',
            cardExp: 'Credit Card expiration date is invalid.',
            postalCode: 'Postal Code is invalid.',
            cvc: 'CVC is invalid.',
            topUpMin: 'Minimum amount is S$15.',
            dateOfBirth: 'Required format is DD/MM/YYYY.'
        },
        info: {
            minTopUp: 'S$15 minimum top up (S$15 is equal to one coaching session)',
            aboutNote: 'Write a short intro for your public profile',
            tutorNote: 'Your coach application will be under review. You will start receiving job notifications once your coach account is approved.',
            wentWrong: 'Sorry, something has gone wrong.'
        },
    };

    return errorObj[type];
}
