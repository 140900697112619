import React, { useState } from "react";
import {Icomoon, Icon} from "elements";

const Textarea = ({
  className = "",
  withIcon = false,
  cols = "3",
  rows = "4",
  placeholder,
  name,
  value,
  touched,
  errorMessage,
  handleChange,
  handleBlur,
  handleFocus,
  handleKeyPress,
  flag,
  disabled,
  label,
  existedErrorMessage,
  withCheck,
  maxLength,
}) => {
  const [typePassword, setTypePassword] = useState(false);

  const onTogglePassword = () => {
    setTypePassword(!typePassword);
  };

  return (
    <div
      className={`form-input ${errorMessage && touched ? "has-error" : ""} ${
        flag ? "flag-input" : ""
      } ${className}`}
    >
      {label && <label>{label}</label>}
      <div className="pos-rel w-100">
        {flag && (
          <div className="flag-icon">
            <img src={flag} alt={flag} className="flag" />
          </div>
        )}
        <textarea
          name={name}
          value={value}
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          className={`${
            withCheck && withIcon && !errorMessage && touched
              ? "pr-80"
              : (withIcon && withCheck) || (!errorMessage && touched)
              ? "pr-60"
              : ""
          }`}
          disabled={disabled}
          maxLength={maxLength}
        />

        <div className="input-abs">
          {!errorMessage && touched && withCheck && (
          <Icomoon name='check-thin' color='#17B429'/>
          )}
          {withIcon && (
            <button
              type="button"
              onClick={onTogglePassword}
              className="btn-input  no-style"
            >
                {
                    typePassword ?
                        <Icomoon name='eye-slash' color='#85a0b3' size={30}/>
                        :
                        <Icomoon name='eye' color='#85a0b3' size={27}/>
                }
            </button>
          )}
        </div>
      </div>
      {existedErrorMessage && (
        <p className="error-message color-secondary-2 static-message">
          8 characters or longer. <br />
          Combine upper and lowercase letters and numbers.
        </p>
      )}
      {errorMessage && touched && !existedErrorMessage && (
        <p className="error-message">{errorMessage}</p>
      )}
    </div>
  );
};
export default Textarea;
