import * as Yup from 'yup';
import errorMessages from './errorMessages.js';
import {passRegex, phoneRegEx, _onlyLetters, emailRegEx, dateOfBirthRegexPattern} from "./helpers";

const validationMessages = errorMessages ('validation');



export const loginSchema = () => {

    return Yup.object().shape({

        username: Yup
            .string()
            .required(validationMessages.required),

        password: Yup
            .string()
            .required(validationMessages.required)
            .min(8, validationMessages.eightOrMore)
            .matches(passRegex, validationMessages.combine),

    });
};

export const signUpFirstSchema = ({exists, ui_message, success}) => {

    return Yup.object().shape({

        first_name: Yup
            .string()
            .test({
                test: function (value) {
                    return _onlyLetters(this, value);
                }
            })
            .min(2, validationMessages.twoOrMore),

        family_name: Yup
            .string()
            .test({
                test: function (value) {
                    return _onlyLetters(this, value);
                }
            })
            .min(2, validationMessages.twoOrMore),
        date_of_birth: Yup
            .string()
            .matches(dateOfBirthRegexPattern, validationMessages.dateOfBirth)
            .required(validationMessages.required),
        email: Yup.string()
            .required(validationMessages.required)
            .matches(emailRegEx, validationMessages.email)
            .test('This email is already registered. Please try again.', ui_message, ()=> exists !== true),

        phone: Yup
            .string()
            .matches(phoneRegEx, validationMessages.mobileNumber)
            .required(validationMessages.required)
            .test('Can\'t send code. Please try again.', ui_message, ()=> success !== false)
    });
};


export const SignUpLastStepSchema = ({exists, ui_message}) => {

    return Yup.object().shape({

        username: Yup
            .string()
            .required(validationMessages.required)
            .test('The username is already taken. Please pick a new one.', ui_message, ()=> exists !== true),

        password: Yup
            .string()
            .required(validationMessages.required)
            .min(8, validationMessages.eightOrMore)
            .matches(passRegex, validationMessages.combine),
    });
};

export const forgotPasswordSchema = ({ui_message, success}) => {

    return Yup.object().shape({

        email: Yup.string()
            .required(validationMessages.required)
            .matches(emailRegEx, validationMessages.email)
            .test('This email doesn’t exist. Please try again.', ui_message, ()=> success !== false),

    });
};

export const resetPasswordSchema = () => {

    return Yup.object().shape({

        password: Yup
            .string()
            .required(validationMessages.required)
            .min(8, validationMessages.eightOrMore)
            .matches(passRegex, validationMessages.combine),

        confirmPassword: Yup
            .string()
            .required(validationMessages.required)
            .oneOf(
                [Yup.ref('password'), null],
                validationMessages.confirmation),

    });
};

export const subjectSchema = () => {

    return Yup.object().shape({

        subjectList: Yup.array()
            .of(
                Yup.object().shape({
                    levels: Yup.array()
                        .min(1, validationMessages.oneOrMore)
                        .required(validationMessages.required)
                        .nullable(),

                    label: Yup.string()
                        .required(validationMessages.requiredSubject)
                })
            ),

        language: Yup.array()
            .min(1, validationMessages.oneOrMoreLanguage)
            .required(validationMessages.required)
            .nullable(),
    });
};

