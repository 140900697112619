import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    subject: null,
    level: null,
    filteredLevels:null,
    error: null
};

const subjectSelectSlice = createSlice({
    name: 'subjectSelect',
    initialState,
    reducers: {
        filterLevels: (state, action) => {
            state.filteredLevels = action.payload;
            return state;
        },
        setSubject: (state, action) => {
            state.subject = action.payload;
            return state;
        },
        setLevel: (state, action) => {
            state.level = action.payload;
            return state;
        },
        resetSelectSubjectForm: () => {
            return initialState;
        },
        resetLevel: (state) => {
            state.level = null;
            return state;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        }
    }
});

export const {
    setSubject,
    setLevel,
    filterLevels,
    resetSelectSubjectForm,
    resetSubject,
    resetLevel,
    actionError
}
    = subjectSelectSlice.actions;

export default subjectSelectSlice.reducer
