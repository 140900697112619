import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Button, ActionInput, Icomoon} from "elements";
import {applyVoucher, changeCurrentStep, postProblem, resetVoucher} from "../../vendors/redux/slices/postProblemSlice";
import TopUp from "../account/modals/TopUp";
import CreditCardForm from "../account/modals/CreditCardForm";



const PostProblemSecond = () => {
    const dispatch = useDispatch();
    const {push} = useHistory();

    const {subject, level, comment, language} = useSelector(state => state.postProblem.data);
    const {user_uuid, paymentInfo = {}} = useSelector(state => state.auth.user);
    const {prices} = useSelector(state => state.staticData);
    const {data, voucher, pending} = useSelector(state => state.postProblem);
    const {problem: activeProblem} = useSelector(state => state.activeProblem);

    const [voucherText, setVoucherText] = useState('');
    const [validVoucher, setValidVoucher] = useState('');

    const [topUpOpen, toggleTopUp] = useState(false);
    const onOpenTopUpModal = () => toggleTopUp(true);
    const onCloseTopUpModal = () => toggleTopUp(false);

    const [creditCardOpen, toggleCard] = useState(false);
    const onOpenCardModal = () => toggleCard(true);
    const onCloseCardModal = () => toggleCard(false);

    useEffect(() => {
        if(voucher.exists) {
            setValidVoucher(voucherText)
        } else {
            setValidVoucher('')
        }
    }, [voucher.exists]);

    useEffect(() => {
        window.scrollTo(0, 0)

        return () => {
            dispatch(resetVoucher())
        }
    }, []);

    useEffect(()=>{
        if (Object.keys(activeProblem).length) {
            push('/countdown-student')
        }
    },[activeProblem]);

    const goBack = () => {
        dispatch(changeCurrentStep(1));
    };

    const onPostProblem = () => {
        dispatch(postProblem(buildParams()));
    };

    const buildParams = () => {
        return  {
            subject: data.subject.name,
            subject_id: data.subject.id,
            level: data.level.name,
            level_id: data.level.id,
            comment: data.comment,
            language: data.language.name,
            language_id: data.language.id,
            files: data.files,
            student: user_uuid,
            price: prices[0].price,
            duration: prices[0].minute,
            voucher: validVoucher
        };
    }

    const onVoucherChange = (e) => {
        const { value } = e.target;
        setVoucherText(value)
    };

    const onVoucherApply = () => {
        if(voucherText) {
            dispatch(applyVoucher({voucher: voucherText}))
        }
    };

    const lowBalance = (paymentInfo?.custom_balance || 0) < prices[0].price;

    return (
        <>
            <ul className='preview-list'>
                <li>
                    <label>Subject</label>
                    <p>{subject.name}</p>
                </li>
                <li>
                    <label>Level of problem</label>
                    <p>{level.name}</p>
                </li>
                <li>
                    <label>Write a few sentences about the problem</label>
                    <p className='break-word'>{comment}</p>
                </li>
                <li>
                    <label>Add attachments (up to 3)</label>
                    {
                        data.files &&
                        <ul className='uploaded-list pt-5'>
                            {
                                data.files.map((file, index) => {
                                    return (
                                        <li key={`file-${index}`}>

                                            <a className='truncate' target='_blank' href={file.file_url}>
                                                <Icomoon
                                                    name='file-image'
                                                    size={32}
                                                />
                                                <span className='truncate'>{file.file_name}</span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    }

                </li>
                <li>
                    <label>What language would you like your helper to speak?</label>
                    <p>{language.name}</p>
                </li>
            </ul>
            <div className='price-section'>
                <h3>Price</h3>
                <ul className="price-list">
                    <li>
                        <p>Cost for {prices[0].minute} mins</p>
                        <div className='flex color-dark'>
                            <strong className={voucher.exists ? 'line-trough' : ''}>S${prices[0].price.toFixed(2)}</strong>
                            {
                                voucher.exists && <span className='color-success ml-2'>FREE</span>
                            }
                        </div>
                    </li>
                    <li>
                        <p>Wallet balance</p>
                        <strong className={lowBalance ? 'color-danger ' : `color-dark`}>S${(paymentInfo?.custom_balance || 0).toFixed(2)}</strong>
                    </li>

                    {!paymentInfo.payment_method_id && <li className="flex align-center justify-end">
                        <Button onClick={onOpenCardModal}
                                size='w-126 small'
                                styleType='primary'>Top up</Button>
                    </li>}

                    {paymentInfo.payment_method_id && lowBalance && <li className="flex align-center justify-end">
                        <Button disabled={!paymentInfo.payment_method_id}
                                onClick={onOpenTopUpModal}
                                size='w-126 small'
                                styleType='primary'>Top up</Button>
                    </li>}

                    {lowBalance && <p className="color-danger mb-10">There are insufficient funds in your account. Please top up to continue posting.</p>}

                </ul>
                <div className='select-box select-wrapper pt-10'>
                    <label>Redeem a promotion code</label>
                    <div>
                        <ActionInput
                            className='mb-0'
                            text='Apply'
                            btnType='secondary'
                            onChange={onVoucherChange}
                            onClick={onVoucherApply}
                            errorMessage={voucher.ui_message}
                            disabled={!voucherText}
                        />
                    </div>
                </div>
            </div>
            <Button
                className='w-100 mt-3'
                onClick={onPostProblem}
                disabled={pending || (!voucher.exists && lowBalance)}
            >
                Post my problem
            </Button>
            <p className='pt-10 text-normal text-center'>Need to change something? <br/>
                <Button
                    styleType='no-style underline'
                    onClick={goBack}
                >
                    Go back to step 1
                </Button>
            </p>

            {topUpOpen && <TopUp
                open={topUpOpen}
                onCloseModal={onCloseTopUpModal}
            >
            </TopUp>}
            {creditCardOpen && <CreditCardForm
                open={creditCardOpen}
                onCloseModal={onCloseCardModal}
                topUp>
            </CreditCardForm>}
        </>
    );
};

export default PostProblemSecond;
