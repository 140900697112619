import React from 'react';
import Rating from "react-rating";
import {Icomoon} from "elements";

const StarRatings = ({
                         initialRating,
                         readonly=true,
                         withPlus,
                         className,
                         onChange
                     }) => {


    return (
        <div className={`rate-stars ${withPlus ? 'with-plus' : ''} ${className}`}>
            <Rating
                initialRating={initialRating}
                readonly={readonly}
                emptySymbol={<Icomoon name='star-outline' color='#FFDF46'/>}
                fullSymbol={<Icomoon name='star' color='#FFDF46'/>}

                onChange={onChange}
            />
        </div>

    );
};

export default StarRatings;
