import React, {useState} from 'react';
import {Modal} from "react-responsive-modal";
import StarRatings from "../../elements/StarRatings";
import DefaultAvatar from "assets/img/avatar-default.svg";
import GuestProfile from "component/publicProfile/modals/guestProfile";

const SingleTutor = ({avatar, family_name, first_name, about, averageRating, practiceAreas, user_uuid}) => {
    const [guestOpen, setGuest] = useState(false);
    const guestProfile = {
        open: () => setGuest(true),
        close: () => setGuest(false),
    };

    return (
        <>
            <div className='result-item-content'>
                <img className="avatar-image sm" src={avatar || DefaultAvatar}  alt="avatar"/>
                <div className='result-item-top'>
                    <h4 onClick={()=>guestProfile.open()} className="pointer">{family_name} {first_name}</h4>
                    <StarRatings initialRating={averageRating || 0}/>
                </div>
                {about && <p className="result-item-text break-word">{about}</p>}
                <div className="result-item-bottom">
                    <h6>Expertise</h6>
                    <ul className='expertise-list'>
                        {
                            practiceAreas.map(({name, user_subject_id, levels}) => {
                                return(
                                    <li key={user_subject_id}>{name} ({
                                        levels.map(({name}) => {
                                            return name
                                        }).join(', ')
                                    }) <span className='bullet'>&bull;</span></li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <Modal
                open={guestOpen}
                onClose={guestProfile.close}
                center
                classNames={{
                    modal: 'large-modal',
                }}
            >
                <GuestProfile user_uuid={user_uuid}/>
            </Modal>
        </>
    );
};

export default SingleTutor;
