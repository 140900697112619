import React from 'react';
import IcomoonReact from "icomoon-react";
import iconSet from "assets/fonts/icomoon/selection.json"


const Icomoon = ({
                     name,
                     color='#2a496e',
                     className = '',
                     size=24,
                     onClick
                 }) => {
    return (
        <IcomoonReact
            className={`icon icon-${name} ${className}`}
            iconSet={iconSet}
            color={color}
            size={size}
            icon={name}
            onClick={onClick}
        />
    );
};

export default Icomoon;
