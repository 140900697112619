import {createSlice} from '@reduxjs/toolkit'
import Api from '../../services/api/user'
import AuthApi from '../../services/api/auth'
import NotificationApi from '../../services/api/notificationSettings'
import {getUserInfo} from "./authSlice";
import {openGeneralErrorModal} from "./layoutSlice";
import {assignLanguageData} from "vendors/utils"
import {setPublicUserLanguages} from "./publicProfileSlice";


const initialState = {
    account: {},
    changePassword: {
        ui_message: '',
        success: null
    },
    avatarUpload: null,
    notifications: {},
    notificationAlert: null,
    userLanguages: [],
    paymentInfo: {},
    emailExists: {
        ui_message: '',
        exists: null
    },

    deletionSuccess: null,
    error: null
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccount: (state, action) => {
            state.account = action.payload;
            return state;
        },
        setNotification: (state, action) => {
            state.notifications = action.payload;
            return state;
        },
        setNotificationAlert: (state, action) => {
            state.notificationAlert = action.payload;
            return state;
        },
        setUserLanguages: (state, action) => {
            state.userLanguages = action.payload;
            return state;
        },
        setUserPaymentInfo: (state, action) => {
            state.paymentInfo = action.payload;
            return state;
        },
        setEmailApiError: (state, action) => {
            state.emailExists.ui_message = action.payload.ui_message || '';
            state.emailExists.exists = !!action.payload.ui_message;
            return state;
        },
        setChangePassword: (state, action) => {
            state.changePassword.ui_message = action.payload.ui_message || '';
            state.changePassword.success = !!action.payload.success;
            return state;
        },
        resetChangePassword: (state) => {
            state.changePassword.ui_message = '';
            state.changePassword.success = null;
            return state;
        },
        resetEmailApiError: (state) => {
            state.emailExists.ui_message = '';
            state.emailExists.exists = null;
            return state;
        },
        resetAccount: () => {
            return initialState;
        },
        actionError: (state, action) => {
            state.error = action.payload;
            return state;
        },
        accountDeletionSuccess: (state, action) => {
            state.deletionSuccess = action.payload;
            return state;
        },
        setAvatarUpload: (state, action) => {
            state.avatarUpload = action.payload;
            return state;
        }
    }
});

export const {
    setAccount,
    setNotification,
    setNotificationAlert,
    setUserLanguages,
    resetAccount,
    setEmailApiError,
    resetEmailApiError,
    setChangePassword,
    resetChangePassword,
    actionError,
    setAvatarUpload,
    setUserPaymentInfo,
    accountDeletionSuccess
}
    = accountSlice.actions;


export const getAccount = () => async dispatch => {
    try {
            const payload = await Api.getAccount();
            await dispatch(setAccount(payload.data.account));
            await dispatch(setNotification(payload.data.notifications));
            await dispatch(setUserLanguages(payload.data.userLanguages));
            await dispatch(setPublicUserLanguages(payload.data.userLanguages));
            await dispatch(setUserPaymentInfo(payload.data.paymentInfo));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const updateAccount = (data) => async (dispatch, getState) => {
    try {
        await Api.updateAccount(data);
        await updateUserLang(getState);
        dispatch(getAccount());
        dispatch(getUserInfo());
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const changePassword = (data) => async dispatch => {

    try {
        const payload = await Api.changePassword(data);
        dispatch(setChangePassword(payload.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(setChangePassword(e.data))
        }
        dispatch(actionError(e))
    }
};

export const changeAvatar = (data) => async dispatch => {

    try {
        dispatch(setAvatarUpload(false));
        await Api.changeAvatar(data);
        await dispatch(getUserInfo());
        await dispatch(getAccount());
        dispatch(setAvatarUpload(true));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const emailExists = (data) => async (dispatch) => {
    try {
        const payload = await AuthApi.emailExists(data);
        dispatch(setEmailApiError(payload.data))
    } catch (e) {
        if (e?.data) {
            return dispatch(setEmailApiError(e.data))
        }
        dispatch(actionError(e))
    }
};

export const updateUserLang = async (getState) => {
    const {was_tutor, role} = getState().account.account;
    const {roles} = getState().staticData;

    if (!was_tutor || role !== roles.TUTOR) return;

    const data = assignLanguageData({}, getState);
    return await Api.updateLanguages(data);
};

export const NotificationTurnOn = (data) => async dispatch => {
    try {
        await NotificationApi.turnOn(data);
        dispatch(getAccount());
        dispatch(setNotificationAlert(1));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const NotificationTurnOff = (data) => async dispatch => {
    try {
        await NotificationApi.turnOff(data);
        dispatch(getAccount());
        dispatch(setNotificationAlert(0));
    } catch (e) {
        dispatch(actionError(e))
    }
};

export const accountDeleteRequest = () => async dispatch => {
    try {
        await Api.accountDeleteRequest();
        dispatch(accountDeletionSuccess(true));
        dispatch(getAccount());
        dispatch(getUserInfo());
    } catch (e) {
        dispatch(actionError(e));
        dispatch(openGeneralErrorModal(e))
    }
};


export default accountSlice.reducer;

