import React, {useEffect, useState} from 'react';
import Modal from "react-responsive-modal";
import {Button} from "../../../elements";
import {useDispatch, useSelector} from "react-redux";
import {setGeneralBanner} from "vendors/redux/slices/layoutSlice";
import {accountDeleteRequest} from "../../../vendors/redux/slices/accountSlice";
import {bannerTypes} from "../../../layout/header/signedIn/configs";

const DeletionModal = ({onClose, open}) => {
    const dispatch = useDispatch()

    const {deletionSuccess} = useSelector(state => state.account);

    const [submitting, setSubmitting] = useState();

    const resetState = () => {
        dispatch(setGeneralBanner(false));
    };

    const init = () => {
        return resetState;
    };

    const handleDelete = async () => {
        setSubmitting(true);
        await dispatch(accountDeleteRequest());
        setSubmitting(false);
    }

    const listenDeleteSuccess = () => {
        if (deletionSuccess) {
            dispatch(setGeneralBanner(bannerTypes.accountDelete));
            onClose();
        }
    };

    useEffect(init,[]);

    useEffect(listenDeleteSuccess, [deletionSuccess]);


    return (
        <Modal
            open={open}
            onClose={onClose}
            center
        >
            <div className='modal-body'>
                <h3 className='modal-title'>Permanently delete my account</h3>

                <p className='color-danger fw-700 pb-7'>Proceed with caution.</p>
                <p className='color-danger pb-7'>You are about to request for your Veepow account to be permanently deleted*.</p>
                <p className='modal-text'>In future, if you decide to come back to Veepow you will need to register under a new account. Your existing reviews and star ratings will be lost.</p>
                <p className='text-xs pb-7'>*For legal reasons veepow retains an archive of your sessions in a secure backup.</p>

                <div className="modal-footer">
                    <Button
                        onClick={onClose}
                        className='text-underline'
                        styleType='tertiary'
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={submitting}
                        type='submit'
                        styleType='secondary'
                        className='w-126 small'
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeletionModal;
